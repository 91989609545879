import React, { cloneElement } from 'react';
import { TabList, Tabs } from 'react-tabs';

import { useCloseDialogOnEscape, useDialog3 } from './useDialog3';

export const useDialog2 = useDialog3;

export const Dialog = ({ dialogRef, children }) => {
    useCloseDialogOnEscape(dialogRef);

    const persistedTabIndex = parseInt(
        sessionStorage.getItem('selectedTabIndex') || '',
    );

    const onSelect = (tabIndex) => {
        sessionStorage.setItem('selectedTabIndex', tabIndex.toString());
    };

    let classes = 'dialog2';

    if (dialogRef.options.fullscreen === true) {
        classes += ' dialog2-fullscreen';
    }

    return (
        <div className={classes} tabIndex={-1}>
            <div className="dialog2-tabs-wrapper">
                <Tabs
                    defaultIndex={persistedTabIndex}
                    onSelect={onSelect}
                    forceRenderTabPanel
                    className="dialog2-tabs"
                >
                    <TabList className="dialog2-tab-list">
                        {React.Children.map(children, (child, index) => {
                            return child.type.tabsRole === 'Tab'
                                ? cloneElement(child, {
                                    className: 'dialog2-tab',
                                    selectedClassName: 'dialog2-selected-tab',
                                    key: `Tab-${index}`,
                                })
                                : null;
                        })}
                    </TabList>
                    <div className="dialog2-panel-wrapper">
                        {React.Children.map(children, (child, index) => {
                            return child.type.tabsRole === 'TabPanel'
                                ? cloneElement(child, {
                                    key: `TabPanel-${index}`,
                                })
                                : null;
                        })}
                    </div>
                    <button
                        className="dialog2-close-button"
                        onClick={() => dialogRef.close()}
                        type="button"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Tabs>
            </div>
        </div>
    );
};
