export const orderStatusTexts: { [key: string]: string } = {
    new: 'Skapad',
    not_received_parts_awaiting: 'Väntar på delar - men ej inkommen',
    canceled: 'Avbokad',
    received: 'Mottagen',
    processing: 'Arbete pågår',
    supplier_response_awaiting: 'Inväntar svar från leverantör',
    supplier_response_received: 'Mottaget svar från leverantör',
    customer_response_awaiting: 'Inväntar svar från kund',
    customer_response_received: 'Mottaget svar från kund',
    parts_awaiting: 'Delar beställda',
    parts_received: 'Delar mottagna',
    done: 'Klar',
    closed: 'Avslutad (Skickad till kassa)',
};

export const orderOriginTexts: { [key: string]: string } = {
    store: 'Butik',
    online: 'Online',
};
