import React from 'react';
import { Tab, TabPanel } from 'react-tabs';

import { Dialog, useDialog2 } from '../custom-hooks/useDialog2';
import AppButton from '../Form/AppButton';

const ConfirmDialog = ({ dialogRef, content, buttons = [] }) => {
    return (
        <Dialog dialogRef={dialogRef}>
            <Tab style={{ display: 'none' }} />
            <TabPanel>
                <div style={{ width: 500 }}>
                    <div>
                        {content || ''}
                    </div>
                    <div className='button-wrapper-right'>
                        {buttons.map((button, i) => {
                            const { action, label, ...rest } = button;
                            return (
                                <AppButton
                                    key={i}
                                    onClick={() => {
                                        action && action();
                                        dialogRef.close({ button });
                                    }}
                                    {...rest}
                                >
                                    {label}
                                </AppButton>
                            );
                        })}
                    </div>
                </div>
            </TabPanel>
        </Dialog>
    );
};

export function useConfirmDialog() {
    const { createDialog } = useDialog2();

    return ({ content, buttons = [] }) => {
        return createDialog((ref) => (
            <ConfirmDialog
                dialogRef={ref}
                content={content}
                buttons={buttons}
            />
        ));
    };
}
