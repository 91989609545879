import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { FeatureToggleService } from '../../../services/Launchdarkly/services/FeatureToggleService';
import { useStore } from '../context/store-context';
import { useUser } from '../context/user-context';

type Props = {
    flag: string;
    renderFallback: () => React.JSX.Element;
} & PropsWithChildren<any>;

export function FeatureToggle({ flag, children, renderFallback = () => null }: Props) {
    const [user] = useUser();
    const [storeId] = useStore()!;
    const [isFeatureEnabled, setIsFeatureEnabled] = useState(null);

    const loading = isFeatureEnabled === null;

    // @todo The launch darkly client needs to be initialized every time,
    //  refactor this so that it can be initialized when we have a user id and all stores.
    //  Refactor this as a provider
    const service = useMemo(async () => await FeatureToggleService.getClient({
        key: `${user.username}`,
        custom: { storeIds: storeId }
    }), [user, storeId]);

    useEffect(() => {
        (async () => {
            const client = await service;
            setIsFeatureEnabled(await client.variation(flag, false));
        })();
    }, [service, setIsFeatureEnabled, flag]);

    if (loading) {
        return renderFallback();
    }

    if (!isFeatureEnabled) {
        return renderFallback();
    }

    return (
        <>
            {children}
        </>
    );
}
