import './Customer.scss';

import React from 'react';

import { useDialog2 } from '../Common/custom-hooks/useDialog2';
import CustomerList from './CustomerList/components/CustomerList';

const CustomerPage = () => {
    const { createDialog } = useDialog2();
    return (
        <>
            <h1>Kunder</h1>
            <div className='box'>
                <CustomerList createDialog={createDialog} />
            </div>
        </>
    );
};

export default CustomerPage;
