import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const LoadingContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const LoaderCircularDiscrete = ({ visible = true }) => {
    return (
        <div>
            {visible && (
                <LoadingContainer>
                    <CircularProgress color='secondary' />
                </LoadingContainer>
            )}
        </div>
    );
};

export default LoaderCircularDiscrete;
