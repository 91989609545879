import './SubStorePage.scss';

import { Box } from '@material-ui/core';
import React from 'react'
import { useParams } from 'react-router-dom';

import { SubStoreForm } from './components/SubStoreForm'

export default function SubStorePage() {
    const params = useParams<{ id: string }>();
    const id = params.id;
    const title = id ? 'Redigera' : 'Ny';

    return (
        <>
            <h1>{title} lista</h1>
            <Box className='box'>
                <div className='sub-store-page'>
                    <SubStoreForm id={id ? parseInt(id) : undefined } />
                </div>
            </Box>
        </>
    )
}
