import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useMemo } from 'react';
import { SystemUser, useMechanicsQuery } from 'src/generated/graphql';
import AppTableButtonLarge from 'src/modules/Common/Tables/AppTableButtonLarge';
import SimpleConfirmDialog from 'src/modules/Customer/components/SimpleConfirmDialog';

import { MechanicsTable } from './MechanicsTable/MechanicsTable';

type AddMechanicDialogProps = {
    subStoreId: number;
    open: boolean;
    onClose: () => void;
    onAdd: (user: SystemUser) => void;
    skipMechanicIds?: string[];
};

export default function AddMechanicDialog({ subStoreId, skipMechanicIds, open, onClose, onAdd }: AddMechanicDialogProps) {
    const { data: mechanicsData } = useMechanicsQuery({
        variables: {
            storeId: subStoreId,
        },
        skip: !open,
    })

    const filteredMechanics = useMemo(() => {
        if(!skipMechanicIds) {
            return mechanicsData?.systemUsers.items ?? []
        }
        return mechanicsData?.systemUsers.items
            .filter((mechanic) => !skipMechanicIds
                .some(skipMechanicId => skipMechanicId === mechanic.id)
            ) ?? [];
    }, [mechanicsData, skipMechanicIds]);

    return (
        <SimpleConfirmDialog open={open} title='Lägg till mekaniker' onCancel={() => onClose()} cancelLabel='Stäng'>
            <MechanicsTable
                actionName="Lägg till"
                mechanics={filteredMechanics}
                rowClickedAction={(row: SystemUser) => <AppTableButtonLarge icon={faPlus} title="Lägg till" onClick={() => onAdd(row)} />}
            />
        </SimpleConfirmDialog>
    );
}
