import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Settings, SettingsInput, useUpdateSettingsMutation } from '../../generated/graphql';
import { FeatureToggle } from '../Common/Common/FeatureToggle';
import { CustomStack } from '../Common/Common/Stack';
import AppButton from '../Common/Form/AppButton';
import AppCheckbox from '../Common/Form/AppCheckbox';
import TextInput from '../Common/Form/TextInput';
import { daySpanValidation } from './inputs/validation';

type AutomatedMessagingSettingsFormType = {
    [key: string]: boolean | string | number,
};

const useAutomatedMessagingSettings = () => {
    const defaultSettings: Omit<Settings, 'id'>[] = [
        {
            path: 'automation/messaging/enabled',
            value: 'false',
            type: 'boolean',
            storeId: undefined,
        },
        {
            path: 'automation/messaging/waiting-for-drop-off/enabled',
            value: 'false',
            type: 'boolean',
            storeId: undefined,
        },
        {
            path: 'automation/messaging/waiting-for-drop-off/days',
            value: '1',
            type: 'number',
            storeId: undefined,
        },
        {
            path: 'automation/messaging/waiting-for-pick-up/enabled',
            value: 'false',
            type: 'boolean',
            storeId: undefined,
        },
        {
            path: 'automation/messaging/waiting-for-pick-up/days',
            value: '3',
            type: 'number',
            storeId: undefined,
        },
        {
            path: 'automation/messaging/storage-fee-warning/enabled',
            value: 'false',
            type: 'boolean',
            storeId: undefined,
        },
        {
            path: 'automation/messaging/storage-fee-warning/days',
            value: '7',
            type: 'number',
            storeId: undefined,
        }
    ];

    const parseVal = (type: string, val: string): string | boolean | number => {
        if (type === 'boolean') {
            return (val.toLowerCase() === 'true' || val === '1');
        }

        if (type === 'number') {
            return parseInt(val, 10);
        }

        return val;
    }

    return {
        defaultSettings,
        parseVal
    };
}

export const AutomatedMessagingSettingsForm = ({ storeId, settings }: { storeId: number, settings: Settings[] }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ onUpdateSettings ] = useUpdateSettingsMutation({
        onError: () => {
            enqueueSnackbar('Kunde inte uppdatera inställningarna', {
                variant: 'error',
            });
        },
        onCompleted: () => {
            enqueueSnackbar('Inställningarna har uppdaterats', {
                variant: 'success',
            });
        }
    });

    const { defaultSettings, parseVal } = useAutomatedMessagingSettings();

    const onSubmit = async (submitData: AutomatedMessagingSettingsFormType) => {
        const updatedSettings = Object.entries(submitData).map(([key, value]) => {
            const currentSettings = settings.find((s) => {
                return s.path === key;
            });

            if (currentSettings) {
                return {
                    ...currentSettings,
                    value: `${value}`,
                }
            }

            const defaultSetting = defaultSettings?.find((s) => {
                return s.path === key;
            });

            return {
                ...defaultSetting,
                value: `${value}`,
                storeId,
            }
        }).filter(Boolean);

        await onUpdateSettings({
            variables: { settings: updatedSettings as unknown as SettingsInput },
        });
    }

    const getDefaultValues = () => {
        const config = settings.length ? settings : defaultSettings;

        const values = config.map((s) => {
            return [
                s.path,
                parseVal(s.type, s.value!),
            ];
        });

        return Object.fromEntries(values);
    };

    const methods = useForm<AutomatedMessagingSettingsFormType>({
        defaultValues: getDefaultValues(),
    });

    const { control, handleSubmit } = methods;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomStack direction="column" gap={20}>
                    <FeatureToggle flag='sportson-enable-message-automation-settings' renderFallback={() => (
                        <AppCheckbox
                            control={control}
                            label="Aktivera automatiska utskick för butik"
                            name="automation/messaging/enabled"
                            disabled={true}
                        />
                    )}>
                        <AppCheckbox
                            control={control}
                            label="Aktivera automatiska utskick för butik"
                            name="automation/messaging/enabled"
                        />
                    </FeatureToggle>

                    <AppCheckbox
                        control={control}
                        label="Använd utskick för påminnelse om inlämning"
                        name="automation/messaging/waiting-for-drop-off/enabled"
                    />
                    <TextInput
                        label={'Antal dagar innan service-datum för påminnelse om inlämning'}
                        name="automation/messaging/waiting-for-drop-off/days"
                        validation={{ validate: daySpanValidation(1, 15) }}
                    />

                    <AppCheckbox
                        control={control}
                        label="Använd utskick för påminnelse om upphämtning"
                        name="automation/messaging/waiting-for-pick-up/enabled"
                    />
                    <TextInput
                        label={'Antal dagar för påminnelse om upphämtning från klar-datum'}
                        name="automation/messaging/waiting-for-pick-up/days"
                        validation={{ validate: daySpanValidation(1, 15) }}
                    />

                    <AppCheckbox
                        control={control}
                        label="Använd utskick för påminnelse om dygnshyra"
                        name="automation/messaging/storage-fee-warning/enabled"
                    />
                    <TextInput
                        label={'Antal dagar för varning om dygnshyra från klar-datum'}
                        name="automation/messaging/storage-fee-warning/days"
                        validation={{ validate: daySpanValidation(1, 15) }}
                    />

                    <AppButton
                        type='submit'
                        icon={faCheckCircle}
                    >
                        Spara
                    </AppButton>
                </CustomStack>
            </form>
        </FormProvider>
    );
}
