import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Send from '@material-ui/icons/Send';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { ServiceOrder } from 'src/generated/graphql';
import AppTextInput from 'src/modules/Common/Form/AppTextInput';
import { Box, Stack } from '@mui/material';

interface Props {
    onNewMessage: (message: string) => void
    serviceOrder: ServiceOrder
    methods: UseFormMethods
}

export default function SmsSendMessage(props: Props) {
    const { serviceOrder, onNewMessage, methods } = props;

    const { register, reset, handleSubmit, watch, setError, formState: { errors } } = methods;

    const toManyCharsError = () => setError('message', {
        type: 'manual',
        message: 'Meddelandet är för lång',
    });

    const clearErrors = () => methods.clearErrors('message');

    const currentMessage = watch('message');

    const maxChars = 320;

    const onSubmit = (formValues: any) => {
        if (formValues.message.length > maxChars) {
            toManyCharsError();

        } else {
            clearErrors();
        }

        if (formValues.message && !errors['message']) {
            onNewMessage(formValues.message);

            reset();
        }
    };

    return (
        <div className="sms-send-message">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    sx={{
                        paddingBottom: '10px',
                    }}
                >
                    <Box />
                    <Box>{(currentMessage || '').length}/{maxChars}</Box>
                </Stack>
                <AppTextInput
                    name='message'
                    placeholder={'Meddelande till kund'}
                    inputRef={register}
                    onChange={(event) => {
                        if (event.target.value.length > maxChars) {
                            toManyCharsError();

                            return event;
                        }

                        clearErrors();

                        return event;
                    }}
                    multiline
                    error={!!errors['message']}
                    InputProps={{
                        endAdornment: currentMessage ? (
                            <InputAdornment position="end">
                                <IconButton
                                    component="span"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    <Send />
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    }}
                />
                {errors['message'] && (
                    <Box sx={{ color: 'var(--red)', marginBottom: '20px', marginTop: '-5px' }}>{errors['message'].message}</Box>
                )}
            </form>
        </div>
    );
}
