export function statusLabel(value) {
    return (
        {
            pass: 'Godkänd',
            worn: 'Slitet',
            fail: 'Underkänd',
            'month-1-3': '1-3 Månader',
            'month-12': '12 Månader',
            'month-24': '24 Månader',
            'month-36': '36 Månader',
        }[value] || value
    );
}

