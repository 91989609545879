import gql from 'graphql-tag';

export const GET_SCHEDULE_DEVIATIONS = gql`
    query scheduleDeviations(
        $filter: FilterArgument
        $page: Int
        $pageSize: Int
        $orderBy: String
        $orderDir: OrderByDirection
    ) {
        scheduleDeviations(
            filter: $filter
            page: $page
            pageSize: $pageSize
            orderBy: $orderBy
            orderDir: $orderDir
        ) {
            items {
                id
                storeId
                date
                timeAvailable
                note
            }
            total
            hasMore
        }
    }
`;

export const CREATE_SCHEDULE_DEVIATION = gql`
    mutation createScheduleDeviation(
        $formValues: CreateScheduleDeviationInput!
    ) {
        createScheduleDeviation(scheduleDeviationInput: $formValues) {
            id
            storeId
            date
            timeAvailable
            note
        }
    }
`;
export const UPDATE_SCHEDULE_DEVIATION = gql`
    mutation updateScheduleDeviation(
        $id: Float!
        $formValues: UpdateScheduleDeviationInput!
    ) {
        updateScheduleDeviation(id: $id, scheduleDeviationInput: $formValues) {
            id
            storeId
            date
            timeAvailable
            note
        }
    }
`;
export const DELETE_SCHEDULE_DEVIATION = gql`
    mutation deleteScheduleDeviation($id: Float!) {
        deleteScheduleDeviation(id: $id)
    }
`;

// mutation {
//     createScheduleDeviation (scheduleDeviationInput: {
//         storeId: 123
//         date: "2020-12-23"
//         timeAvailable: 0
//         note: "Julafton"
//     }) {
//         storeId
//         date
//         timeAvailable
//         note
//     }
// }
// // New
// mutation {
//     updateScheduleDeviation (id:4, scheduleDeviationInput: {
//         note: "Annandag Jul"
//     }) {
//         storeId
//         date
//         timeAvailable
//         note
//     }
// }
// mutation {
//     deleteScheduleDeviation (id: 5)
// }
