const translations: { [name: string]: string } = {
    // App errors
    'User already belongs to store':
        'Användaren tillhör redan butiken.',

    // Cognito errors, add user
    'User does not exist.':
        'Användaren existerar inte.',

    // Cognito errors, create user
    'Password did not conform with password policy: Password not long enough':
        'Lösenordet är inte tillräckligt långt.',
    'Password did not conform with password policy: Password must have uppercase characters':
        'Lösenordet måste ha minst ett stort tecken.',
    'Password did not conform with password policy: Password must have numeric characters':
        'Lösenordet måste ha minst en siffra.',
    'Password did not conform with password policy: Password must have symbol characters':
        'Lösenordet måste ha minst ett specialtecken.',

    // Cognito errors, reset password
    'Password does not conform to policy: Password not long enough':
        'Lösenordet är inte tillräckligt långt.',
    'Password does not conform to policy: Password must have uppercase characters':
        'Lösenordet måste ha minst ett stort tecken.',
    'Password does not conform to policy: Password must have numeric characters':
        'Lösenordet måste ha minst en siffra.',
    'Password does not conform to policy: Password must have symbol characters':
        'Lösenordet måste ha minst ett specialtecken.',
};

export const translateError = (t: string) => {
    return translations[t] || t;
};
