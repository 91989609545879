import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { css } from 'emotion';
import React from 'react';

import { formatDateTime } from '../../../../../Common/helpers/dates';
import { colors } from '../../../../../Common/styles/colors';
import { orderStatusText } from '../../../../utils/serviceOrderFields';

const container = css`
    margin: 45px 0px;
    min-width: 190px;
    flex-grow: 1;
    * {
        font-size: 100%;
        font: inherit;
    }
`;

const timeline = css`
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        position: relative;
    }
    > li:not(:last-child)::after {
        background-color: ${colors.black};
        content: '';
        position: absolute;
        top: 20px;
        left: 8px;
        width: 4px;
        bottom: -30px;
    }
`;

const circle = css`
    background-color: #fff;
    border: 3px solid ${colors.black};
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 10px;
    width: 20px;
    height: 20px;
    z-index: 100;
`;

const content = css`
    padding: 0 0 30px 40px;
    text-align: left;
`;

const Timeline = ({ children }) => (
    <div className={container}>
        <ul className={timeline}>{children}</ul>
    </div>
);

const TimelineItem = ({ children }) => (
    <li>
        <div className={circle} />
        <div className={content}>{children}</div>
    </li>
);

const ServiceOrderHistoryTimeLine = ({ serviceOrder }) => {
    const serviceOrderHistory = serviceOrder?.history || [];

    return (
        <Timeline>
            {serviceOrderHistory.map((historyEvent) => (
                <TimelineItem key={historyEvent.id}>
                    <Typography variant='body2' color='textSecondary'>
                        {formatDateTime(historyEvent.createdAt)}
                    </Typography>
                    <Typography component='span'>
                        <Box fontWeight='fontWeightBold'>
                            {orderStatusText(historyEvent.status)}
                        </Box>
                    </Typography>
                    {historyEvent.message &&
                    <Typography component='span' color='textSecondary' fontSize={12}>
                        {historyEvent.message}
                    </Typography>
                    }
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default ServiceOrderHistoryTimeLine;
