import { ReplaySubject } from 'rxjs';
import { useSharedSubject } from 'src/modules/Common/custom-hooks/sharedSubjects/useSharedSubject';

// The subject
export const printers = new ReplaySubject<string[]>(1);

// React hook with state
export function usePrinters() {
    return useSharedSubject(printers)
}

// load from local storage
const saved = localStorage.getItem('printers');
if (saved) {
    printers.next(JSON.parse(saved));
}

// Save to local storage on changes
printers.subscribe((newValue) => {
    localStorage.setItem('printers', JSON.stringify(newValue));
})
