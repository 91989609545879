import * as Sentry from '@sentry/react';
import * as Tracing from '@sentry/tracing';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

enum Environments {
    Production = 'production',
    Stage = 'stage',
}

enum Branches {
    Master = 'master',
    Develop = 'develop',
}

const RELEASE_NAME_VARIABLE = 'REACT_APP_RELEASE_NAME';

export class SentryService {
    static getEnvironment(_branchName: string | undefined): string | undefined {
        switch (_branchName) {
            case Branches.Master:
                return Environments.Production;
            case Branches.Develop:
                return Environments.Stage;
            default:
                return undefined;
        }
    }

    static getBranchName(releaseName: string | undefined): string | undefined {
        return releaseName?.split('-')[0];
    }

    static initiate() {
        const branchName = this.getBranchName(process.env[RELEASE_NAME_VARIABLE]);
        const environment = this.getEnvironment(branchName);

        Sentry.init({
            release: process.env[RELEASE_NAME_VARIABLE],
            environment,
            debug: environment !== Environments.Production && environment !== Environments.Stage,
            maxValueLength: 3000,
            // frontend-specific
            sampleRate: environment ? 1.0 : 0.0,
            dsn: 'https://513d1219596a40f486d5581c7105198c@o1240364.ingest.sentry.io/6414539',
            ignoreErrors: [
                "Access denied! You don't have permission for this action!"
            ],
            tracesSampler: () => {
                if (environment === Environments.Production) {
                    return 0.5;
                }
                if (environment === Environments.Stage) {
                    return 1.0;
                }

                return 0.0;
            },
            integrations: [new Tracing.BrowserTracing()],
            beforeBreadcrumb: excludeGraphQLFetch,
        });
    }
}
