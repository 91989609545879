import './ProductSearch.scss';

import { useLazyQuery } from '@apollo/react-hooks';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useRef, useState } from 'react';

import { FeatureToggle } from "../../../../../../Common/Common/FeatureToggle";
import LoaderCircular from '../../../../../../Common/Common/LoaderCircular';
import { useStore } from '../../../../../../Common/context/store-context';
import useElementMouseClick from '../../../../../../Common/custom-hooks/useElementMouseClick';
import AppSearchDebounce from '../../../../../../Common/Form/AppSearchDebounce';
import { FIND_PRODUCTS } from '../../../../../graphql/ProductsGql';
import { priceFormat } from '../../../../../utils/productHelper';

export function ProductSearch({ onProductSelection }) {
    const [store] = useStore();

    let [search, { data, loading }] = useLazyQuery(FIND_PRODUCTS);

    const searchRef = useRef({});
    const ref = useRef(null);

    const products = data?.searchForProducts?.items;

    const [showResults, setShowResults] = useState(false);
    const [currentSearch, setCurrentSearch] = useState('');

    useEffect(() => {
        if (currentSearch && currentSearch !== '-' && products && products.length) {
            const matches = products.filter((product) => product.EanCode === currentSearch)

            // If there is only one match on ean code, select it.
            if (matches.length === 1) {
                onProductSelection(matches[0]);
                setCurrentSearch('');
                searchRef.current.reset();
                searchRef.current.focus();
            }
        }
    }, [products, currentSearch, onProductSelection])

    useElementMouseClick(ref, (isInside) => {
        setShowResults(isInside);
    })

    const onSearch = (value) => {
        if (value && value.length > 2) {
            setCurrentSearch(value);
            search({ variables: { query: value, page: 1, storeId: store } });

            return;
        }

        // Don't show results. No search query has been executed.
        setCurrentSearch('');
    };

    return (
        <div ref={ref}>
            <AppSearchDebounce
                label='Lägg till artikel'
                placeholder='Ange artikelnummer, streckkod eller fritext på namn'
                onSearch={onSearch}
                searchRef={searchRef}
            />
            {showResults && currentSearch.length > 2 && (
                <>
                    <ProductResult
                        onProductSelection={(v) => {
                            onProductSelection(v);
                            setCurrentSearch('');
                            searchRef.current.reset();
                            searchRef.current.focus();
                        }}
                        result={products || []}
                        loading={loading}
                        currentSearch={currentSearch}
                    />
                </>
            )}
        </div>
    );
}

function ProductResult({ result, onProductSelection, loading, currentSearch }) {
    // Don't show list if no products.
    if (!result && !loading) {
        return null;
    }

    return (
        <div className='product-result-parent'>
            <div className='product-result card'>
                <LoaderCircular visible={loading} size={50} />
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>ArtNr</TableCell>
                                <TableCell>Beskrivning</TableCell>
                                <FeatureToggle flag='PRODUCT_SEARCH_ENABLE_STOCK_LISTING'>
                                    <TableCell>Lager</TableCell>
                                </FeatureToggle>
                                <TableCell align='right'>Pris</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result && result?.map((product, i) => (
                                <ProductMatch
                                    onSelect={onProductSelection}
                                    key={i}
                                    product={product}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {currentSearch && !result.length && !loading && (
                    <div className='product-result-no-matches'>Inga produkter hittades</div>
                )}
            </div>
        </div>
    );
}

function ProductMatch({ onSelect, product, rowIndex }) {
    return (
        <TableRow
            className='product-result-match'
            key={rowIndex}
            onClick={() => onSelect(product)}
        >
            <TableCell>{product.PartNo}</TableCell>
            <TableCell>{product.Name}</TableCell>
            <FeatureToggle flag='PRODUCT_SEARCH_ENABLE_STOCK_LISTING'>
                <StockQty product={product} container={<TableCell />}></StockQty>
            </FeatureToggle>
            <TableCell align='right'>
                <span>{priceFormat(product.PriceIncVat)}</span>
            </TableCell>
        </TableRow>
    );
}

function StockQty({ product, container }) {
    const [item] = product?.OnHands ?? [];

    return React.cloneElement(container, {
        children: item?.OnHandValue ?? '-'
    });
}
