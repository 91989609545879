import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import {
    CompanyCustomerSearchResult,
    useSearchForCompanyCustomersLazyQuery,
} from '../../../../generated/graphql';
import { DialogRef } from '../../../Common/custom-hooks/useDialog3';
import TextInput from '../../../Common/Form/TextInput';
import { ConfirmUseCompanyCustomerDialog } from '../../../Customer/CustomerDialogs/ConfirmUseCompanyCustomerDialog';
import { validateOrgNum } from '../../../Customer/helpers/company.helper';

const StyledTextInput = withStyles({
    root: {
        verticalAlign: 'inherit',
    },
})(TextInput);

export const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    inputWrapper: {
        position: 'relative',
        flexGrow: 1,
    },
    openDialog: {
        position: 'relative',
        left: '5px',
    },
    overlay: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    disabledOverlay: {
        position: 'absolute',
        background: 'rgba(255,255,255,0.6)',
        top: -5,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

type OrgNumberInputProps = {
    dialogRef: DialogRef,
    orgNumber: string,
    onCheckComplete: (exists: boolean | undefined) => void,
};

export const OrgNumberInput = ({ dialogRef, orgNumber, onCheckComplete }: OrgNumberInputProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ existingCompanyList, setExistingCompanyList ] = useState<CompanyCustomerSearchResult[]>([]);

    const [ getCompanyCustomers ]  = useSearchForCompanyCustomersLazyQuery({
        fetchPolicy: 'network-only',
        onError: () => enqueueSnackbar('Kunde inte kontrollera företaget!', { variant: 'error' }),
        onCompleted: (data) => {
            const { items } = data.searchForCompanyCustomers;

            setExistingCompanyList(items);

            if (items.length > 0) {
                onCheckComplete(true);
                setShowConfirmation(true);
            } else {
                onCheckComplete(false);
            }
        },
    });

    const classes = useStyles();

    return (
        <>
            <ConfirmUseCompanyCustomerDialog
                open={showConfirmation}
                onConfirm={(customer) => {
                    setShowConfirmation(false);
                    dialogRef.close(customer);
                }}
                onCancel={() => {
                    setShowConfirmation(false);
                    onCheckComplete(true);
                }}
                matches={existingCompanyList}
            />
            <div className={classes.root}>
                <StyledTextInput
                    autoFocus
                    label='Org.nummer'
                    name='orgNumber'
                    validation={{ validate: validateOrgNum, required: true }}
                    onChange={() => {
                        onCheckComplete(undefined);
                    }}
                />
                <div className={classes.openDialog}>
                    <Tooltip title="Kontrollera">
                        <IconButton onClick={() => getCompanyCustomers({ variables: { query: orgNumber } })}>
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </>
    );
}
