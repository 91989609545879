/* eslint-disable react/display-name */
import './UserTable.scss';

import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { MutableRefObject, ReactNode, useState } from 'react';
import { Query, SystemUser } from 'src/generated/graphql';
import LoaderCircular from 'src/modules/Common/Common/LoaderCircular';
import LoaderLinearDiscrete from 'src/modules/Common/Common/LoaderLinearDiscrete';
import useTableSorting, { inlineSortingFunction } from 'src/modules/Common/custom-hooks/useTableSorting';
import ErrorMessage from 'src/modules/Common/ErrorMessage/ErrorMessage';
import AppSearchDebounce from 'src/modules/Common/Form/AppSearchDebounce';
import TableSort from 'src/modules/Common/Tables/TableSort';
import { SYSTEM_USERS } from 'src/modules/UserAdministration/graphql/UsersGql';

interface Props {
    innerRef: MutableRefObject<any>,
    actions: (row: SystemUser) => ReactNode,
    storeId?: number,
    loading: boolean
}

export const UserTable = ({ actions, innerRef, storeId, loading }: Props) => {

    const [searchString, setSearchString] = useState('');

    const { data, error, refetch } = useQuery<Query>(SYSTEM_USERS, {
        variables: {
            storeId
        }
    });

    innerRef.current.refetch = refetch;

    const sortState = useTableSorting('name', 'asc');

    if (error) {
        return <ErrorMessage error={error} />;
    }

    if (!data) {
        return <LoaderCircular />;
    }

    const sortedUsers = data.systemUsers.items
        .filter((user) => {
            return !searchString
                || user.name?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
                || user.email?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
        })
        .sort(inlineSortingFunction(sortState));

    return (
        <div className="user-table">
            <Box p={2}>
                <AppSearchDebounce
                    autoSearch
                    delay={50}
                    id="shareowners-search"
                    onSearch={setSearchString}
                    placeholder="Sök namn / e-post"
                />
            </Box>
            <LoaderLinearDiscrete visible={loading} />
            <TableContainer>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSort sortState={sortState} property="name" title="Namn" />
                            </TableCell>
                            <TableCell>
                                <TableSort sortState={sortState} property="email" title="E-post" />
                            </TableCell>
                            <TableCell>Butiker</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedUsers.map((row, i) => (
                            <TableRow
                                key={i}
                            >
                                <TableCell align="left">
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">
                                    {row.email}
                                </TableCell>
                                <TableCell align="left">
                                    <div className="assigned-stores">
                                        {row.assignedStores.map((store) => (
                                            <div key={`${store.id}-${store.name}`}>
                                                {store.name}
                                            </div>
                                        ))}
                                    </div>
                                </TableCell>
                                <TableCell align="right" padding='none'>
                                    {actions(row)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

