import React from 'react';
import NumberFormat from 'react-number-format';

interface CurrencyFormatProps {
    inputRef: (instance: NumberFormat | null) => void;
}

const CurrencyFormat = ({ inputRef, ...rest }: CurrencyFormatProps) => (
    <NumberFormat
        getInputRef={inputRef}
        {...rest}
        thousandSeparator=' '
        suffix=' kr'
    />
);

export default CurrencyFormat;
