import React, { useEffect, useState } from 'react';
import CurrencyFormatCustom from 'src/modules/Common/Common/CurrencyFormat';
import AppTextInput from 'src/modules/Common/Form/AppTextInput';

interface CurrencyInputProps {
    label: string;
    name: string;
    register: any;
    validation?: ValidationOptions;
    value?: number | null;
    onChange?: () => any;
}

interface ValidationOptions {
    required?: boolean | string;
    maxLength?: number;
    minLength?: number;
    max?: number;
    min?: number;
    pattern?: RegExp;
    validate?: any;
}

const CurrencyInput = ({
    register,
    validation = {
        required: false,
        minLength: 1,
        max: 500000,
        min: 1,
    },
    value,
    onChange = () => {},
    ...rest
}: CurrencyInputProps) => {
    const [maxCost, setMaxCost] = useState<
        number | null | undefined | string
    >();

    useEffect(() => {
        setMaxCost(value);
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setMaxCost(e.target.value);
        onChange();
    };

    return (
        <>
            <AppTextInput
                value={maxCost}
                onChange={handleChange}
                InputProps={{
                    inputComponent: CurrencyFormatCustom as any,
                }}
                inputRef={register({
                    ...validation,
                })}
                {...rest}
            />
        </>
    );
};

export default CurrencyInput;
