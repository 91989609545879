import { format } from 'date-fns';

export const transformToCreateCustomerBikeInput = (
    formValues = {},
    customerId,
    companyId,
) => {
    const {
        frameNumber,
        manufacturer,
        description,
        purchaseDate,
        yearModel,
        sku,
        tag,
    } = formValues;
    const purchasedAt = purchaseDate ? format(purchaseDate, 'yyyy-MM-dd') : null;

    return {
        customerId,
        companyId,
        frameNumber,
        manufacturer,
        description,
        purchasedAt,
        year: yearModel ? yearModel.getUTCFullYear() : null,
        sku,
        tag,
    };
};
