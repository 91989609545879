import React from 'react';
import {
    ServiceOrder,
} from 'src/generated/graphql';
import { PrettyJson } from 'src/modules/Common/Common/PrettyJson';

interface Props {
    serviceOrder: ServiceOrder
}

export default function DebugTab({ serviceOrder }: Props) {

    return (
        <div>
            <h4>last create:</h4>
            <PrettyJson data={serviceOrder.debugCreationData as string} />
            <h4>last update:</h4>
            <PrettyJson data={serviceOrder.debugUpdateData as string} />
        </div>
    );
}
