import { useEffect } from 'react';
import { useGetServiceOrderLazyQuery } from 'src/generated/graphql';

export function useQueryServiceOrder(serviceOrderId: number) {
    const [call, actions] = useGetServiceOrderLazyQuery({
        // network-only causes refetch with selecting product category. cache seems to work.
        fetchPolicy: 'network-only',
        variables: { id: serviceOrderId },
    });

    // Make sure that it is only called once per mount
    useEffect(() => {
        call();
    }, [call]);

    return actions;
}
