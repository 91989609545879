import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faArchive, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Card, CardContent, Grid, MenuItem, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
    SubStoreQuery,
    SubStoreStatus,
    SystemUser,
    useAddMechanicMutation,
    useArchiveSubStoreMutation,
    useCreateSubStoreMutation,
    useRemoveMechanicMutation,
    useSubStoreQuery,
    useSystemUserQuery,
    useUpdateSubStoreMutation,
} from 'src/generated/graphql';
import { useConfirmDialog } from 'src/modules/Common/Common/ConfirmDialog';
import { useUser } from 'src/modules/Common/context/user-context';
import AppButton from 'src/modules/Common/Form/AppButton';
import AppSelect from 'src/modules/Common/Form/AppSelect';
import TextInput from 'src/modules/Common/Form/TextInput';
import AppTableButtonLarge from 'src/modules/Common/Tables/AppTableButtonLarge';
import { MechanicsTable } from 'src/modules/SubStore/components/MechanicsTable/MechanicsTable';

import AddMechanicDialog from './AddMechanicDialog';
import { SubStoreStatuses } from './data/SubStoreSelectOptions';

type SubStoreFormProps = {
    id?: number;
};

type SubStoreFormValues = {
    id?: number;
    name: string;
    storeId: number;
    status: SubStoreStatus;
    users: SystemUser[];
};

function mapSubStoreForFormData(subStore?: SubStoreQuery['subStore']) {
    return {
        id: subStore?.id ?? undefined,
        storeId: subStore?.store?.id ?? 0,
        name: subStore?.name ?? '',
        status: subStore?.status ?? SubStoreStatus.Active,
    };
}

export function SubStoreForm({ id }: SubStoreFormProps) {
    const storeExists = id !== undefined;

    const history = useHistory();


    const { data: subStoreData, refetch } = useSubStoreQuery({
        variables: {
            id: id!,
        },
        onCompleted: (data) => {
            methods.reset(mapSubStoreForFormData(data.subStore));
        },
        skip: !storeExists,
    });

    const [user] = useUser();

    const { data: userData } = useSystemUserQuery({
        variables: {
            id: user?.getUsername() ?? '',
        },
        skip: !user,
    });
    

    const methods = useForm<SubStoreFormValues>({
        defaultValues: mapSubStoreForFormData(subStoreData?.subStore),
    });

    useEffect(() => {
        if (!subStoreData?.subStore?.users) return;
        if (subStoreData.subStore?.users) {
            setUsers(() => subStoreData.subStore.users as SystemUser[]);
        }
    }, [subStoreData?.subStore?.users]);

    const [users, setUsers] = React.useState<SystemUser[]>((subStoreData?.subStore?.users as SystemUser[]) ?? []);

    const [addMechanicDialogOpen, setAddMechanicDialogOpen] = React.useState(false);

    const [archiveSubStore] = useArchiveSubStoreMutation({
        onCompleted: () => {
            enqueueSnackbar('Lista arkiverad!', { variant: 'success' })
            history.push(`/store-administration`);
        },
        onError: () => enqueueSnackbar('Kunde inte arkivera lista!', { variant: 'error' }),
    });

    const [updateSubStore] = useUpdateSubStoreMutation({
        onCompleted: () => {
            enqueueSnackbar('Lista uppdaterad!', { variant: 'success' })
            history.push(`/store-administration`);
        },
        onError: () => enqueueSnackbar('Kunde inte uppdatera lista!', { variant: 'error' }),
    });

    const [createSubStore] = useCreateSubStoreMutation({
        onCompleted: () => {
            enqueueSnackbar('Lista skapad!', { variant: 'success' })
            history.push(`/store-administration`);
        },
        onError: () => enqueueSnackbar('Kunde inte skapa lista!', { variant: 'error' }),
    });

    const [removeMechanic] = useRemoveMechanicMutation({
        onCompleted: () => {
            enqueueSnackbar('Mekaniker borttagen!', { variant: 'success' })
            refetch({ id: id! });
        },
        onError: () => enqueueSnackbar('Kunde inte ta bort mekaniker!', { variant: 'error' }),
    });

    const [addMechanic] = useAddMechanicMutation({
        onCompleted: () => {
            enqueueSnackbar('Mekaniker tillagd!', { variant: 'success' })
            refetch({ id: id! });
        },
        onError: () => enqueueSnackbar('Kunde inte lägga till mekaniker!', { variant: 'error' }),
    });

    const confirmDialog = useConfirmDialog();

    const { enqueueSnackbar } = useSnackbar();

    const onMechanicsSelectClose = () => {
        setAddMechanicDialogOpen(false);
        refetch({ id: id! });
    };

    const onMechanicSelected = (user?: SystemUser) => {
        if (!user) {
            return;
        }

        if (users.find((u) => u.id === user.id)) {
            enqueueSnackbar('Mekaniker finns redan i listan!', { variant: 'warning' });
            return;
        }

        if (id) {
            addMechanic({
                variables: { subStoreId: id!, userId: user.id },
            })
            return;
        }

        setUsers((users) => [...users, user]);
    };

    const onRemoveMechanic = (user: SystemUser) => {
        if (id) {
            removeMechanic({
                variables: { subStoreId: id!, userId: user.id },
            })
            return;
        }
        setUsers((users) => users.filter((u) => u.id !== user.id));
    };

    const onSubmit = (data: SubStoreFormValues) => {
        if (id) {
            updateSubStore({
                variables: {
                    input: {
                        id: id,
                        name: data.name,
                        status: data.status,
                        storeId: data.storeId,
                        userIds: users.map((u) => u.id),
                    },
                },
            });
        } else {
            createSubStore({
                variables: {
                    input: {
                        name: data.name,
                        status: data.status,
                        storeId: data.storeId,
                        userIds: users.map((u) => u.id),
                    },
                },
            });
        }
    };

    const showRemoveUserDialog = (row: SystemUser) => {
        confirmDialog({
            content: (
                <>
                    Är du säker på att du vill ta bort <b>{row.name}</b> från listan?
                </>
            ),
            buttons: [
                { label: 'Avbryt', variant: 'outlined' },
                { label: 'Radera', action: () => onRemoveMechanic(row) },
            ],
        });
    };

    const onArchiveList = () => {
        if (id) {
            archiveSubStore({
                variables: {
                    id: id,
                },
            });
        }
    };

    const showArchiveListDialog = () => {
        confirmDialog({
            content: (
                <>
                    Är du säker på att du vill arkivera listan?
                </>
            ),
            buttons: [
                { label: 'Avbryt', variant: 'outlined' },
                { label: 'Arkivera', action: () => onArchiveList() },
            ],
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <FormProvider {...methods}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextInput
                                            name="name"
                                            label="Namn"
                                            validation={{
                                                required: 'Namn är obligatoriskt',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppSelect
                                            rules={{
                                                required: 'Butik är obligatoriskt',
                                                validate: (value) => {
                                                    if (value === 0) {
                                                        return 'Butik är obligatoriskt';
                                                    }
                                                    return true;
                                                },
                                            }}
                                            disabled={Boolean(id) || users.length > 0}
                                            name="storeId"
                                            label="Butik"
                                            defaultValue={methods.getValues('storeId')}
                                        >
                                            {userData?.systemUser?.stores?.map((user) => (
                                                <MenuItem key={user.id} value={user.id}>
                                                    {user.name}
                                                </MenuItem>
                                            ))}
                                        </AppSelect>
                                        {(Boolean(!id) && users.length > 0) ? <Typography color='textSecondary'>Kan inte byta butik med mekaniker i listan.</Typography> : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppSelect
                                            name="status"
                                            label="Status"
                                            defaultValue={methods.getValues('status') ?? SubStoreStatus.Active}
                                        >
                                            {SubStoreStatuses.map((user) => (
                                                <MenuItem key={user.id} value={user.id}>
                                                    {user.name}
                                                </MenuItem>
                                            ))}
                                        </AppSelect>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <Grid item xs={12} md={6}>
                                            <AppButton type="submit" icon={faCheckCircle}>
                                                {id ? 'Spara' : 'Skapa'}
                                            </AppButton>
                                        </Grid>
                                        {id && subStoreData?.subStore.status == SubStoreStatus.Active ? <Grid item xs={12} md={6}>
                                            <AppButton type="button" icon={faArchive} color={'default'} onClick={showArchiveListDialog}>
                                                Arkivera lista
                                            </AppButton>
                                        </Grid> : null}
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <AddMechanicDialog
                                    skipMechanicIds={users.map((u) => u.id)}
                                    open={addMechanicDialogOpen}
                                    subStoreId={methods.getValues('storeId')!}
                                    onAdd={onMechanicSelected}
                                    onClose={onMechanicsSelectClose}
                                />
                                <Box paddingBottom={2}>
                                    <Typography variant='body1' color='textSecondary'>
                                        Mekaniker
                                    </Typography>
                                </Box>
                                {methods.watch('storeId') ?
                                    <Box paddingRight={4} display={'inline'}>
                                        <AppButton icon={faPlus} onClick={() => setAddMechanicDialogOpen(true)}>
                                            Lägg till mekaniker
                                        </AppButton>
                                    </Box>
                                    : null}
                                {(!id && users.length > 0 && methods.watch('storeId')) ?
                                    <Box display={'inline'}>
                                        <AppButton icon={faTrash} onClick={() => setUsers([])}>
                                            Töm lista
                                        </AppButton>
                                    </Box> : null}
                                <MechanicsTable
                                    actionName="Ta bort"
                                    mechanics={users}
                                    rowClickedAction={(row) => {
                                        return (
                                            <Grid container>
                                                <Grid item>
                                                    <AppTableButtonLarge
                                                        icon={faTimes}
                                                        onClick={() => showRemoveUserDialog(row)}
                                                        label=""
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
