import './HeadsLog.scss';

import styled from '@emotion/styled';
import Box from '@material-ui/core/Box';
import React from 'react';
import { Tab, TabPanel } from 'react-tabs';

import { PrettyJson } from '../../Common/Common/PrettyJson';
import { Dialog } from '../../Common/custom-hooks/useDialog2';

const TabInner = styled.div`
    width: 1024px;
    height: 643px;
`;

function HeadsLog({ headsLog }) {
    return (
        <Box p={1} pb={3}>
            <h2>Request:</h2>
            <PrettyJson data={headsLog.request} />
            <h2>Response:</h2>
            <PrettyJson data={headsLog.response} />
        </Box>
    );
}

export function HeadsLogDialog({ dialogRef, headsLog }) {
    return (
        <Dialog dialogRef={dialogRef}>
            <Tab>HeadsLog</Tab>
            <TabPanel>
                <TabInner>
                    <HeadsLog headsLog={headsLog} />
                </TabInner>
            </TabPanel>
        </Dialog>
    );
}
