import { css } from 'emotion';
import React from 'react';
import { Customer } from 'src/generated/graphql';
import { DialogRef } from 'src/modules/Common/custom-hooks/useDialog3';
import useFeatureFlag, { LaunchDarklyFlag } from 'src/modules/Common/custom-hooks/useFeatureFlag';

import { Dialog } from '../../Common/custom-hooks/useDialog3';
import SearchCompanyCustomer from './SearchCompanyCustomer';
import SearchCustomer from './SearchCustomer';

const tabContentStyles = css`
    height: 70vh;
    width: 960px;
    overflow-y: scroll;
`;

interface Props {
    dialogRef: DialogRef<Customer>;
}

const SearchCustomerDialog = ({ dialogRef }: Props) => {
    const featureFlagBusinessCustomersActive = useFeatureFlag(LaunchDarklyFlag.BUSINESS_CUSTOMERS);
    return (
        <Dialog
            dialogRef={dialogRef}
            tabs={
                featureFlagBusinessCustomersActive ?[
                    {
                        label: 'Sök kund',
                        content: (
                            <div className={tabContentStyles}>
                                <SearchCustomer onSelectCustomer={dialogRef.close} />
                            </div>
                        ),
                    },
                    {
                        label: 'Sök företagskund',
                        content: (
                            <div className={tabContentStyles}>
                                <SearchCompanyCustomer onSelectCustomer={dialogRef.close} />
                            </div>
                        ),
                    },
                ] : [
                    {
                        label: 'Sök kund',
                        content: (
                            <div className={tabContentStyles}>
                                <SearchCustomer onSelectCustomer={dialogRef.close} />
                            </div>
                        ),
                    }]}
        />
    );
};

export default SearchCustomerDialog;
