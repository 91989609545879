import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';

import { findOrPush } from '../../../../../Common/helpers/models';
import CommentDialogButton from './CommentDialog';
import InspectionProtocolHeader from './utils/InspectionProtocolHeader';

const Wrapper = styled.div`
    width: 50%;
    padding: 0 0 32px;
`;

const ComponentTables = ({ section = [], grades, onChange, disabled, config }) => {
    if (!grades) {
        return null;
    }

    if (!config) {
        return null;
    }

    const isGradeSelected = (componentId, gradeName) => {
        const grade = findGradeByComponentId(componentId);

        return grade && grade.status === gradeName;
    };

    const setGrade = (componentId, value) => {
        onChange(
            findOrPush(grades, (grade) => grade.component.id === componentId, {
                component: { id: componentId },
                status: value,
            })
        );
    };

    const handleCommentChanged = (component, value) => {
        onChange(
            findOrPush(grades, (grade) => grade.component.id === component.id, {
                component: { id: component.id },
                comment: value,
            }),
        );
    };

    function findGradeByComponentId(componentId) {
        return grades.find((grade) => grade.component.id === componentId);
    }

    return (
        <Wrapper>
            {section.map((section) => (
                <div key={section.number}>
                    <InspectionProtocolHeader>
                        {section.number} {section.name}
                    </InspectionProtocolHeader>
                    <TableContainer className='table-bordered table-striped table-dense-checkbox'>
                        <Table padding='default'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ paddingRight: 0 }}>#</TableCell>
                                    <TableCell style={{ width: '100%' }}>Beskrivning</TableCell>
                                    {config.columns.map((column, key) => (
                                        <TableCell key={key} style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{column.prefix ? <><div>mån</div><div>{column.label}</div></> : column.label}</TableCell>
                                    ))}
                                    <TableCell align="center">{config.legend}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {section.component.map((row) => (
                                    <TableRow key={row.number}>
                                        <TableCell component="th" scope="row" style={{ paddingRight: 0 }}>
                                            {row.number}
                                        </TableCell>
                                        <TableCell>
                                            {row.name}
                                        </TableCell>
                                        {config.columns.map((column, key) => {
                                            return (
                                                <TableCell align="center" padding='checkbox' key={key}>
                                                    <SimpleCheckbox
                                                        disabled={disabled}
                                                        checked={isGradeSelected(row.id, column.key)}
                                                        onChange={(isTrue) => {
                                                            setGrade(row.id, isTrue ? column.key : null);
                                                        }}
                                                    />
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell align="right" padding='checkbox'>
                                            {disabled ? null : (
                                                <CommentDialogButton
                                                    defaultValue={
                                                        findGradeByComponentId(row.id)?.comment || ''
                                                    }
                                                    onClose={(value) => handleCommentChanged(row, value)}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ))}
        </Wrapper>
    );
};

export default ComponentTables;

function SimpleCheckbox({ checked, onChange, disabled }) {
    const [isTrue, setIsTrue] = useState(false);

    useEffect(() => {
        setIsTrue(!!checked);
    }, [checked]);

    const handleChange = (value) => {
        setIsTrue(value);
        onChange(value);
    };

    return (
        <Checkbox
            disabled={disabled}
            checked={isTrue}
            onChange={() => handleChange(!isTrue)}
            color="primary"
        />
    );
}
