import styled from '@emotion/styled';
import React from 'react';
import { Tab, TabPanel } from 'react-tabs';
import useFeatureFlag, { LaunchDarklyFlag } from 'src/modules/Common/custom-hooks/useFeatureFlag';

import { Dialog } from '../../../../Common/custom-hooks/useDialog2';
import { CompanyCustomerSearchPanel } from './Panels/CompanyCustomerSearchPanel';
import { CustomerSearchPanel } from './Panels/CustomerSearchPanel';
import { OrderSearchPanel } from './Panels/ServiceOrderSearchPanel';

const TabInner = styled.div`
    width: 1024px;
    height: 643px;
    max-width: 85vw;
    padding: 10px 0;
`;


export const QuickSearchDialog = ({ dialogRef }) => {
    const featureFlagBusinessCustomersActive = useFeatureFlag(LaunchDarklyFlag.BUSINESS_CUSTOMERS);
    if (featureFlagBusinessCustomersActive) {
        return (
            <Dialog dialogRef={dialogRef}>
                <Tab>Sök serviceorder</Tab>
                <TabPanel>
                    <TabInner>
                        <OrderSearchPanel />
                    </TabInner>
                </TabPanel>
                <Tab>Sök kund</Tab>
                <TabPanel>
                    <TabInner>
                        <CustomerSearchPanel />
                    </TabInner>
                </TabPanel>
                <Tab>Sök företagskund</Tab>
                <TabPanel>
                    <TabInner>
                        <CompanyCustomerSearchPanel />
                    </TabInner>
                </TabPanel>
            </Dialog>
        )
    }
    return (
        <Dialog dialogRef={dialogRef}>
            <Tab>Sök serviceorder</Tab>
            <TabPanel>
                <TabInner>
                    <OrderSearchPanel />
                </TabInner>
            </TabPanel>
            <Tab>Sök kund</Tab>
            <TabPanel>
                <TabInner>
                    <CustomerSearchPanel />
                </TabInner>
            </TabPanel>
        </Dialog>
    );
};
