import gql from 'graphql-tag';

export const GET_CATEGORIES = gql`
    query getCategories {
        productCategories {
            items {
                CategoryId
                ParentCategoryId
                Hierarchy
                Category {
                    Id
                    ClientId
                    Code
                    DefaultName
                }
            }
        }
    }
`;
