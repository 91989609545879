import React from 'react';

import { useDialog2 } from '../../../../../Common/custom-hooks/useDialog2';
import { tableStyles } from '../../../../../Common/styles/tableStyles';
import ServiceOrderHistoryList from './ServiceOrderHistoryList';

const ServiceOrderHistoryTable = ({ customerId }) => {
    const { createDialog } = useDialog2();

    return (
        <div className={tableStyles}>
            <ServiceOrderHistoryList
                customerId={customerId}
                createDialog={createDialog}
            />
        </div>
    );
};

export default ServiceOrderHistoryTable;
