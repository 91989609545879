import { useSnackbar } from 'notistack';
import React from 'react';
import { Tab, TabPanel } from 'react-tabs';
import { useSubStore } from 'src/modules/Common/context/sub-store-context';
import { DialogRef } from 'src/modules/Common/custom-hooks/useDialog3';

import { ServiceOrder, useCreateServiceOrderMutation } from '../../../../generated/graphql';
import { useStore } from '../../../Common/context/store-context';
import { useUser } from '../../../Common/context/user-context';
import { Dialog } from '../../../Common/custom-hooks/useDialog2';
import { transformToCreateServiceOrderInput } from '../../utils/serviceOrderUtils';
import { TabPanelContent } from './ServiceOrderDialog';
import ServiceOrderForm from './ServiceOrderDialog/FormTab/ServiceOrderForm';

interface Props {
    dialogRef: DialogRef;
}

const CreateServiceOrderDialog = ({ dialogRef }: Props) => {
    const [loggedInUser] = useUser();
    const [storeId] = useStore()!;
    const { selectedSubStoreId } = useSubStore();

    const { enqueueSnackbar } = useSnackbar();

    const [
        createServiceOrder,
        { loading: createServiceOrderLoading },
    ] = useCreateServiceOrderMutation({
        onCompleted: (result) => {
            enqueueSnackbar('Serviceordern är nu tillagd!', {
                variant: 'success',
            });
            dialogRef.close(result.createServiceOrder);
        },
        onError: (e) => {
            enqueueSnackbar(e.graphQLErrors[0].message, {
                variant: 'error',
            })
        },
    });

    const onSubmit = async (formData: ServiceOrder) => {
        const serviceOrderInput = transformToCreateServiceOrderInput(
            formData,
            loggedInUser,
            storeId,
            selectedSubStoreId
        );

        await createServiceOrder({
            variables: { serviceOrderInput },
        });
    };

    return (
        <Dialog dialogRef={dialogRef}>
            <Tab>Skapa serviceorder</Tab>
            <TabPanel>
                <TabPanelContent>
                    <ServiceOrderForm
                        loading={createServiceOrderLoading}
                        onSubmit={onSubmit}
                    />
                </TabPanelContent>
            </TabPanel>
        </Dialog>
    );
};

export default CreateServiceOrderDialog;
