import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { ErrorLink } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';

import Auth from '../Auth/Auth';
import config from './../../config';
import cache from './Cache';

const httpLink = new HttpLink({
    uri: config.graphql_api,
});

const webSocketLink = new WebSocketLink({
    uri: config.graphql_api
        .replace('https://', 'wss://')
        .replace('http://', 'ws://'),
    options: {
        reconnect: true,
        connectionParams: async () => {
            return { authorization: await new Auth().getTokenOrWait() };
        },
    },
});

const errorLink = new ErrorLink(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            if (err.extensions.code === 'UNAUTHENTICATED') {
                if (window.location.pathname !== '/') {
                    window.location = '/';
                }
            } else if(!err.message.includes('Access denied')) {
                Sentry.captureException(new Error(err.message), err);
            }
        }
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`, networkError);
    }
});

const authMiddleware = new ApolloLink(async (operation, forward) => {
    const authToken = await new Auth().getToken();
    operation.setContext({
        headers: {
            authorization: authToken,
        },
    });

    return forward(operation);
});

// use with apollo-client
const link = authMiddleware
    .concat(errorLink)
    .concat(
        new SentryLink({
            attachBreadcrumbs: {
                includeQuery: true,
                includeVariables: true,
                includeError: true,
            }
        })
    )
    .concat(
        new ApolloLink((operation, forward) => {
            const sanitizeVariableNames = [
                'query',
                'searchQuery'
            ]

            sanitizeVariableNames.forEach(variableName => {
                if(operation.variables[variableName]) {
                    operation.variables[variableName] = operation.variables[variableName].replace(/'/g, '');
                }
            })

            return forward(operation)
        }))
    .concat(
        split(
            ({ query }) => {
                const { kind, operation } = getMainDefinition(query);
                return (
                    kind === 'OperationDefinition' &&
                    operation === 'subscription'
                );
            },
            webSocketLink,
            httpLink
        )
    );

const client = new ApolloClient({
    link: link,
    cache,
    uri: config.graphql_api,
});

export default client;
