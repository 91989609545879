import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

// Explicitly set CognitoUser to any as there is no sufficient type I found in AWS Library
// Type it whenever possible.
export const UserContext = createContext<[any | undefined, React.Dispatch<React.SetStateAction<any | undefined>>]>([undefined, () => { }]);

interface Props {
    children: React.ReactNode;
}

export const UserContextProvider = ({ children }: Props) => {
    const [user, setUser] = useState<any>();

    useEffect(() => {
        const getAuthenticatedUser = async () => {
            const authenticatedUser = await Auth.currentAuthenticatedUser();

            setUser(authenticatedUser);
        };

        getAuthenticatedUser();
    }, []);

    return (
        <UserContext.Provider value={[user, setUser]}>
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.any,
};

export const useUser = () => {
    return useContext(UserContext);
};
