import gql from 'graphql-tag';

export const SMS_STATISTICS = gql`
    query smsStatistics {
        smsStatistics {
            items {
                store {
                    name
                }
                stats {
                    key
                    successes
                    errors
                }
            }
        }
    }
`;
