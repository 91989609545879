export const SubStoreListColumns = [
    {
        name: 'Namn',
        selector: 'name',
        sortable: true
    }, {
        name: 'Butik',
        selector: 'butik',
        sortable: true
    }, {
        name: 'Admin/Ansvarig',
        selector: 'admin',
        sortable: true
    }, {
        name: 'Status',
        selector: 'status',
        sortable: true
    }];