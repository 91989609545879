import '@aws-amplify/ui/dist/style.css';
import 'react-tiny-fab/dist/styles.css';
import './App.scss';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useGetSystemUserQuery } from 'src/generated/graphql';

import { useStore } from '../Common/context/store-context';
import { useSubStore } from '../Common/context/sub-store-context';
import { useUser } from '../Common/context/user-context';
import ErrorMessage from '../Common/ErrorMessage/ErrorMessage';
import ActionsMenu from '../Layout/ActionsMenu/components/ActionsMenu';
import Menu from '../Layout/Menu/Menu';
import Routes from './Routes';
import TopBar from './TopBar';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const AppContent = () => {
    const [user] = useUser();
    const [, setCurrentStore, , setStores] = useStore();
    const { setSubStores } = useSubStore();
    const {
        data: fetchedUser,
        loading: fetchingUser,
        error
    } = useGetSystemUserQuery({
        variables: { id: user?.attributes?.sub },
        skip: user?.attributes?.sub === undefined,
    });

    const classes = useStyles();

    useEffect(() => {
        if (
            !fetchingUser &&
            fetchedUser &&
            fetchedUser.systemUser.stores.length > 0
        ) {
            const { systemUser } = fetchedUser;

            const prevStoreId = localStorage.getItem('selectedStore');
            let storeId;

            if (
                prevStoreId &&
                systemUser.stores.find(
                    (store) => Number(store.id) === Number(prevStoreId)
                )
            ) {
                storeId = prevStoreId;
            } else {
                storeId = systemUser.stores[0].id;
            }

            setStores(systemUser.stores.map(({ id, name }) => ({ id, name })));
            setCurrentStore(parseFloat(storeId));

            setSubStores(systemUser.subStores);
        }
    }, [
        fetchedUser,
        fetchingUser,
        setCurrentStore,
        setStores,
        setSubStores
    ]);

    const signOut = () => {
        Auth.signOut().then(() => {
            document.location = '/';
        });
    };

    if (error) {
        return <ErrorMessage error={error} />;
    }

    if (fetchingUser || !fetchedUser) {
        return (
            <Grid
                container
                alignItems='center'
                justify='center'
                style={{ minHeight: '100vh' }}
            >
                <CircularProgress color='secondary' />
            </Grid>
        );
    }

    if (fetchedUser.systemUser.stores.length === 0) {
        return (
            <Grid
                container
                alignItems='center'
                justify='center'
                style={{ minHeight: '100vh' }}
            >
                <Card>
                    <CardContent className={classes.root}>
                        <Typography variant='h5' component='h2'>
                            Hoppsan! Du har inga butiker satta på dig och kan
                            därför inte logga in.
                        </Typography>
                        <Typography
                            className={classes.title}
                            color='textSecondary'
                            gutterBottom
                        >
                            Kontakta din systemadministratör för hjälp.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size='small' onClick={signOut}>
                            Logga ut
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        );
    }

    return (
        <>
            <TopBar stores={fetchedUser.systemUser.stores} />
            <div className='content-area'>
                <div className='menu'>
                    <Menu unreadSmsCount={fetchedUser.systemUser.unreadSmsCount} />
                </div>
                <div className='content'>
                    <div className='style-bar' />
                    <Routes />
                    <ActionsMenu id='main-action-menu' />
                    <footer>
                        Sportson Servicesystem {new Date().getFullYear()}
                    </footer>
                </div>
            </div>
        </>
    );
};

export default AppContent;
