import styled from "@emotion/styled";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";
import React, { useState } from 'react';

import {
    ServiceOrder,
    UpdateServiceOrderInput,
    useUpdateInspectionReportOnServiceOrderMutation
} from '../../../../../../generated/graphql';
import AppButton from "../../../../../Common/Form/AppButton";
import { FormStatus } from '../../../../../Common/Form/FormStatus';
import { DocumentButton } from './components/DocumentButton';
import { NoFrameNumberWarning } from './components/NoFrameNumberWarning';
import InspectionProtocolView, { InspectionProtocolViewProps } from './InspectionProtocolView';

const Wrapper = styled.div`
    overflow-y: auto;
    height: 100%;
    position: relative
`;

const WrapperInner = styled.div`
    display: flex;
`;

const MiniActions = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
`;

const InspectionProtocolTab = ({ serviceOrder }: { serviceOrder: ServiceOrder }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { selectedId, setSelectedId, inspectionReport, config, buttons, link, finished, bike } = useInspectionProtocol(serviceOrder);

    const [updateInspectionProtocol] = useUpdateInspectionReportOnServiceOrderMutation({
        onError: () => enqueueSnackbar('Kunde inte uppdatera serviceorder!', { variant: 'error' })
    });

    const update = async (serviceOrderInput: UpdateServiceOrderInput) => {
        await updateInspectionProtocol({
            variables: { serviceOrderInput, id: serviceOrder.id }
        });
    }

    return (
        <Wrapper>
            <MiniActions>
                <FormStatus loading={false} errors={[]} />
                <DocumentButton link={link} />
            </MiniActions>
            <Box display="flex" gridGap={10}>
                {buttons.map((protocol, key) => (
                    <AppButton
                        key={key}
                        type="submit"
                        variant={selectedId === protocol.id ? 'outlined' : 'contained'}
                        style={{ textTransform: 'none' }}
                        onClick={() => setSelectedId(protocol.id)}
                    >{protocol.label}</AppButton>
                ))}
            </Box>
            <WrapperInner>
                { config.options.isBikeFrameNumberRequired && !bike?.frameNumber ? (
                    <Box py={2}>
                        <NoFrameNumberWarning />
                    </Box>
                ) : (
                    <InspectionProtocolView {...{ inspectionReport, selectedProtocolId: selectedId, update, config, finished }} />
                )}
            </WrapperInner>
        </Wrapper>
    );
}

export default InspectionProtocolTab;

function useInspectionProtocol(serviceOrder: ServiceOrder) {
    const [selectedId, setSelectedId] = useState<number>(1);

    const inspectionReport = serviceOrder.inspectionReports?.find((report) => report.inspectionProtocol!.id === selectedId);

    const protocols: InspectionProtocolViewProps['config'][] = [
        {
            id: 1,
            key: 'default',
            label: "Sportson",
            options: {
                isBikeFrameNumberRequired: false,
            },
            columns: [
                {
                    label: 'Godkänd',
                    key: 'pass'
                },
                {
                    label: 'Slitet',
                    key: 'worn'
                },
                {
                    label: 'Underkänd',
                    key: 'fail'
                }
            ]
        },
        {
            id: 2,
            key: 'cycleurope',
            label: "CykELkärlek",
            options: {
                isBikeFrameNumberRequired: true,
            },
            columns: [
                {
                    prefix: 'mån',
                    label: '1-3',
                    key: 'month-1-3'
                },
                {
                    prefix: 'mån',
                    label: '12',
                    key: 'month-12'
                },
                {
                    prefix: 'mån',
                    label: '24',
                    key: 'month-24'
                },
                {
                    prefix: 'mån',
                    label: '36',
                    key: 'month-36'
                }
            ]
        }
    ];

    const config = protocols.find((protocol) => protocol.id === selectedId) || {} as InspectionProtocolViewProps["config"];

    const buttons = protocols.map((protocol) => {
        const { id, label } = protocol;
        return { id, label }
    });

    const link = serviceOrder.inspectionReportLinks.find((l) => l.key === config.key);

    const finished = serviceOrder.status === 'closed';

    const bike = serviceOrder.customerBike;

    return { selectedId, setSelectedId, inspectionReport, config, buttons, link, finished, bike };
}
