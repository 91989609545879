import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

export function NoFrameNumberWarning() {
    return (
        <Alert severity="warning">
            <AlertTitle>Cykeln som valts saknar ramnummer.</AlertTitle>
            Vänligen skapa en ny cykel med ramnummer ifyllt.
        </Alert>
    );
}
