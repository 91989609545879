import React from 'react';

import { useStore } from '../../Common/context/store-context';
import ScheduleDeviationTable from './ScheduleDeviationTable';

export function ScheduleDeviationsTableWrapper() {
    const [storeId] = useStore();

    if (!storeId) {
        return null;
    }

    return <ScheduleDeviationTable key={storeId} storeId={storeId} />
}
