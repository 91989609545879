import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

export const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    inputWrapper: {
        position: 'relative',
        flexGrow: 1,
    },
    openDialog: {
        position: 'relative',
        left: '5px',
    },
    overlay: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    disabledOverlay: {
        position: 'absolute',
        background: 'rgba(255,255,255,0.6)',
        top: -5,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

export const InputButtonDecorator = ({ children, onButtonClick, buttonTitle, faIcon, disabled, hide }) => {
    const classes = useStyles();

    if (hide) return children;

    return (
        <div className={classes.root}>
            {children}
            <IconButton
                onClick={() => onButtonClick()}
                color='primary'
                title={buttonTitle}
                disabled={disabled}
            >
                <FontAwesomeIcon
                    fontSize={21}
                    icon={faIcon}
                />
            </IconButton>
        </div>
    );
}
