import gql from 'graphql-tag';

import client from '../../../services/GraphQL/client';

const SEARCH_BY_LEGAL_ID = gql`
    query bisnodeFindByLegalId($legalId: String!) {
        bisnodeFindByLegalId(legalId: $legalId) {
            firstName
            lastName
            address1
            postalCode
            city
            phoneNumber
            cellPhoneNumber
        }
    }
`;

const SEARCH_BY_PHONE = gql`
    query bisnodeFindByPhone($phone: String!) {
        bisnodeFindByPhone(phone: $phone) {
            firstName
            lastName
            address1
            postalCode
            city
            phoneNumber
            cellPhoneNumber
        }
    }
`;

export function useBisnode() {
    return {
        searchLegalId: (legalId) => {
            return client.query({
                query: SEARCH_BY_LEGAL_ID,
                fetchPolicy: 'no-cache',
                variables: { legalId },
            }).then((result) => result.data.bisnodeFindByLegalId);
        },
        searchPhone: (phone) => {
            return client.query({
                query: SEARCH_BY_PHONE,
                fetchPolicy: 'no-cache',
                variables: { phone },
            }).then((result) => result.data.bisnodeFindByPhone);
        },
    };
}
