import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { useConfirmDialog } from '../../../../../Common/Common/ConfirmDialog';
import AppButton from '../../../../../Common/Form/AppButton';


export function DeleteButton({ serviceOrder, disabled, onDelete }) {
    const confirmDialog = useConfirmDialog();

    const onClick = () => {
        confirmDialog({
            content: `
                Är du säker på att du vill radera serviceorder ${serviceOrder.orderNumber}?
                Det här går inte att ångra.
                `,
            buttons: [
                {
                    label: 'Avbryt',
                    variant: 'outlined',
                },
                {
                    label: 'Radera',
                    action: onDelete,
                },
            ],
        });
    };

    return (
        <AppButton
            className='button-red'
            variant='outlined'
            disabled={disabled}
            onClick={onClick}
            startIcon={
                <FontAwesomeIcon icon={faTrash} />
            }
        >
            Radera
        </AppButton>
    );
}
