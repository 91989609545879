import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useSnackbar } from 'notistack';
import React from 'react';
import SimpleConfirmDialog from 'src/modules/Common/Common/SimpleConfirmDialog';

import {
    CompanyCustomerSearchResult,
    Customer,
    useGetCustomerByIdLazyQuery,
} from '../../../generated/graphql';

type ConfirmUseCompanyCustomerDialogType = {
    open: boolean,
    onConfirm: (customer: Customer) => void,
    onCancel: () => void,
    matches: CompanyCustomerSearchResult[],
}

export function ConfirmUseCompanyCustomerDialog(props: ConfirmUseCompanyCustomerDialogType) {
    const {
        open,
        onConfirm,
        onCancel,
        matches,
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [getCustomerById]  = useGetCustomerByIdLazyQuery({
        onCompleted: (data) => {
            if (data?.customer) {
                onConfirm(data.customer);
            }
        },
        onError: () => enqueueSnackbar('Kunde inte hämta företagskund.', { variant: 'error' })
    });

    const [ companyCustomer ] = matches;

    return (
        <SimpleConfirmDialog
            title="Det finns redan ett företag med samma organisationsnummer"
            confirmLabel="Ja, välj"
            cancelLabel="Nej, avbryt"
            open={open}
            onConfirm={() => {
                if (companyCustomer?.customerId) {
                    getCustomerById({ variables: { id: companyCustomer.customerId } })
                }
            }}
            onCancel={onCancel}
            onClose={onCancel}
        >
            {companyCustomer ? (
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Org.nummer:</TableCell>
                            <TableCell>{companyCustomer?.orgNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Företagsnamn:</TableCell>
                            <TableCell>{companyCustomer?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>E-post:</TableCell>
                            <TableCell>{companyCustomer?.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Telefon:</TableCell>
                            <TableCell>{companyCustomer?.phoneNumber}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            ) : null}

            <Box my={3}>
                <Typography>Vill du välja denna kund eller söka på ett annat organisationsnummer?</Typography>
            </Box>
        </SimpleConfirmDialog>
    );
}
