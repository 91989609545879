import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import React, { MouseEvent, PropsWithChildren,useState } from 'react';

export const AppMenu = ({ children }: PropsWithChildren<any>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant='outlined'
                color='primary'
                onClick={handleClick}
                style={{ height: '34.5px' }}
            >
                <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {children}
            </Menu>
        </>
    );
}
