import { useSystemUsersQuery } from 'src/generated/graphql';

export function useMechanics(storeId: number) {
    const result = useSystemUsersQuery({
        fetchPolicy: 'cache-and-network',
    });

    const allUsers = result.data?.systemUsers.items || []

    // filter by storeId
    const users = allUsers.filter((user) => {
        return !!user.assignedStores.find((store) => store.id === storeId);
    });

    return {
        ...result,
        users
    };
}
