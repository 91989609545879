import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';

import { Qztray } from '../../../services/Qztray/Qztray';
import { BootstrapInput, MiniSelect, MiniSelectLabel } from '../../Common/Common/MiniSelect';
import { usePrinters } from '../../Common/custom-hooks/sharedSubjects/usePrinters';

export function PrinterSelector({ ...rest }) {

    const [value, setValue] = useState(Qztray.getPrinter() || 'none');

    const [printers] = usePrinters();

    const options = printers || [];

    const onSelect = (v) => {
        setValue(v);
        Qztray.setPrinter(v);
    };

    const renderValue = (v) => {
        return options.find((o) => o === v)
            || 'Ingen skrivare vald';
    };

    return (
        <div {...rest}>
            <MiniSelectLabel>Etikettskrivare:</MiniSelectLabel>{' '}
            <MiniSelect
                onOpen={Qztray.refreshPrinterList}
                value={value}
                onChange={(e) => onSelect(e.target.value)}
                input={<BootstrapInput />}
                renderValue={renderValue}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </MiniSelect>
        </div>
    );
}
