import gql from 'graphql-tag';

export const EDIT_STORE_ON_USER = gql`
    mutation editStoreOnUser($userId: String!, $storeId: Int!) {
        editUserOnStore(userId: $userId, storeId: $storeId)
    }
`;

export const ADD_USER_TO_STORE = gql`
    mutation addUserToStore($userId: String!, $storeId: Int!) {
        addUserToStore(userId: $userId, storeId: $storeId)
    }
`;

export const REMOVE_USER_FROM_STORE = gql`
    mutation removeUserFromStore($userId: String!, $storeId: Int!) {
        removeUserFromStore(userId: $userId, storeId: $storeId)
    }
`;

export const GET_STORES = gql`
    query getStores {
        stores {
            items {
                id
                name
            }
        }
    }
`;

export const SYSTEM_USERS = gql`
    query systemUsers($storeId: Int) {
        systemUsers(storeId: $storeId) {
            items {
                id
                name
                email
                groups
                assignedStores {
                    id
                    name
                }
            }
        }
    }
`;

export const CREATE_USER = gql`
    mutation createUser(
        $email: String!
        $name: String!
        $tempPassword: String!
    ) {
        createUser(
            email: $email,
            name: $name,
            tempPassword: $tempPassword,
        )
    }
`;

export const CREATE_USER_FOR_STORE = gql`
    mutation createUserForStore(
        $email: String!
        $name: String!
        $tempPassword: String!
        $storeId: Int!
    ) {
        createUserForStore(
            email: $email,
            name: $name,
            tempPassword: $tempPassword,
            storeId: $storeId,
        )
    }
`;

export const ADD_SYSTEM_USER_TO_GROUP = gql`
    mutation addSystemUserToGroup(
        $userId: String!
        $groupName: String!
    ) {
        addSystemUserToGroup(
            userId: $userId,
            groupName: $groupName,
        )
    }
`;

export const REMOVE_SYSTEM_USER_FROM_GROUP = gql`
    mutation removeSystemUserFromGroup(
        $userId: String!
        $groupName: String!
    ) {
        removeSystemUserFromGroup(
            userId: $userId,
            groupName: $groupName,
        )
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($userId: String!) {
        deleteUser(userId: $userId)
    }
`;

export const SET_PASSWORD = gql`
    mutation setPassword($userId: String!, $tempPassword: String!) {
        setPassword(userId: $userId, tempPassword: $tempPassword)
    }
`;

export const EDIT_USER = gql`
    mutation editUser($userId: String!, $name: String!) {
        editUser(userId: $userId, name: $name)
    }
`;
