import createMuiTheme from '@material-ui/core/es/styles/createMuiStrictModeTheme';
// import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

// See: https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode

export const theme = {
    palette: {
        primary: {
            light: '#3d3d3d',
            main: '#262626',
            dark: '#0b0b0b',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffd851',
            main: '#fecb00',
            dark: '#ebbc00',
            contrastText: '#000',
        },
    },
};

export const appTheme = createMuiTheme(theme);
