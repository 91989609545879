import gql from 'graphql-tag';

export const GET_SCHEDULE_STANDARD = gql`
    query scheduleStandard($storeId: Float!) {
        scheduleStandard(storeId: $storeId) {
            storeId
            minutesPerJob
            tooEasyId
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
        }
    }
`;

export const UPDATE_SCHEDULE_STANDARD = gql`
    mutation saveScheduleStandard(
        $storeId: Float!
        $formValues: UpdateScheduleStandardInput!
    ) {
        saveScheduleStandard(
            storeId: $storeId
            scheduleStandardInput: $formValues
        ) {
            storeId
            minutesPerJob
            tooEasyId
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
        }
    }
`;
