import gql from 'graphql-tag';

export const CREATE_CUSTOMER_BIKE = gql`
    mutation CreateCustomerBike($createCustomerBikeInput: CustomerBikeInput!) {
        createCustomerBike(createCustomerBikeInput: $createCustomerBikeInput) {
            id
            customerId
            frameNumber
            description
            manufacturer
        }
    }
`;
