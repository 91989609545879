import { faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import AppButton from '../../../../../Common/Form/AppButton';


export const StatusBackButtons = ({ serviceOrder, onChange, disabled }) => {

    if (!serviceOrder?.id) {
        return null;
    }

    const buttons = [];
    const isStatus = (statuses = []) => statuses.includes(serviceOrder.status);

    if (isStatus([
        'received',
        'not_received_parts_awaiting',
    ])) {
        buttons.push((
            <AppButton
                key={'new'}
                variant={'outlined'}
                disabled={disabled}
                onClick={() => onChange('new')}
                startIcon={<FontAwesomeIcon icon={faUndoAlt} />}
            >
                Skapad
            </AppButton>
        ));
    }

    if (isStatus([
        'processing',
    ])) {
        buttons.push((
            <AppButton
                key={'received'}
                variant={'outlined'}
                disabled={disabled}
                onClick={() => onChange('received')}
                startIcon={<FontAwesomeIcon icon={faUndoAlt} />}
            >
                Mottagen
            </AppButton>
        ));
    }

    if (isStatus([
        'done',
    ])) {
        buttons.push((
            <AppButton
                key={'processing'}
                variant={'outlined'}
                disabled={disabled}
                onClick={() => onChange('processing')}
                startIcon={<FontAwesomeIcon icon={faUndoAlt} />}
            >
                Arbete pågår
            </AppButton>
        ));
    }

    return buttons;
};
