import { useSnackbar } from 'notistack';
import { useOnServiceOrderChangeSubscription } from 'src/generated/graphql';
import { useUser } from 'src/modules/Common/context/user-context';

export function useServiceOrderModificationWarning(serviceOrderId: number) {
    const { enqueueSnackbar } = useSnackbar();
    const [user] = useUser();

    useOnServiceOrderChangeSubscription({
        variables: { id: serviceOrderId },
        onSubscriptionData: ({ subscriptionData }) => {
            const payload = subscriptionData.data?.serviceOrderChange;
            if (payload && (payload.userId !== user?.username)) {
                enqueueSnackbar('En annan användare har redigerat ordern! Stäng ner fönstret för att ladda om.', {
                    variant: 'warning',
                });
            }
        }
    })
}
