import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';

import { vatFormat } from '../../../../../utils/productHelper';

export function VatSelect(props) {
    const {
        onChange,
        value,
        disabled,
        hide12Vat,
    } = props;

    if (disabled) {
        return vatFormat(value);
    }

    return (
        <FormControl className='input-fontsize-inherit'>
            <Select
                className='vat-selector'
                value={value}
                onChange={(e) => onChange(e.target.value)}
            >
                {(6 === value || !hide12Vat) && <MenuItem value={6}>{vatFormat(6)}</MenuItem>}
                <MenuItem value={12}>{vatFormat(12)}</MenuItem>
                <MenuItem value={25}>{vatFormat(25)}</MenuItem>
            </Select>
        </FormControl>
    );
}
