import { Box, BoxProps } from '@material-ui/core';
import React from 'react';

export const CustomStack = ({ style, direction = 'column', gap = 0, ...props }: { direction?: 'column' | 'row', gap?: number } & BoxProps) => <Box
    {...props}
    style={{
        display: 'flex',
        flexDirection: direction,
        gap,
        ...style,
    }}
/>;
