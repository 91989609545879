import 'react-tabs/style/react-tabs.css';
import './dialog.scss';

import React, { ReactElement, useEffect } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { BehaviorSubject } from 'rxjs';

export const dialogsSubject = new BehaviorSubject<DialogRef[]>([]);

export type onCloseCallable<OnCloseT> = (props: OnCloseT) => void;

export interface DialogRef<OnCloseT = any> {
    onClose: onCloseCallable<OnCloseT | undefined>[];
    options: any;
    content: any;
    key: number;
    close: (props?: OnCloseT) => void;
}

let dialogIncrement = 0;
export let dialogList: DialogRef[] = [];

export const useDialog3 = () => {

    const onCountChange = (dialogList: DialogRef[]) => {
        if (dialogList.length) {
            document.body.classList.add('modal-open3');
        } else {
            document.body.classList.remove('modal-open3');
        }
    };

    function createDialog<OnCloseT>(content: (dialogRef: DialogRef) => any, options: any = {}) {
        const dialogRef = {} as DialogRef<OnCloseT>;

        dialogRef.key = dialogIncrement++;
        dialogRef.content = content(dialogRef);
        dialogRef.options = options;
        dialogRef.onClose = [];

        dialogRef.close = (props: OnCloseT | undefined) => {
            dialogRef.onClose.map((callback) => callback(props));
            dialogList = dialogsSubject.value.filter(
                (dialog) => dialog.key !== dialogRef.key,
            );

            dialogsSubject.next([...dialogList]);
            onCountChange(dialogList);
        };

        dialogList.push(dialogRef);
        dialogsSubject.next([...dialogList]);

        onCountChange(dialogList);

        return dialogRef;
    }

    return { createDialog };
};

export interface Tab2 {
    label: string,
    content: ReactElement,
    testId?: string
}

export interface DialogProps {
    dialogRef: DialogRef,
    tabs: Tab2[],
}

export const useCloseDialogOnEscape = (dialogRef: DialogRef) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && dialogRef === dialogList[dialogList.length - 1]) {
                dialogRef.close();
                window.removeEventListener('keydown', handleKeyDown);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
    }, [dialogRef]);
}

export const Dialog = (props: DialogProps) => {
    const { dialogRef, tabs } = props;

    useCloseDialogOnEscape(dialogRef)

    // I just had to remove this stupidity.
    // It was the same index for all tabs everywhere. WHY!?
    // const persistedTabIndex = parseInt(
    //     sessionStorage.getItem('selectedTabIndex') || ''
    // );
    //
    // const onSelect = (tabIndex: number) => {
    //     sessionStorage.setItem('selectedTabIndex', tabIndex.toString());
    // };

    let classes = 'dialog2';
    const defaultIndex = dialogRef.options.defaultIndex || 0;

    if (dialogRef.options.fullscreen) {
        classes += ' dialog2-fullscreen';
    }

    return (
        <div className={classes} tabIndex={-1}>
            <div className='dialog2-tabs-wrapper'>
                <Tabs
                    defaultIndex={defaultIndex}
                    //onSelect={onSelect}
                    forceRenderTabPanel
                    className='dialog2-tabs'
                >
                    <TabList className='dialog2-tab-list'>
                        {tabs.map((tab, index) => (
                            <Tab
                                data-testid={tab.testId}
                                className="dialog2-tab"
                                selectedClassName="dialog2-selected-tab"
                                key={'Tab-' + index}
                                data-tab-text={tab.label}
                            >{tab.label}</Tab>
                        ))}
                    </TabList>
                    <div className='dialog2-panel-wrapper'>
                        {tabs.map((tab, index) => (
                            <TabPanel
                                key={'TabPanel-' + index}
                            >{tab.content}</TabPanel>
                        ))}
                    </div>
                    <button
                        className='dialog2-close-button'
                        onClick={() => dialogRef.close()}
                        type='button'
                        aria-label='Close'
                    >
                        <span aria-hidden='true'>&times;</span>
                    </button>
                </Tabs>
            </div>
        </div>
    );
};


