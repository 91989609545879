import { format } from 'date-fns';
import gql from 'graphql-tag';
import React from 'react';

import DataList from '../../../../../Common/DataList/DataList';
import { orderStatusText } from '../../../../utils/serviceOrderFields';
import ServiceOrderDialog from '../../ServiceOrderDialog';

const customStyles = {
    rows: {
        style: {
            cursor: 'pointer',
        },
    },
};

const showServiceOrder = (
    serviceOrderId,
    refetchServiceOrders,
    createDialog
) => {
    const ref = createDialog((ref) => (
        <ServiceOrderDialog serviceOrderId={serviceOrderId} dialogRef={ref} />
    ));
    ref.onClose.push(refetchServiceOrders);
};

export default class ServiceOrderHistoryList extends DataList {
    constructor(params) {
        super(params);

        this.baseFilters.push({
            field: 'customerId',
            stringValue: this.props.customerId?.toString(),
        });

        this.columns = [
            {
                name: 'Datum',
                selector: 'serviceDate',
                cell: (row) => format(new Date(row.serviceDate), 'yyyy-MM-dd'),
                width: '120px',
                sortable: true,
            },
            {
                name: 'Ordernummer',
                selector: 'orderNumber',
                sortable: true,
            },
            {
                name: 'Status',
                selector: 'status',
                cell: (row) => orderStatusText(row.status),
                sortable: true,
            },
            {
                name: 'Cykel',
                selector: 'customerBike',
                cell: ({ customerBike }) =>
                    customerBike
                        ? `${customerBike.manufacturer} ${customerBike.frameNumber}`
                        : '',
                sortable: true,
            },
            {
                name: 'Beskrivning',
                selector: 'description',
                grow: 2,
                sortable: true,
            },
            {
                name: 'Användare',
                selector: 'serviceByName',
                sortable: true,
            },
        ];

        this.onRowClicked = (row) =>
            showServiceOrder(row.id, this.refetch, this.props.createDialog);

        this.customStyles = customStyles;

        this.query = gql`            
            query getCustomerServiceOrders(
                $filter: FilterArgument
                $page: Int
                $pageSize: Int
                $orderBy: String
                $orderDir: OrderByDirection
            ) {
                orders(
                    filter: $filter
                    page: $page
                    pageSize: $pageSize
                    orderBy: $orderBy
                    orderDir: $orderDir
                ) {
                    total
                    hasMore
                    items {
                        id
                        serviceDate
                        status
                        customerBike {
                            manufacturer
                            frameNumber
                            description
                        }
                        serviceByName
                    }
                }
            }
        `;

        this.options = {
            initialPagination: {
                orderBy: 'serviceDate',
            },
        };

        this.queryKey = 'orders';
    }
}
