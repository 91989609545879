import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AppCheckbox from 'src/modules/Common/Form/AppCheckbox';

import {
    Customer,
    useCreateCustomerMutation,
    useGetCustomersByPhoneNumberLazyQuery,
    useGetCustomersBySocialSecurityNumberLazyQuery, useUpdateCustomerMutation,
} from '../../../generated/graphql';
import LoaderCircular from '../../Common/Common/LoaderCircular';
import { DialogRef } from '../../Common/custom-hooks/useDialog3';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import AppButton from '../../Common/Form/AppButton';
import TextInput from '../../Common/Form/TextInput';
import { ExistingCustomerDialog } from "../components/ExistingCustomerDialog";
import { InputButtonDecorator } from '../components/Inputs';
import { useBisnode } from '../hooks/useBisnode';

type CustomerCheckType = {
    checked: boolean,
    open: boolean,
    matches: Customer[],
    type: 'phone' | 'ssn' | '',
    onConfirm: () => void
};

const CustomerForm = (props: {
    dialogRef: DialogRef,
    defaultValues?: Customer,
    customerId?: number,
}) => {
    const {
        dialogRef,
        defaultValues,
        customerId
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    // @todo Could this be in the Form?
    const [createCustomer, { loading, error }] = useCreateCustomerMutation({
        onCompleted: () => enqueueSnackbar('Kund skapad och tillagd!', { variant: 'success' }),
        onError: () => enqueueSnackbar('Kunde inte skapa kund!', { variant: 'error' }),
    });

    const [updateCustomer, { error: updateError }] = useUpdateCustomerMutation({
        onCompleted: () => enqueueSnackbar('Kund uppdaterad!', { variant: 'success' }),
        onError: () => enqueueSnackbar('Kunde inte uppdatera kund!', { variant: 'error' }),
    });

    const [customerCheck, setCustomerCheck] = useState<CustomerCheckType>({ checked: false, open: false, matches: [], type: '', onConfirm: () => {} });

    const { searchLegalId, searchPhone } = useBisnode();

    let methods;
    const { control, handleSubmit, setValue, getValues, errors, watch } = methods = useForm({
        defaultValues: {
            socialSecurityNumber: defaultValues?.socialSecurityNumber || '',
            firstName: defaultValues?.firstName || '',
            lastName: defaultValues?.lastName || '',
            address1: defaultValues?.address1 || '',
            address2: defaultValues?.address2 || '',
            addressId: defaultValues?.addressId || '',
            email: defaultValues?.email || '',
            postalCode: defaultValues?.postalCode || '',
            city: defaultValues?.city || '',
            consentSms: defaultValues ? !!defaultValues?.consentSms : true,
            consentEmail: !!defaultValues?.consentEmail,
            useAutomaticSms: defaultValues?.useAutomaticSms,
            consent: !!defaultValues?.consent,
            cellPhoneNumber: defaultValues?.cellPhoneNumber ||
                defaultValues?.phoneNumber || '',
            cellPhoneNumberForNotifications: defaultValues?.cellPhoneNumberForNotifications ||
                defaultValues?.cellPhoneNumberForNotifications || '',
        },
    });

    const [getCustomersByPhoneNumber, customersByPhoneNumber] = useGetCustomersByPhoneNumberLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: result => {
            setCustomerCheck(prevState => {
                return {
                    ...prevState,
                    open: true,
                    matches: result.customersByPhoneNumber?.items ?? [],
                    type: 'phone',
                    onConfirm: () => {
                        onValidPhoneNumberDoCallback(async (value) => {
                            const customerData = await searchPhone(value);

                            if (customerData) patchFormWithSearchResult(customerData);
                        });
                    },
                };
            });
        },
    });

    const [getCustomersBySocialSecurityNumber, customersBySocialSecurityNumber] = useGetCustomersBySocialSecurityNumberLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: result => {
            setCustomerCheck(prevState => ({
                ...prevState,
                open: true,
                matches: result.customersBySocialSecurityNumber?.items ?? [],
                type: 'ssn',
                onConfirm: () => {
                    onValidSocialSecurityNumberDoCallback(async (value) => {
                        const customerData = await searchLegalId(value);

                        if (customerData) patchFormWithSearchResult(customerData);
                    });
                }
            }));
        },
    });

    const cellPhoneNumber = watch('cellPhoneNumber', '');

    function patchFormWithSearchResult(customerData: Customer) {
        const currentValues = getValues();
        setValue('socialSecurityNumber', customerData.socialSecurityNumber || currentValues.socialSecurityNumber);
        setValue('firstName', customerData.firstName || currentValues.firstName);
        setValue('lastName', customerData.lastName || currentValues.lastName);
        setValue('address1', customerData.address1 || currentValues.address1);
        setValue('postalCode', customerData.postalCode || currentValues.postalCode);
        setValue('city', customerData.city || currentValues.city);
        setValue('cellPhoneNumber', currentValues.cellPhoneNumber || customerData.cellPhoneNumber);
    }

    const onValidSocialSecurityNumberDoCallback = (callback: (socialSecurityNumber: Customer['socialSecurityNumber']) => void) => {
        if (typeof callback !== 'function') return;

        const socialSecurityNumber = getValues().socialSecurityNumber;

        if (socialSecurityNumber.length !== 12) return false;

        callback(socialSecurityNumber);
    }

    const onValidPhoneNumberDoCallback = (callback: (cellPhoneNumber: Customer['cellPhoneNumber']) => void) => {
        if (typeof callback !== 'function') return;

        const cellPhoneNumber = getValues().cellPhoneNumber;

        if (cellPhoneNumber.length < 5) return false;

        callback(cellPhoneNumber);
    }

    const validateConsent = () => {
        const currentValues = getValues();
        if (!currentValues.consentEmail && !currentValues.consentSms) {
            return 'Måste ange minst en föredragen kommunikation'
        }

        return true;
    }

    const onSubmit = async (formValues: any) => {
        if (customerId) {
            const updatedCustomerData = await updateCustomer({
                variables: {
                    customerInput: formValues,
                    id: Number(customerId),
                },
            });
            dialogRef.close(updatedCustomerData.data?.updateCustomer);

        } else {
            const createdCustomer = await createCustomer({
                variables: { createCustomerInput: formValues },
            });
            dialogRef.close(createdCustomer.data?.createCustomer);
        }
    };

    return (
        <FormProvider {...methods}>
            <ExistingCustomerDialog
                customers={ customerCheck.matches }
                isOpen={ customerCheck.open }
                onConfirm={() => {
                    // We also should handle this on submit with a different text for confirmation and no callback, just allow the user to save.

                    // Execute confirm handler
                    customerCheck.onConfirm();

                    setCustomerCheck(prevState => ({
                        ...prevState,
                        open: false, // Hide the dialog
                        checked: true,
                        onConfirm: () => {},
                    }));
                }}
                onCancel={ () => setCustomerCheck(prevState => ({
                    ...prevState,
                    matches: [],
                    open: false, // Hide the dialog
                    onConfirm: () => {},
                })) }
                onCustomerSelect={ (customer: Customer) => dialogRef.close(customer) }
                disabled={ !!customerId }
            />
            <LoaderCircular
                visible={loading}
                size={50}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}
                    style={{ maxWidth: 700, margin: '0 auto' }}
                >
                    <Grid item xs={6}>
                        <InputButtonDecorator
                            onButtonClick={() => onValidSocialSecurityNumberDoCallback(async (socialSecurityNumber) => {
                                if (!socialSecurityNumber) return;
                                getCustomersBySocialSecurityNumber({ variables: { socialSecurityNumber: socialSecurityNumber } });
                            })}
                            buttonTitle='Hämta kontaktuppgifter'
                            faIcon={faSearch}
                            disabled={customersBySocialSecurityNumber.loading}
                            hide={!!customerId}
                        >
                            <TextInput
                                label="Personnummer"
                                name="socialSecurityNumber"
                                placeholder="12 siffror utan bindestreck"
                                inputProps={{
                                    pattern: "[0-9]*",
                                    max: 999999999999,
                                }}
                            />
                        </InputButtonDecorator>
                    </Grid>
                    <Grid item xs={6}>
                        {/* empty */}
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label='Förnamn'
                            name='firstName'
                            validation={{
                                required: 'Detta fält är obligatoriskt',
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label='Efternamn'
                            name='lastName'
                            validation={{
                                required: 'Detta fält är obligatoriskt',
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label='Adressrad 1'
                            name='address1'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label='E-post'
                            name='email'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label='Adressrad 2'
                            name='address2'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputButtonDecorator
                            onButtonClick={() => onValidPhoneNumberDoCallback(async (cellPhoneNumber) => {
                                if (!cellPhoneNumber) return;

                                getCustomersByPhoneNumber({ variables: { phone: cellPhoneNumber } });
                            })}
                            buttonTitle='Hämta kontaktuppgifter'
                            faIcon={faSearch}
                            disabled={customersByPhoneNumber.loading}
                            hide={!!customerId}
                        >
                            <TextInput
                                label='Telefon'
                                name='cellPhoneNumber'
                                validation={{
                                    required: 'Detta fält är obligatoriskt',
                                }}
                            />
                        </InputButtonDecorator>
                    </Grid>
                    <Grid item xs={2}>
                        <TextInput
                            label='Postnummer'
                            name='postalCode'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            label='Ort'
                            name='city'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label='Telefonnummer för utskick'
                            name='cellPhoneNumberForNotifications'
                            placeholder={cellPhoneNumber ?? ''}
                            helperText="Hit skickas sms-utskick"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            error={!!(errors['consentEmail'] || errors['consentSms'] || errors['useAutomaticSms'])}
                            component="fieldset"
                        >
                            <FormLabel component='legend' style={{ marginTop: 10 }}>
                                Föredragen kommunikation
                            </FormLabel>
                            <FormGroup row={true}>
                                <AppCheckbox
                                    label='Epost'
                                    name='consentEmail'
                                    disabled={true}
                                    control={control}
                                    controllerProps={{
                                        rules: {
                                            validate: validateConsent,
                                        },
                                    }}
                                />
                                <AppCheckbox
                                    label='Sms'
                                    name='consentSms'
                                    control={control}
                                    controllerProps={{
                                        rules: {
                                            validate: validateConsent,
                                        },
                                    }}
                                />
                            </FormGroup>
                            {/* <FormGroup row={true}>
                                <AppCheckbox
                                    label='Automatiska sms-utskick'
                                    name='useAutomaticSms'
                                    control={control}
                                />
                            </FormGroup> */}
                            <FormHelperText>
                                {errors['consentEmail']?.message || errors['consentSms']?.message || ''}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <AppCheckbox
                            label="Godkänner marknadskommunikation"
                            name="consent"
                            control={control}
                            checkboxProps={{
                                style: { display: 'block' },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorMessage style={{ margin: '0 20px 0 0' }} error={updateError} />
                        <ErrorMessage style={{ margin: '0 20px 0 0' }} error={error} />
                        <div className='button-wrapper-right'>
                            <AppButton
                                disabled={loading}
                                type='submit'
                                variant='contained'
                                startIcon={
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                }
                            >
                                Spara kund
                            </AppButton>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default CustomerForm;
