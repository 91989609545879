import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

export function useSharedSubject<T>(subject: Subject<T>) {
    const [data, setData] = useState<T>();
    useEffect(() => {
        const subscription = subject.subscribe((v) => setData(v));
        return () => subscription.unsubscribe();
    }, [subject]);

    const update = (newData: T) => subject.next(newData);

    return [data, update];
}

