import React from 'react';
import { HeadsLogStatus as HeadsLogStatusEnum } from 'src/generated/graphql';

interface Props {
    status: HeadsLogStatusEnum
}

export function HeadsLogStatus(props: Props) {

    return (
        <div className={'log-status log-status--' + props.status.toLowerCase()}>
            {props.status}
        </div>
    );
}
