import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import AppButton from '../../Common/Form/AppButton';

export function SubmitCustomerButton({ loading, disabled }: { loading: boolean, disabled: boolean }) {
    return (
        <div className='button-wrapper-right'>
            <AppButton
                disabled={ loading || disabled }
                type='submit'
                variant='contained'
                startIcon={
                    <FontAwesomeIcon icon={faCheckCircle} />
                }
            >
                Spara kund
            </AppButton>
        </div>
    );
}
