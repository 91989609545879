import { useState } from "react";
import { OrderByDirection, PaginatedArguments } from "src/generated/graphql";

export function usePaginationArguments(defaults?: PaginatedArguments) {
    return useState<PaginatedArguments>({
        page: 1,
        pageSize: 20,
        orderDir: OrderByDirection.Asc,
        orderBy: 'id',
        ...defaults,
    });
}
