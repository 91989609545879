import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Box, Checkbox, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DeepPartial } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { SubStore, useSubStoresQuery } from 'src/generated/graphql';
import { useSubStore } from 'src/modules/Common/context/sub-store-context';
import AppButton from 'src/modules/Common/Form/AppButton';
import AppSearchDebounce from 'src/modules/Common/Form/AppSearchDebounce';

import LoaderCircularDiscrete from '../../Common/Common/LoaderCircularDiscrete';
import { SubStoreListColumns } from '../columns/SubStorePageColumns';
import SubStoreTableMenu from './SubStoreTableMenu';

const SubStoreList = () => {
    const [subStoreList, setSubStoreList] = useState([] as SubStoreRowType[])

    const [searchTerm, setSearchterm] = useState('');

    const [filteredSubStoreList, setFilteredSubStoreList] = useState([] as SubStoreRowType[])

    const [showArchivedLists, setShowArchivedLists] = useState(false);
    const { setSubStores } = useSubStore();

    const { data, loading, refetch } = useSubStoresQuery({
        onCompleted: (data) => {
            setSubStoreList(data?.subStores?.map((subStore) => SubStoreToListType(subStore)) ?? [])
            setSubStores(data.subStores ?? [])
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        setFilteredSubStoreList(subStoreList.filter((subStore) => {
            return !searchTerm
                || subStore.name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || subStore.admin?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || subStore.butik?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || subStore.status?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
        }).filter((subStore) => {
            return showArchivedLists || subStore.status !== 'ARCHIVED';
        }));

    }, [searchTerm, subStoreList, showArchivedLists]);

    useEffect(() => {
        refetch();
    }, [refetch, data?.subStores]);

    const history = useHistory();

    const openStore = (id: number) => {
        history.push(`/sub-store/${id}`)
    }

    return (
        <Box className="store-administration-table" >
            <Typography color='textSecondary'>
                Hantera inställningar för bokningslistan
            </Typography>
            <Box marginBottom={2}>
                <Box display="inline-block" paddingRight={2}>
                    <AppButton icon={faPlus} onClick={() => history.push('/sub-store')}>
                        Ny Bokningslista
                    </AppButton>
                </Box>
                <Box display="inline-block" textAlign={'left'}>
                    <label className="subStoreListCheckbox" htmlFor='archivedCheckbox'>
                        <Checkbox id='archivedCheckbox' checked={showArchivedLists} onChange={() => setShowArchivedLists((show) => !show)} />
                        <Typography color="textSecondary">
                            Visa arkiverade bokningslistor
                        </Typography>
                    </label>
                </Box>
            </Box>
            <Box marginY={3}>
                <AppSearchDebounce
                    autoSearch
                    delay={50}
                    id="shareowners-search"
                    onSearch={setSearchterm}
                    placeholder="Sök namn / butik / admin / status"
                />
            </Box>
            {loading && <LoaderCircularDiscrete />}
            <DataTable
                className="sub-store-list-table"
                columns={[...SubStoreListColumns, {
                    name: '',
                    selector: 'actions',
                    sortable: false,
                    cell: (row: SubStoreRowType) => {
                        return (<SubStoreTableMenu subStore={row} openStore={openStore} refetch={refetch} />)
                    }
                },]}
                data={filteredSubStoreList}
                pagination
                striped

            />
        </Box>
    );
};

type SubStoreRowType = {
    id: number;
    name: string;
    butik: string;
    admin: string;
    status: string;
};

function SubStoreToListType(subStore: DeepPartial<SubStore>): SubStoreRowType {
    return {
        id: subStore?.id ?? 0,
        name: subStore?.name ?? '',
        butik: subStore.store?.name ?? '',
        admin: subStore?.adminUser?.name ?? '',
        status: subStore?.status ?? '',
    };
}

export default SubStoreList;
