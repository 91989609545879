import TextField from '@material-ui/core/TextField';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import React from 'react';

export default function AppTextInput(props: TextFieldProps) {
    return <TextField
        InputLabelProps={{
            shrink: true,
        }}
        variant='outlined'
        type='text'
        fullWidth
        {...props}
    />;
}
