import styled from '@emotion/styled';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import debounce from '@material-ui/core/utils/debounce';
import { css } from 'emotion';
import React, { useMemo, useState } from 'react';

import AppTableButton from '../../../Common/Common/AppTableButton';


const amountStyle = css`
    text-align: center;
    width: 50px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;

    > :not(:last-child) {
        margin-right: 5px;
    }
`;

export default function AmountInput({ amount, onChange, disabled }) {
    const [value, setValue] = useState(amount);

    const _onChange = useMemo(
        () => debounce((nextValue) => {
            onChange(nextValue);
        }, 300),
        [onChange],
    );

    const update = (newValue) => {
        // Always return a number
        const v = newValue === '' || isNaN(+newValue)
            ? 1
            : +newValue;
        _onChange(v);
        setValue(v);
    }

    if (disabled) {
        return value;
    }

    return (
        <Container>
            <AppTableButton
                onClick={() => update(+value - 1)}
                size='small'
                icon={faMinusCircle}
            >
                Minska antal
            </AppTableButton>
            <input
                className={amountStyle}
                value={value}
                onChange={(e) => update(e.target.value)}
            />
            <AppTableButton
                onClick={() => update(+value + 1)}
                size='small'
                icon={faPlusCircle}
            >
                Öka antal
            </AppTableButton>
        </Container>
    );
}
