/* eslint-disable react/display-name */
import styled from '@emotion/styled';
import React from 'react';
import DataTable from 'react-data-table-component';

import { tableStyles } from '../../../../../Common/styles/tableStyles';
import InspectionProtocolHeader from './utils/InspectionProtocolHeader';
import { statusLabel } from './utils/utils';

const StyledDataTable = styled(DataTable)`
    & .rdt_TableRow {
        align-items: flex-start;
    }
`;

const Wrapper = styled.div``;

const cellStyle = {
    margin: '10px 0',
};

const columns = [
    {
        name: 'Punkt',
        selector: 'number',
        grow: 0,
        sortable: true,
        style: cellStyle,
    },
    {
        name: 'Kommentar',
        selector: 'comment',
        sortable: true,
        grow: 2,
        wrap: true,
        style: cellStyle,
    },
    {
        name: 'Status',
        selector: 'status',
        cell: (row) => row.status,
        grow: 1,
        style: cellStyle,
    },
];

const ComponentComments = ({
    section,
    grades,
}) => {
    if (!Array.isArray(grades)) {
        return null; // if empty then it is an object.
    }

    let data = [];

    section.forEach((section) => {
        section.component.forEach((row) => {
            const grade = grades.find((grade) => grade.component.id === row.id);
            if (grade && grade.comment) {
                console.log('Grade: ', statusLabel(grade.status))
                data.push({
                    number: section.number + '.' + row.number,
                    comment: grade.comment,
                    status: grade.status ? statusLabel(grade.status) : '-',
                });
            }
        });
    });

    return (
        <Wrapper>
            {Boolean(data.length) && (
                <div>
                    <InspectionProtocolHeader>Kommentarer</InspectionProtocolHeader>
                    <div className={tableStyles}>
                        <StyledDataTable
                            noHeader
                            columns={columns}
                            data={data}
                            defaultSortField='number'
                            striped
                        />
                    </div>
                </div>
            )}
        </Wrapper>
    );
};

export default ComponentComments;
