import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import AppTextInput from 'src/modules/Common/Form/AppTextInput';

export default function AppDateInput(props: any) {
    const { control, name, label, size, ...rest } = props;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const CustomInput = forwardRef((props, ref) => {
        const { onClick, value, onChange } = props as any;
        return (
            <AppTextInput
                onClick={onClick}
                label={label}
                value={value}
                size={size}
                onChange={(onChange)}
            />
        );
    });

    return <Controller
        name={name}
        control={control}
        render={({ onChange, value }) => (
            <ReactDatePicker
                locale="sv"
                selected={value}
                onChange={onChange}
                dateFormat='yyyy-MM-dd'
                customInput={<CustomInput />}
                {...rest}
            />
        )}
    />;
}
