import { useSnackbar } from 'notistack';

export const toast = {}

export function Toaster() {

    const { enqueueSnackbar } = useSnackbar();

    toast.error = (message) => {
        enqueueSnackbar(message, { variant: 'error' });
    };

    toast.success = (message) => {
        enqueueSnackbar(message, { variant: 'success' });
    };

    toast.default = (message) => {
        enqueueSnackbar(message, { variant: 'default' });
    };

    toast.info = (message) => {
        enqueueSnackbar(message, { variant: 'info' });
    };

    toast.warning = (message) => {
        enqueueSnackbar(message, { variant: 'warning' });
    };

    return null;
}
