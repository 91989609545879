import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { SubStoreStatus, useActivateSubStoreMutation, useArchiveSubStoreMutation } from 'src/generated/graphql';
import { useConfirmDialog } from 'src/modules/Common/Common/ConfirmDialog';

interface Props {
    subStore: { id: number, status: string };
    openStore: any;
    refetch: () => void;
}

export default function SubStoreTableMenu({ subStore, openStore }: Props) {
    const [openRowMenu, setOpenRowMenu] = useState(false);
    const [archiveSubStore] = useArchiveSubStoreMutation({
        refetchQueries: ['SubStores'],
        onCompleted: () => {
            enqueueSnackbar(`Lista arkiverad!`, { variant: 'success' });
        }
    });
    const [activateSubStore] = useActivateSubStoreMutation({
        refetchQueries: ['SubStores'],
        onCompleted: () => {
            enqueueSnackbar(`Lista aktiverad!`, { variant: 'success' });
        }
    });
    const anchorEl = React.useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const archive = async () => {
        await archiveSubStore({
            variables: {
                id: subStore.id
            }
        });
    }
    const activate = async () => {
        await activateSubStore({
            variables: {
                id: subStore.id
            }
        });
    }

    const confirmDialog = useConfirmDialog();

    const showArchiveListDialog = () => {
        setOpenRowMenu(false)
        confirmDialog({
            content: (
                <>
                    Är du säker på att du vill arkivera listan?
                </>
            ),
            buttons: [
                { label: 'Avbryt', variant: 'outlined' },
                { label: 'Arkivera', action: () => archive() },
            ],
        });
    };
    const showActivateListDialog = () => {
        setOpenRowMenu(false)
        confirmDialog({
            content: (
                <>
                    Är du säker på att du vill aktivera listan?
                </>
            ),
            buttons: [
                { label: 'Avbryt', variant: 'outlined' },
                { label: 'Aktivera', action: () => activate() },
            ],
        });
    };

    return (
        <Box display="inline-block" marginLeft="auto" marginRight="2rem" component="div">
            <Button ref={anchorEl} onClick={() => setOpenRowMenu(true)}>
                <FontAwesomeIcon icon={faEllipsisV} size='lg' />
            </Button>
            <Menu open={openRowMenu} anchorEl={anchorEl.current} onBackdropClick={() => setOpenRowMenu(false)}>
                <MenuItem onClick={() => openStore(subStore.id)}>Öppna</MenuItem>
                {subStore.status == SubStoreStatus.Active
                    ? <MenuItem onClick={() => showArchiveListDialog()}>Arkivera</MenuItem>
                    : <MenuItem onClick={() => showActivateListDialog()}>Aktivera</MenuItem>}
            </Menu>
        </Box>
    );
}
