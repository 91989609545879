import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';

const TableSort = ({ sortState, title, property, startDirection = sortState.defaultDirection }) => {
    const onClick = (property) => {
        const isSelected = sortState.property === property;
        if (isSelected) {
            sortState.flip();
        } else {
            sortState.set(property, startDirection);
        }
    };

    return (
        <TableSortLabel
            active={sortState.property === property}
            direction={sortState.property === property ? sortState.direction : startDirection}
            onClick={() => onClick(property)}>
            {title}
        </TableSortLabel>
    );
};

export default TableSort;
