
const transformMaxCost = (value: string) =>
    value ? parseInt(value.replace(/[^0-9]/gi, '')) : 0;

// TODO: Type formValues from serviceOrderForm
export const transformToCreateServiceOrderInput = (
    formValues: any,
    loggedInUser: any, // TODO: Type loggedInUser as CognitoUser from AWS Amplify, can't find the right type in aws cognito
    storeId: number,
    subStoreId: number | undefined
) => {
    const {
        name,
        bikeType,
        customerBikeId,
        customerId,
        companyId,
        serviceByUserId,
        maxCost,
        description,
        internalComment,
        customerComment,
        serviceDate,
        receivedBy,
        invoiceLabel,
        invoiceRef,
        useAlternativeContact,
        alternativeContactEmail,
        alternativeContactPhone,
        selectedShippingAddressId,
        subStoreId: formSubStoreId,
    } = formValues;

    if (!storeId) {
        throw new Error('Store not defined');
    }
    
    return {
        name,
        status: null,
        description,
        internalComment,
        customerComment,
        customerId: parseInt(customerId),
        companyId: parseInt(companyId) ?? undefined,
        maxCost: transformMaxCost(maxCost),
        serviceByUserId,
        createdByUserId: loggedInUser.attributes.sub,
        serviceDate,
        storeId,
        bikeType,
        customerBike: customerBikeId ? { id: parseInt(customerBikeId) } : null,
        receivedBy,
        invoiceLabel,
        invoiceRef,
        subStoreId: (formSubStoreId === 999999 ? undefined : formSubStoreId) || subStoreId,
        useAlternativeContact: Boolean(useAlternativeContact),
        alternativeContactEmail: alternativeContactEmail ? alternativeContactEmail : null,
        alternativeContactPhone: alternativeContactPhone ? alternativeContactPhone : null,
        selectedShippingAddressId,
    };
};

// TODO: Type formValues from serviceOrderForm
export const transformToUpdateServiceOrderInput = (formData: any) => {
    const {
        name,
        bikeType,
        customerBikeId,
        customerId,
        companyId,
        serviceByUserId,
        maxCost,
        description,
        internalComment,
        customerComment,
        serviceDate,
        receivedBy,
        invoiceLabel,
        invoiceRef,
        useAlternativeContact,
        alternativeContactEmail,
        alternativeContactPhone,
        selectedShippingAddressId,
        subStoreId,
    } = formData;

    return {
        name,
        description,
        internalComment,
        customerComment,
        customerId: parseInt(customerId),
        companyId: parseInt(companyId) ?? undefined,
        serviceByUserId: serviceByUserId,
        maxCost: transformMaxCost(maxCost),
        serviceDate,
        bikeType,
        customerBike: customerBikeId ? { id: parseInt(customerBikeId) } : null,
        receivedBy,
        invoiceLabel,
        invoiceRef,
        useAlternativeContact: Boolean(useAlternativeContact),
        alternativeContactEmail: alternativeContactEmail ? alternativeContactEmail : null,
        alternativeContactPhone: alternativeContactPhone ? alternativeContactPhone : null,
        selectedShippingAddressId,
        subStoreId: subStoreId,
    };
};
