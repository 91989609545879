import { Box, BoxProps } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

import { BootstrapInput, MiniSelect, MiniSelectLabel } from '../../Common/Common/MiniSelect';
import { useSubStore } from '../../Common/context/sub-store-context';

function SubStoreTopMenu({ ...props }: BoxProps) {
    const { activeSubStores ,selectedSubStoreId, setSelectedSubStoreId } = useSubStore();

    const onSelect = (id: number) => {
        setSelectedSubStoreId(id);
    };

    return (
        <Box {...props}>
            <MiniSelectLabel>Bokningslista:</MiniSelectLabel>&nbsp;
            <MiniSelect
                defaultValue={selectedSubStoreId ?? 0}
                value={selectedSubStoreId}
                onChange={(e) => onSelect(Number(e.target.value))}
                input={<BootstrapInput />}
            >
                <MenuItem
                    key={0}
                    value={0}
                >Huvudlista</MenuItem>
                {activeSubStores.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
                {activeSubStores.length === 0 && <MenuItem disabled>Inga aktiva listor</MenuItem>}
            </MiniSelect>
        </Box>
    );
}

export default SubStoreTopMenu;
