/* eslint-disable react/display-name */
import { format } from 'date-fns';
import gql from 'graphql-tag';
import React from 'react';

import DataList from '../../../Common/DataList/DataList';
import { orderOriginText, orderStatusText } from '../../utils/serviceOrderFields';
import ServiceOrderDialog from '../Dialogs/ServiceOrderDialog';
import FilterForm from './Filter/FilterForm';

export default class AllOrders extends DataList {
    constructor(params) {
        super(params);

        // Change names of columns accordingly to what to display
        this.columns = [
            {
                name: 'Ordernummer',
                selector: 'orderNumber',
                grow: 1,
                sortable: true,
            },
            {
                name: 'Cykeltyp',
                selector: 'bikeType',
                cell: (row) => row.bikeType || '-',
                sortable: true,
            },
            {
                name: 'Kundens namn',
                selector: 'customer.name',
                cell: (row) => row.customer?.companyName || row.customer?.name || '-',
                grow: 2,
                sortable: false,
            },
            {
                name: 'Beskrivning',
                selector: 'description',
                grow: 3,
                sortable: true,
            },
            {
                name: 'Mekaniker',
                selector: 'serviceByName',
                grow: 2,
                sortable: true,
            },
            {
                name: 'Status',
                selector: 'status',
                grow: 2,
                cell: (row) => orderStatusText(row.status),
                sortable: true,
            },
            {
                name: 'Butik',
                selector: 'store',
                cell: (row) => row.store?.name || '-',
                grow: 2,
                sortable: false,
            },
            {
                name: 'Källa',
                selector: 'origin',
                cell: (row) => orderOriginText(row.origin),
                grow: 1,
                sortable: true,
            },
            {
                name: 'Planerat datum',
                cell: (row) => format(new Date(row.serviceDate), 'yyyy-MM-dd'),
                selector: 'serviceDate',
                grow: 2,
                sortable: true,
            },
        ];

        this.onRowClicked = (row) => {
            this.props.createDialog((ref) => (
                <ServiceOrderDialog serviceOrderId={row.id} dialogRef={ref} />
            ));
        }

        this.options = {
            initialPagination: {
                orderBy: 'serviceDate',
            },
        };

        this.customStyles = {
            rows: {
                style: { cursor: 'pointer' },
            },
        };

        this.baseFilters.push({
            field: 'storeId',
            whereOperator: 'IN',
            arrayIntValue: [params.storeId],
        });

        this.initialFilter = {
            where: [
                {
                    field: 'serviceDate',
                    operator: '>=',
                    stringValue: format(new Date(), 'yyyy-MM-dd'),
                }
            ],
        }

        // Change query accordingly to where we fetch the orders
        this.query = gql`
            query(
                $filter: FilterArgument
                $page: Int
                $pageSize: Int
                $orderBy: String
                $orderDir: OrderByDirection
            ) {
                orders(
                    filter: $filter
                    page: $page
                    pageSize: $pageSize
                    orderBy: $orderBy
                    orderDir: $orderDir
                ) {
                    total
                    items {
                        id
                        orderNumber
                        description
                        serviceByName
                        customer {
                            id
                            name
                            firstName
                            lastName
                            companyName
                            companyId
                            orgNumber
                        }
                        store {
                            name
                        }
                        origin
                        serviceDate
                        status
                    }
                }
            }
        `;
        this.queryKey = 'orders';
    }
    subHeaderComponent() {
        return (
            <>
                <FilterForm filterList={this.filterList} />
            </>
        );
    }
}
