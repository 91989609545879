import styled from '@emotion/styled';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { useGetCustomerByIdLazyQuery } from '../../../generated/graphql';
import LoaderCircular from '../../Common/Common/LoaderCircular';
import { useDialog2 } from '../../Common/custom-hooks/useDialog2';
import { useStyles } from '../../Common/styles/inputStyles';
import { tableStyles } from '../../Common/styles/tableStyles';
import CreateBikeDialog from './CreateBikeDialog';

const Wrapper = styled.div`
    position: relative;
`;

const useComponentStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(3),
    },
    dataTableRow: {
        overflow: 'hidden',
    },
    buttonContainer: {
        marginTop: '1rem',
    },
}));

const columns = [
    {
        name: 'Ramnr',
        selector: 'frameNumber',
        width: '100px',
        sortable: true,
    },
    {
        name: 'Fabrikat',
        selector: 'manufacturer',
        sortable: true,
    },
    {
        name: 'Beskrivning',
        selector: 'description',
        grow: 2,
        sortable: true,
    },
    {
        name: 'Inköpsdatum',
        selector: 'purchasedAt',
        cell: (row) => format(new Date(row.purchasedAt), 'yyyy-MM-dd'),
        sortable: true,
    },
    {
        name: 'Artnr',
        selector: 'sku',
        sortable: true,
    },
    {
        name: 'Årsmodell',
        selector: 'year',
        width: '100px',
        sortable: true,
    },
];

const CreateBike = ({ customerId, onCloseCreateBike }) => {
    const classes = useStyles();
    const { createDialog } = useDialog2();
    // @todo Should probably handle company bikes here too.
    const showCreateBike = () => {
        const ref = createDialog((ref) => (
            <CreateBikeDialog customerId={customerId} dialogRef={ref} />
        ));
        ref.onClose.push(onCloseCreateBike);
    };

    return (
        <Button
            variant='contained'
            className={classes.buttonStyles}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={showCreateBike}
        >
            Lägg till cykel
        </Button>
    );
};

const BikesTable = ({ bikes, customerId }) => {
    const [customerBikes, setCustomerBikes] = useState([]);
    const [refetchCustomer, { loading: fetchingCustomer }] = useGetCustomerByIdLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: (data) => setCustomerBikes(data.customer.bikes),
    });

    useEffect(() => {
        if (bikes && Boolean(bikes.length)) {
            setCustomerBikes(bikes);
        }
    }, [bikes]);

    const componentClasses = useComponentStyles();

    const onCloseCreateBike = () => {
        refetchCustomer({ variables: { id: customerId } });
    };

    return (
        <Wrapper>
            <Grid
                container
                className={componentClasses.formContainer}
                direction='column'
            >
                <Grid item className={componentClasses.dataTableRow} md={12}>
                    <div className={tableStyles}>
                        <LoaderCircular visible={fetchingCustomer} />
                        <DataTable
                            noHeader
                            columns={columns}
                            data={customerBikes}
                            defaultSortField='frameNumber'
                            striped
                        />
                    </div>
                </Grid>
                <Grid
                    container
                    className={componentClasses.buttonContainer}
                    justify='flex-end'
                >
                    <Grid item>
                        <CreateBike
                            customerId={customerId}
                            onCloseCreateBike={onCloseCreateBike}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default BikesTable;
