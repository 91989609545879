import { Customer } from "src/generated/graphql";

type getCompanyNameFromCustomerInputType = {
    companyName?: string;
    company?: {
        companyName?: string;
    }
};

export function isEmail(email: string): string | true {
    if(!email) {
        return true;
    }
    const re = /\S+@\S+\.\S+/;
    if(re.test(email)){
        return true;
    }
    return "Ogiltig e-postadress";
}

export function isCompanyCustomer(customer: Partial<Customer>): boolean {
    return Boolean(customer?.company);
}

export function getCompanyNameFromCustomer(customer: getCompanyNameFromCustomerInputType): string {
    return customer?.companyName || customer?.company?.companyName || '';
}

export function validateOrgNum(orgNumStr: string): string | true {
    const hyphenPosition = orgNumStr.indexOf('-');

    if (hyphenPosition !== -1 && hyphenPosition !== 6) {
        return 'Bindestreck (-) är på fel position. Rätt format: 000000-0000';
    }

    const digits = orgNumStr.replace('-', '');

    if (/\D/.test(digits)) {
        return 'Ogiltiga tecken, organisationsnummer får endast innehålla siffror.';
    }

    if (digits.length !== 10) {
        return 'Felaktigt antal siffror, organisationsnummer måste innehålla exakt 10 siffror.';
    }

    if (!validateLuhn(digits)) {
        return 'Ogiltig kontrollsiffra, ange ett korrekt organisationsnummer.';
    }

    return true;
}

function validateLuhn(number: string): boolean {
    const digits = number.split('').reverse().map(Number);
    const sum = digits.reduce((acc, digit, idx) => {
        if (idx % 2 === 0) {
            return acc + digit;
        } else {
            const doubled = digit * 2;
            return acc + (doubled > 9 ? doubled - 9 : doubled);
        }
    }, 0);
  
    return sum % 10 === 0;
}

export function convertCustomerToCompanyInput(customer: Customer) {
    const company = customer?.company;
    if (!company) {
        return undefined;
    }

    company.__typename = undefined;
    company.billingAddress.__typename = undefined;
    if (company.shippingAddress) {
        company.shippingAddress = company.shippingAddress.map((address: any) => {
            delete address.__typename;
            return address
        });
    }
    if (company.visitingAddress) {
        company.visitingAddress.__typename = undefined;
    }

    return { ...company, contacts: [
        {
            id: customer.id,
            email: customer.email,
            name: customer.name,
            phoneNumber: customer.cellPhoneNumber,
            // The Storm API magically returns the cellPhoneNumber in the phoneNumber field.
            // This is a bug.
            // Not sure if it's connected to saving Companies or if it does that in general for Customers.
        },
    ] };
}
