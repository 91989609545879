import styled from '@emotion/styled';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Store } from '../../generated/graphql';
import { PrinterSelector } from '../Layout/Printer/PrinterSelector';
import SignOutButton from '../Layout/SignOutButton/SignOutButton';
import StoreSelector from '../Layout/StoreSelector/StoreSelector';
import SubStoreTopMenu from '../Layout/SubStore/SubStoreTopMenu';

const User = () => {
    const [value, setValue] = useState(() => '');

    useEffect(() => {
        const getAuthenticatedUser = async () => {
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            setValue(authenticatedUser.attributes.email);
        };

        getAuthenticatedUser();
    }, []);

    return <div style={{ display: 'inline-block' }}>{value}</div>;
};

User.propTypes = {
    then: PropTypes.any,
};

const Divider = styled.div`
    width: 1px;
    height: 1.2em;
    display: inline-flex;
    background-color: #fff;
    vertical-align: middle;
    margin: 0 10px;
`;

function TopBar({ stores }: { stores: Store[] }) {
    return (
        <div className='top-bar'>
            <PrinterSelector className='top-bar-select' />
            <StoreSelector className='top-bar-select' stores={stores} />
            <SubStoreTopMenu className='top-bar-select' />
            <Divider />
            Inloggad som:&nbsp;
            <User />
            <SignOutButton />
        </div>
    );
}

export default TopBar;
