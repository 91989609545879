import { useLazyQuery } from '@apollo/react-hooks';
import React from 'react';

import { useDialog3 } from '../../../../../Common/custom-hooks/useDialog3';
import EditCustomerDialog from '../../../../../Customer/CustomerDialogs/EditCustomerDialog';
import { SEARCH_CUSTOMER } from '../../../../../SearchCustomer/graphql/queries';
import { QuickSearch } from './QuickSearch';

const columns = [
    {
        name: 'ID',
        selector: 'id',
        sortable: true,
        grow: 2,
    },
    {
        name: 'Cykeltyp',
        selector: 'bikeType',
        cell: (row) => row.bikeType || '-',
        sortable: true,
    },
    {
        name: 'Kundens namn',
        selector: 'name',
        cell: (row) => row.name || '-',
        sortable: false,
        grow: 3,
    },
    {
        name: 'Personnummer',
        selector: 'socialSecurityNumber',
        cell: (row) => row.socialSecurityNumber || '-',
        sortable: true,
        grow: 3,
    },
    {
        name: 'E-post',
        selector: 'email',
        cell: (row) => row.email || '-',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Tel.',
        selector: 'cellPhoneNumber',
        cell: (row) => row.cellPhoneNumber || row.phoneNumber || '-',
        width: '140px',
        sortable: true,
        grow: 3,
    },
];

export function CustomerSearchPanel() {
    const { createDialog } = useDialog3();

    const [getCustomers, { data, loading, error, called }] = useLazyQuery(
        SEARCH_CUSTOMER,
        {
            fetchPolicy: 'network-only',
        }
    );

    const onSearch = (query) => {
        if (query && query.length > 0) {
            getCustomers({ variables: { query } });
        }
    };

    const onSelect = (customerId) => {
        createDialog((ref) => (
            <EditCustomerDialog customerId={customerId} dialogRef={ref} />
        ));
    };

    return (
        <QuickSearch
            error={error}
            id='customer-search'
            label='Sök'
            placeholder='Personnummer, telefonnummer eller kundens namn'
            onSearch={onSearch}
            loading={loading}
            columns={columns}
            items={data?.searchForCustomers?.items}
            onSelect={onSelect}
            called={called}
            noResultText={'Inga kunder hittades'}
        />
    );
}
