import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';

const AppTableButton = ({ icon, ...rest }) => {
    return (
        <IconButton color='primary' size='medium' {...rest}>
            <FontAwesomeIcon icon={icon} style={{ fontSize: 15 }} />
        </IconButton>
    );
};

export default AppTableButton;
