import {
    // faBarcode,
    faBinoculars,
    faBolt,
    faPlus,
    //faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Action, Fab } from 'react-tiny-fab';

import { useDialog2 } from '../../../Common/custom-hooks/useDialog2';
import CreateServiceOrderDialog from '../../../ServiceOrder/components/Dialogs/CreateServiceOrderDialog';
import ServiceOrderDialog from '../../../ServiceOrder/components/Dialogs/ServiceOrderDialog';
import { QuickSearchDialog } from '../../QuickSearch/components/Dialog/QuickSearchDialog';

export const position = {
    bottom: 0,
    right: 0,
};
export const mainButtonStyles = {
    backgroundColor: '#1b1b1b',
};
export const actionStyle = {
    backgroundColor: '#f8ca46',
    border: '3px solid #ffffff',
    color: '#242a42',
};

const ActionsMenu = () => {
    const { createDialog } = useDialog2();

    const showServiceOrderForm = () => {
        const ref = createDialog((ref) => (
            <CreateServiceOrderDialog dialogRef={ref} />
        ));
        ref.onClose.push((data) => {
            if (data) {
                createDialog((ref) => (
                    <ServiceOrderDialog
                        serviceOrderId={data.id}
                        dialogRef={ref}
                    />
                ));
            }
        });
    };

    // const showCustomer = () => {
    //     createDialog((ref) => <CreateCustomerDialog dialogRef={ref} />);
    // };

    // TODO: Implement barcode scan -> https://netconsult.atlassian.net/browse/SPORT-226
    // const scanBarcode = () => {
    //     console.log('Not yet implemented');
    // };

    const quickSearch = () => {
        createDialog((ref) => <QuickSearchDialog dialogRef={ref} />);
    };

    return (
        <Fab
            position={position}
            event='click'
            mainButtonStyles={mainButtonStyles}
            icon={<FontAwesomeIcon icon={faPlus} size='1x' />}
        >
            {/* <Action
                text='Scanna streckkod'
                style={actionStyle}
                onClick={scanBarcode}
            >
                <FontAwesomeIcon icon={faBarcode} size='sm' />
            </Action> */}
            <Action
                text='Snabbsök'
                style={actionStyle}
                onClick={quickSearch}
                data-testid='main-quick-search-action'
            >
                <FontAwesomeIcon icon={faBinoculars} size='sm' />
            </Action>
            <Action
                data-testid='main-create-order-action'
                text='Skapa order'
                style={actionStyle}
                onClick={showServiceOrderForm}
            >
                <FontAwesomeIcon icon={faBolt} size='sm' />
            </Action>
            {/*
            <Action
                text='Skapa kund'
                style={actionStyle}
                onClick={showCustomer}
            >
                <FontAwesomeIcon icon={faUser} size='sm' />
            </Action>
            */}
        </Fab>
    );
};

export default ActionsMenu;
