import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Customer } from 'src/generated/graphql';

import AppSelect from '../../../Common/Form/AppSelect';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    inputWrapper: {
        cursor: 'pointer',
        flexGrow: 1,
    },
    openDialog: {
        position: 'relative',
        left: '5px',
    },
    disabledOverlay: {
        position: 'absolute',
        background: 'rgba(255,255,255,0.6)',
        top: -5,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

export default function ActiveDeliveryAddressInput({
    customer,
    onChange,
    control,
    disabled,
} : {
    customer: Customer,
    onChange: () => void,
    disabled?: true,
    control: ReturnType<typeof useFormContext>['control'],
}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppSelect
                label='Aktiv leveransaddress'
                defaultValue={customer.company?.shippingAddress?.[0]?.id || null}
                disabled={disabled}
                control={control}
                handleChange={onChange}
                name='selectedShippingAddressId'
            >
                {customer.company?.shippingAddress?.map((address) => (
                    <MenuItem key={address.id} value={address.id!}>
                        {address.unit} {address.street}
                    </MenuItem>
                ))}
            </AppSelect>
        </div>
    );
}
