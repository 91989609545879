/* eslint-disable react/display-name */
import '../CustomerList.scss';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import gql from 'graphql-tag';
import React from 'react';

import AppTableButton from '../../../Common/Common/AppTableButton';
import DataList from '../../../Common/DataList/DataList';
import EditCustomerDialog from '../../CustomerDialogs/EditCustomerDialog';

export default class CustomerList extends DataList {
    state = {
        page: 1,
        pageSize: 20,
        customers: [],
        totalCount: 0,
        orderBy: 'lastName',
        orderDir: 'ASC',
    };

    constructor(params) {
        super(params);

        const { createDialog } = this.props;

        const showCustomerFormDialog = (customerId) => {
            const ref = createDialog((ref) => (
                <EditCustomerDialog customerId={customerId} dialogRef={ref} />
            ));

            ref.onClose.push(this.refetch);
        };

        this.columns = [
            {
                name: 'Kundnummer',
                selector: 'id',
                sortable: true,
            },
            {
                name: 'Efternamn',
                selector: 'lastName',
                sortable: true,
            },
            {
                name: 'Förnamn',
                selector: 'firstName',
                sortable: true,
            },
            {
                name: 'E-post',
                selector: 'email',
                sortable: false,
            },
            {
                name: 'Telefon',
                selector: 'cellPhoneNumber',
                sortable: false,
            },
            {
                name: 'Företagsnamn',
                selector: (row) => {
                    return row.company?.companyName || '';
                },
                sortable: true,
            },
            {
                name: '',
                button: true,
                cell: (row) => (
                    <AppTableButton
                        icon={faEdit}
                        onClick={() => showCustomerFormDialog(parseInt(row.id))}
                    />
                ),
            },
        ];

        this.onRowClicked = (row) => showCustomerFormDialog(parseInt(row.id));

        this.options = {
            initialPagination: {
                orderBy: 'lastName',
            },
        };

        this.customStyles = {
            rows: {
                style: { cursor: 'pointer' },
            },
        };

        this.query = gql`
            query(
                $page: Int
                $pageSize: Int
                $orderBy: String
                $orderDir: OrderByDirection
            ) {
                customers(
                    page: $page
                    pageSize: $pageSize
                    orderBy: $orderBy
                    orderDir: $orderDir
                ) {
                    total
                    items {
                        id
                        firstName
                        lastName
                        email
                        cellPhoneNumber
                        company {
                            companyName
                        }
                    }
                }
            }
        `;
        this.queryKey = 'customers';
    }
}
