import Box from '@material-ui/core/Box';
import React from 'react';
import { SmsStatisticsTable } from 'src/modules/SmsOvererview/SmsStatisticsTable/SmsStatisticsTable';

export const SmsOverviewPage = () => {

    return (
        <div>
            <h1>Skickade SMS</h1>
            <div className="box">
                <Box p={2} pt={0}>
                    <SmsStatisticsTable />
                </Box>
            </div>
        </div>
    );
};
