type env = 'master' | 'develop' | undefined;

const branchName = process.env.REACT_APP_RELEASE_NAME?.split('-')[0] as env;

const getKey = (branchName: env) => {
    switch (branchName) {
        case 'master':
            return '62949079e2353d0d1d29a4b7';
        case 'develop':
            return '62949079e2353d0d1d29a4b6';
        default:
            return '6298cc582011b60c1f7bb747';
    }
}

export default {
    apiKey: getKey(branchName),
};
