import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';

import { useDialog3 } from '../../../Common/custom-hooks/useDialog3';
import { OpenEntityDialogButton } from '../../../Common/Form/Buttons';
import TextInput from '../../../Common/Form/TextInput';
import CreateCustomerDialog from '../../../Customer/CustomerDialogs/CreateCustomerDialog';
import EditCustomerDialog from '../../../Customer/CustomerDialogs/EditCustomerDialog';
import { getCompanyNameFromCustomer } from '../../../Customer/helpers/company.helper';
import SearchCustomerDialog from '../../../SearchCustomer/components/SearchCustomerDialog';

const StyledTextInput = withStyles({
    root: {
        verticalAlign: 'inherit',
    },
})(TextInput);

export const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    inputWrapper: {
        position: 'relative',
        flexGrow: 1,
    },
    openDialog: {
        position: 'relative',
        left: '5px',
    },
    overlay: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    disabledOverlay: {
        position: 'absolute',
        background: 'rgba(255,255,255,0.6)',
        top: -5,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

const CustomerDialogButton = ({ selectCustomerOnClose, entityId }) => {
    const { createDialog } = useDialog3();
    const showCustomer = () => {
        const ref = createDialog((ref) =>
            entityId ? (
                <EditCustomerDialog customerId={entityId} dialogRef={ref} />
            ) : (
                <CreateCustomerDialog customerId={entityId} dialogRef={ref} />
            ),
        );

        ref.onClose.push(selectCustomerOnClose);
    };

    return <OpenEntityDialogButton entityId={entityId} onDialogOpen={showCustomer} />;
};

const CustomerTextInputWithSearch = ({ name, selectCustomer }) => {
    const { createDialog } = useDialog3();
    const classes = useStyles();
    const openSearchCustomer = async () => {
        const ref = createDialog((ref) => <SearchCustomerDialog dialogRef={ref} />);
        ref.onClose.push(selectCustomer);
    };

    return (
        <div className={classes.inputWrapper}>
            <StyledTextInput
                inputAdornments={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <FontAwesomeIcon icon={faSearch} size="sm" />
                        </InputAdornment>
                    ),
                }}
                id="customerInput"
                label="Kund"
                name="customerDisplayName"
                validation={{ required: 'Detta fält är obligatoriskt' }}
                value={name}
            />
            <div className={classes.overlay} onClick={openSearchCustomer} role="presentation" />
        </div>
    );
};

export default function CustomerInput({ customer, onChange, register, disabled = false }) {
    const classes = useStyles();

    // @todo This only takes the customer ID, make sure it can handle customers with company too.
    const selectCustomer = (newCustomer) => {
        if (newCustomer?.id) {
            customer = newCustomer;
        }

        onChange(customer); // always trigger on close in case bike list has changed
        document.getElementById('subStoreId').focus(); // focus on next form-field for accessibility
    };

    return (
        <div className={classes.root}>
            <input type="hidden" name="customerId" value={customer?.id || ''} ref={register} />
            <input
                type="hidden"
                name="companyId"
                value={customer?.companyId || customer?.company?.id || ''}
                ref={register}
            />
            <CustomerTextInputWithSearch
                register={register}
                selectCustomer={selectCustomer}
                name={getCompanyNameFromCustomer(customer) || customer?.name || ''}
            />
            <div className={classes.openDialog}>
                <CustomerDialogButton entityId={customer?.id} selectCustomerOnClose={selectCustomer} />
            </div>
            <div>{disabled && <div className={classes.disabledOverlay} />}</div>
        </div>
    );
}
