import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

import AppButton from '../../../../../Common/Form/AppButton';
import { AppMenu } from './AppMenu';

export const StatusButtons = ({ serviceOrder, onChange, disabled }) => {
    const buttons = [];
    const isStatus = (statuses = []) => statuses.includes(serviceOrder.status);

    if (isStatus([
        'new',
        'not_received_parts_awaiting',
    ])) {
        buttons.push((
            <AppButton
                key={'received'}
                disabled={disabled}
                onClick={() => onChange('received')}
                startIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
            >
                Mottagen
            </AppButton>
        ));
    }

    if (isStatus([
        'new',
    ])) {
        buttons.push((
            <AppButton
                key={'not_received_parts_awaiting'}
                disabled={disabled}
                onClick={() => onChange('not_received_parts_awaiting')}
                startIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
            >
                Väntar på delar - men ej inkommen
            </AppButton>
        ));
    }

    if (isStatus([
        'received',
        'customer_response_received',
        'supplier_response_received',
        'parts_received',
    ])) {
        buttons.push((
            <AppButton
                key={'processing'}
                disabled={disabled}
                onClick={() => onChange('processing')}
                startIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
            >
                Arbete pågår
            </AppButton>
        ));
    }

    if (isStatus([
        'processing',
    ])) {
        buttons.push((
            <AppButton
                key={'done'}
                disabled={disabled}
                onClick={() => onChange('done')}
                startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            >
                Klarmarkera
            </AppButton>
        ));
    }

    if (isStatus([
        'done',
    ])) {
        buttons.push((
            <AppButton
                key={'closed'}
                disabled={disabled || serviceOrder.customer === null}
                onClick={() => onChange('closed')}
                startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            >
                Skicka till kassa
            </AppButton>
        ));
    }

    // Extra statuses - Contact customer

    if (isStatus([
        'supplier_response_awaiting',
    ])) {
        buttons.push((
            <AppButton
                key={'supplier_response_received'}
                disabled={disabled}
                onClick={() => onChange('supplier_response_received')}
                startIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
            >
                Mottaget svar från leverantör
            </AppButton>
        ));
    }

    if (isStatus([
        'customer_response_awaiting',
    ])) {
        buttons.push((
            <AppButton
                key={'customer_response_received'}
                disabled={disabled}
                onClick={() => onChange('customer_response_received')}
                startIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
            >
                Mottaget svar från kund
            </AppButton>
        ));
    }

    // Extra statuses - Order parts

    if (isStatus([
        'processing',
    ])) {
        buttons.push((
            <AppButton
                key={'parts_awaiting'}
                disabled={disabled}
                onClick={() => onChange('parts_awaiting')}
                variant='outlined'
                startIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
            >
                Delar beställda
            </AppButton>
        ));
    }

    if (isStatus([
        'parts_awaiting',
    ])) {
        buttons.push((
            <AppButton
                key={'parts_received'}
                disabled={disabled}
                onClick={() => onChange('parts_received')}
                startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            >
                Delar mottagna
            </AppButton>
        ));
    }

    if (isStatus([
        'processing',
    ])) {
        buttons.push((
            <AppMenu>
                <MenuItem
                    onClick={() => onChange('customer_response_awaiting')}
                    style={{ fontWeight: 500 }}
                >
                    <ListItemIcon style={{ color: 'black', fontSize: '18px', minWidth: '20px', marginRight: '8px' }}>
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </ListItemIcon>
                    Inväntar svar från kund
                </MenuItem>
                <MenuItem
                    onClick={() => onChange('supplier_response_awaiting')}
                    style={{ fontWeight: 500 }}
                >
                    <ListItemIcon style={{ color: 'black', fontSize: '18px', minWidth: '20px', marginRight: '8px' }}>
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </ListItemIcon>
                    Inväntar svar från leverantör
                </MenuItem>
            </AppMenu>
        ));
    }

    return buttons;
};
