import React from 'react';

import { HeadsLogTable } from './HeadsLog/HeadsLogTable';

export default function HeadsIntegrationPage() {
    return (
        <div>
            <h1>Heads-integration</h1>
            <div className="box">
                <HeadsLogTable />
            </div>
        </div>
    );
}
