import './Dashboard.scss';

import React from 'react';

import { useSubStore } from '../Common/context/sub-store-context';
import { Dashboard } from './components/Dashboard';

const DashboardPage = () => {
    const { selectedSubStore } = useSubStore();

    const headerText = 'Översikt';

    const header = selectedSubStore?.name
        ? <h1>{headerText} - Listning: <span className="lighter">{selectedSubStore.name}</span></h1>
        : <h1>{headerText}</h1>;

    return (
        <>
            {header}
            <div className='box'>
                <Dashboard />
            </div>
        </>
    );
};

export default DashboardPage;
