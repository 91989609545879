import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';

import { useUpdateServiceOrderRowMutation } from '../../../../../../../generated/graphql';
import { FeatureToggleService } from "../../../../../../../services/Launchdarkly/services/FeatureToggleService";
import AppTableButton from '../../../../../../Common/Common/AppTableButton';
import LoaderCircularDiscrete from '../../../../../../Common/Common/LoaderCircularDiscrete';
import { useStore } from "../../../../../../Common/context/store-context";
import { useUser } from "../../../../../../Common/context/user-context";
import { priceFormat } from '../../../../../utils/productHelper';
import AmountInput from '../../../../Inputs/AmountInput';
import { DiscountInput } from './DiscountInput';
import { VatSelect } from './VatSelect';

// https://www.delftstack.com/howto/javascript/javascript-round-to-2-decimal-places/#use-double-rounding-to-round-a-number-to2-decimal-places-in-javascript
function round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

export default function SelectedProducts({ serviceOrder, locked = false }) {
    const [updateRow, { loading: rowUpdating }] = useUpdateServiceOrderRowMutation();
    const [user] = useUser();
    const [storeId] = useStore();
    const [hide12Vat, setHide12Vat] = useState(null);

    // @todo The launch darkly client needs to be initialized every time,
    //  refactor this so that it can be initialized when we have a user id and all stores.
    //  Refactor this as a provider
    const service = useMemo(async () => await FeatureToggleService.getClient({ key: `${user.username}`, custom: { storeIds: storeId } }), [user, storeId]);

    useEffect( () => {
        async function hide12VatFn() {
            const client = await service;
            setHide12Vat(await client.variation('SERVICE_ORDER_ROW_HIDE_12_VAT', false));
        }
        hide12VatFn();

    }, [service, setHide12Vat]);

    if (null === hide12Vat) return null;
    
    const updateQuantity = async (row, newValue) => {
        try {
            await updateRow({
                variables: {
                    rowId: +row.id,
                    quantity: newValue,
                },
            });
        } catch (e) {
            // Sometimes happens if you remove row that is already removed.
            console.log(e);
        }

        if (newValue === 0) {
            // Optimistic remove
            serviceOrder.orderRows = serviceOrder
                .orderRows?.filter((r) => r.id !== row.id);
        }
    };

    const updateVat = function(row, newVat) {
        updateRow({
            variables: {
                rowId: +row.id,
                vat: newVat,
            },
        }).then();
    };
    const updateDiscount = function(row, newDiscount) {
        updateRow({
            variables: {
                rowId: +row.id,
                discount: newDiscount,
                discountPercent: 0,
            },
        }).then();
    };

    const setPercentDiscount = function(row, percent) {
        const discount = round(row.priceIncVat * (percent / 100));

        updateRow({
            variables: {
                rowId: +row.id,
                discount: discount,
                discountPercent: percent,
            },
        }).then();
    };

    const rows = serviceOrder.orderRows || [];

    function rowTotal(row) {
        return (row.priceIncVat - row.discount) * row.quantity;
    }

    function rowsTotal(rows) {
        return rows.reduce((sum, row) => sum + rowTotal(row), 0);
    }

    rows.sort((a, b) => b.createdAt - a.createdAt);

    return (
        <div>
            <h4>Valda produkter</h4>
            <div className="card">
                <LoaderCircularDiscrete visible={rowUpdating} />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ArtNr</TableCell>
                                <TableCell>Beskrivning</TableCell>
                                <TableCell align="right">Pris</TableCell>
                                <TableCell style={{ width: 100 }}>Rabatt</TableCell>
                                <TableCell style={{ width: 60 }}>%</TableCell>
                                <TableCell style={{ width: 60 }}>Moms</TableCell>
                                <TableCell align='center' style={{ width: 142 }}>Antal</TableCell>
                                <TableCell />
                                <TableCell style={{ width: 38 }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        {row.sku}
                                    </TableCell>
                                    <TableCell>
                                        {row.description}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title={`${priceFormat(row.priceExVat)} exkl. moms`} placement='left'>
                                            <span>{priceFormat(row.priceIncVat)}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <DiscountInput
                                            value={row.discount}
                                            max={row.priceIncVat}
                                            onChange={(newValue) =>
                                                updateDiscount(row, newValue)
                                            }
                                            unit='kr'
                                            disabled={locked}
                                        />
                                    </TableCell>
                                    <TableCell padding='checkbox'>
                                        <DiscountInput
                                            value={row.discountPercent}
                                            max={100}
                                            width={52}
                                            onChange={(newValue) =>
                                                setPercentDiscount(row, newValue)
                                            }
                                            unit='%'
                                            disabled={locked}
                                        />
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <VatSelect
                                            value={row.selectedVat}
                                            onChange={(newValue) => updateVat(row, newValue)}
                                            disabled={locked}
                                            hide12Vat={hide12Vat}
                                        />
                                    </TableCell>
                                    <TableCell padding="checkbox" align="center">
                                        <AmountInput
                                            amount={row.quantity}
                                            onChange={(newValue) =>
                                                updateQuantity(row, newValue)
                                            }
                                            disabled={locked}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {priceFormat(rowTotal(row))}
                                    </TableCell>
                                    <TableCell align="right" padding="none">
                                        {!locked ? (
                                            <AppTableButton
                                                className='button-delete-product'
                                                onClick={() =>
                                                    updateQuantity(row, 0)
                                                }
                                                icon={faTrash}
                                            >
                                                Ta bort
                                            </AppTableButton>
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell
                                    align="right"
                                    colSpan={7}
                                    style={{ border: 'none' }}
                                >
                                    Totalt: {priceFormat(rowsTotal(rows))}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
