import React from 'react';
import { SystemUser } from 'src/generated/graphql';
import { Dialog } from 'src/modules/Common/custom-hooks/useDialog3';
import ErrorMessage from 'src/modules/Common/ErrorMessage/ErrorMessage';
import {
    EditUserForm,
    EditUserFormValues,
} from 'src/modules/UserAdministration/components/EditUserDialog/EditUserForm';
import { useEditUserMutation } from 'src/modules/UserAdministration/hooks/useUserMutations';

interface Props {
    dialogRef: any;
    user: SystemUser
}

export function EditUserDialog({ dialogRef, user }: Props) {

    const [editUser, { loading, error }] = useEditUserMutation();

    const onSubmit = async (formData: EditUserFormValues) => {
        await editUser({
            variables: {
                userId: user.id,
                ...formData,
            }
        });
        dialogRef.close()
    }

    return (
        <Dialog dialogRef={dialogRef} tabs={[{
            label: 'Redigera användare',
            content: (
                <div>
                    <ErrorMessage error={error} />
                    <EditUserForm
                        loading={loading}
                        onSubmit={onSubmit}
                        defaultValues={{
                            name: user.name || '',
                        }}
                    />
                </div>
            ),
        }]}
        />
    );
}
