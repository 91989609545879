import { SystemUser } from "src/generated/graphql";

export const MechanicsTableColumns = [
    {
        name: 'Namn',
        selector: 'name',
        sortable: true,
        cell: (row: SystemUser) => {
            return row.name;
        }
    }, {
        name: 'E-post',
        selector: 'email',
        sortable: true,
        cell: (row: SystemUser) => {
            return row.email;
        }
    }];