import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import CreateBikeDialog from '../../../Bike/components/CreateBikeDialog';
import { useDialog2 } from '../../../Common/custom-hooks/useDialog2';
import AppSelect from '../../../Common/Form/AppSelect';
import { OpenEntityDialogButton } from '../../../Common/Form/Buttons';

const CreateNewBikeDialog = ({ customerId, companyId, onHide }) => {
    const { createDialog } = useDialog2();
    const showCreateBikeDialog = () => {
        const ref = createDialog((ref) => (
            <CreateBikeDialog customerId={customerId} companyId={companyId} dialogRef={ref} />
        ));
        ref.onClose.push(onHide);
    };

    return <OpenEntityDialogButton onDialogOpen={showCreateBikeDialog} />;
};

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    inputWrapper: {
        cursor: 'pointer',
        flexGrow: 1,
    },
    openDialog: {
        position: 'relative',
        left: '5px',
    },
    disabledOverlay: {
        position: 'absolute',
        background: 'rgba(255,255,255,0.6)',
        top: -5,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

export default function CustomerBikeInput({
    bikes,
    customerId,
    companyId,
    control,
    onBikeCreate,
    onBikeChange,
    disabled = false,
}) {
    const classes = useStyles();
    const onHide = async (createdBike) => {
        if (!isEmpty(createdBike) && createdBike.id !== undefined) {
            onBikeCreate(createdBike);
        }
    };

    return (
        <div className={classes.root}>
            <AppSelect
                control={control}
                name='customerBikeId'
                label='Cykel'
                handleChange={onBikeChange}
            >
                <MenuItem key={0} value={''}>
                    --
                </MenuItem>
                {bikes.map((bike) => (
                    <MenuItem key={bike.id} value={bike.id}>
                        {bike.manufacturer} {bike.description}
                    </MenuItem>
                ))}
            </AppSelect>
            <div className={classes.openDialog}>
                <CreateNewBikeDialog customerId={customerId} companyId={companyId} onHide={onHide} />
            </div>
            <div>{(!customerId || disabled) && <div className={classes.disabledOverlay} />}</div>
        </div>
    );
}
