import { useEffect, useRef, useState } from 'react';

const useDebounce = ({ value, initialValue = null, delay = 300 }) => {
    const [debouncedValue, setDebouncedValue] = useState(initialValue);
    const didMount = useRef(false);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebounce;
