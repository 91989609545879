import { format } from 'date-fns';
import React, { useMemo } from 'react';

import { useSearchForServiceOrdersLazyQuery } from '../../../../../../generated/graphql';
import { FeatureToggleService } from '../../../../../../services/Launchdarkly/services/FeatureToggleService';
import { useStore } from '../../../../../Common/context/store-context';
import { useUser } from '../../../../../Common/context/user-context';
import { useDialog2 } from '../../../../../Common/custom-hooks/useDialog2';
import { getCompanyNameFromCustomer } from '../../../../../Customer/helpers/company.helper';
import ServiceOrderDialog from '../../../../../ServiceOrder/components/Dialogs/ServiceOrderDialog';
import { orderStatusText } from '../../../../../ServiceOrder/utils/serviceOrderFields';
import { QuickSearch } from './QuickSearch';

const columns = [
    {
        name: 'Ordernummer',
        selector: 'orderNumber',
        sortable: true,
        grow: 2,
    },
    {
        name: 'Cykeltyp',
        selector: 'bikeType',
        cell: (row) => row.bikeType || '-',
        sortable: true,
    },
    {
        name: 'Kundens namn',
        selector: 'customer.name',
        cell: (row) => row.customer?.name || '-',
        sortable: false,
        grow: 3,
    },
    {
        name: 'Företagsnamn',
        selector: 'customer.companyName',
        cell: (row) => getCompanyNameFromCustomer(row.customer) || '-',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Beskrivning',
        selector: 'description',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Mekaniker',
        selector: 'serviceByName',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Status',
        selector: 'status',
        cell: (row) => orderStatusText(row.status),
        sortable: true,
        grow: 3,
    },
    {
        name: 'Planerat datum',
        selector: 'serviceDate',
        cell: (row) => format(new Date(row.serviceDate), 'yyyy-MM-dd'),
        width: '140px',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Butik',
        selector: 'store.name',
        cell: (row) => row.store?.name || '-',
        sortable: true,
        grow: 2,
    },
];

export function OrderSearchPanel() {
    const [user] = useUser();
    const [storeId,, stores] = useStore();
    const { createDialog } = useDialog2();

    // @todo The launch darkly client needs to be initialized every time,
    //  refactor this so that it can be initialized when we have a user id and all stores.
    //  Refactor this as a provider
    const service = useMemo(async () => await FeatureToggleService.getClient({ key: `${user.username}`, custom: { storeIds: storeId } }), [user, storeId]);

    const [getServiceOrders, { data, loading, error, called }] = useSearchForServiceOrdersLazyQuery({
        fetchPolicy: 'network-only',
    });

    const onSearch = async (searchQuery) => {
        const storeIds = stores.map(store => store.id) ?? [storeId];

        const client = await service;

        // @todo Would be nice if keys where a type
        const feature = await client.variation('SEARCH_ORDERS_MULTIPLE_STORES', false);

        if (searchQuery && searchQuery.length > 2) {
            getServiceOrders({ variables: { searchQuery, storeIds: (feature ? storeIds : [storeId]) } });
        }
    };

    const onSelect = (serviceOrderId) => {
        createDialog((ref) => (
            <ServiceOrderDialog
                serviceOrderId={serviceOrderId}
                dialogRef={ref}
            />
        ));
    };

    return (
        <QuickSearch
            error={error}
            id='quick-order-search'
            label='Sök'
            placeholder='Personnummer, telefonnummer, ordernummer eller kundens namn'
            onSearch={onSearch}
            loading={loading}
            columns={columns}
            items={data?.searchForServiceOrders?.items}
            onSelect={onSelect}
            called={called}
            noResultText={'Inga ordrar hittades'}
        />
    );
}
