import gql from 'graphql-tag';

export const GET_HEADS_LOGS = gql`
    query getHeadsLogs($pagination: PaginatedArguments!) {
        headsLogs(pagination: $pagination) {
            total
            pageSize
            items {
                id
                request
                response
                createdAt
                status
                path
                serviceOrder {
                    orderNumber
                    status
                }
            }
        }
    }
`;

export const RESEND_HEADS_MESSAGE = gql`
    mutation resendHeadsMessage($id: Int!) {
        resendHeadsMessage(id: $id) {
            id
        }
    }
`;
