import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import DataTable from 'react-data-table-component';
import { FilterArgument } from 'src/generated/graphql';
import PaginationState from 'src/modules/Common/DataList/PaginationState';
import { NoResult } from 'src/modules/Layout/QuickSearch/components/Dialog/Panels/QuickSearch';
import client from 'src/services/GraphQL/client';

import LoaderCircular from '../Common/LoaderCircular';

export interface DataListOptions {
    initialPagination?: Partial<PaginationState>,
}

export interface Order {
    id: string;
    name: string;
    status: string;
    description: string;
    maxCost: string;
    store: any;
    customer: any;
    serviceByUserId: string;
    serviceByName: string;
    serviceBy: any;
    serviceDate: any;
    createdAt: string;
    isRemoved: boolean;
}

const TableWrapper = styled.div`
    position: relative;
    min-height: 210px;
`;

export default class DataList extends React.Component {
    options: DataListOptions | null = null;
    columns: [] = [];
    conditionalRowStyles: [] = [];
    customStyles: any = {};
    baseFilters: FilterArgument[] = [];
    initialFilter: FilterArgument | null = null;
    state = {
        items: [],
        pagination: new PaginationState(),
        resetDefaultPage: false,
        totalCount: 0,
        loading: true,
        filters: {},
    };
    onRowClicked: any | undefined = () => {};
    query: any;
    queryKey: string | null = null;

    constructor(props: any) {
        super(props);
        this.refetch = this.refetch.bind(this);
        this.filterList = this.filterList.bind(this);
    }

    async componentDidMount() {
        const initialPagination = {
            orderBy: 'id',
            page: 1,
            pageSize: 20,
            orderDir: 'ASC',
            ...this.options?.initialPagination || {}
        };
        await this.loadPage(initialPagination, this.initialFilter);
    }

    async loadPage(state: PaginationState, filter?: FilterArgument | null) {
        try {
            this.setState((prevState) => ({
                ...prevState,
                loading: true,
                filters: filter,
            }));
            const result = await client.query({
                variables: {
                    ...state,
                    filter: {
                        where: [
                            ...this.baseFilters,
                            ...filter?.where || [],
                        ]
                    },
                },
                query: this.query,
                fetchPolicy: 'no-cache',
            });

            const items: Order[] = result.data[this.queryKey!].items;
            const totalCount = result.data[this.queryKey!].total;

            this.setState({
                pagination: state,
                items,
                totalCount: totalCount,
                resetDefaultPage: this.state.resetDefaultPage !== undefined,
                loading: false,
            });
        } catch (err) {
            console.log('error: ', err);
        }
    }

    hasFilters() {
        return !isEmpty(this.state.filters);
    }

    subHeaderComponent() {
        return null;
    }

    filterList(filter: FilterArgument) {
        const initialPagination = {
            orderBy: 'id',
            page: 1,
            pageSize: 20,
            orderDir: 'ASC',
            ...this.options?.initialPagination || {}
        };
        const state = Object.assign({}, initialPagination);
        this.loadPage(state, filter).then();
    }

    changePage(page: number) {
        const state = Object.assign({}, this.state.pagination);
        state.page = page;
        this.loadPage(state).then(() => {});
    }

    changeRowsPerPage(pageSize: number) {
        const state = Object.assign({}, this.state.pagination);
        state.pageSize = pageSize;
        this.loadPage(state).then(() => {});
    }

    changeSort(col: any, sortDirection: string) {
        const state = Object.assign({}, this.state.pagination);
        state.orderBy = col.selector;
        state.orderDir = sortDirection.toUpperCase();
        this.loadPage(state).then(() => {});
    }

    refetch() {
        this.loadPage(this.state.pagination).then(() => {});
    }

    render() {
        const subHeader = this.subHeaderComponent();

        return (
            <TableWrapper>
                <LoaderCircular visible={this.state.loading} size={50} />
                <DataTable
                    noHeader
                    columns={this.columns}
                    customStyles={this.customStyles}
                    conditionalRowStyles={this.conditionalRowStyles}
                    defaultSortField={this.options?.initialPagination?.orderBy}
                    paginationServer={true}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                    onChangePage={(page) => {
                        this.changePage(page);
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        this.changeRowsPerPage(pageSize);
                    }}
                    onRowClicked={this.onRowClicked}
                    paginationTotalRows={this.state.totalCount}
                    sortServer={true}
                    onSort={(col, sortDirection) => {
                        this.changeSort(col, sortDirection);
                    }}
                    data={this.state.items}
                    striped
                    subHeader={subHeader !== null}
                    subHeaderComponent={subHeader}
                    paginationResetDefaultPage={this.state.resetDefaultPage}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Poster per sida',
                        rangeSeparatorText: 'av',
                        selectAllRowsItemText: 'Alla',
                    }}
                    pagination
                    noDataComponent={
                        <NoResult>Det finns ingen data att visa</NoResult>
                    }
                />
            </TableWrapper>
        );
    }
}
