import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { format } from 'date-fns';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useOnServiceOrderChangeForStoreSubscription } from '../../../../../generated/graphql';
import { orderOriginTexts, orderStatusTexts } from '../../../../Common/constants/texts';
import { useStore } from '../../../../Common/context/store-context';
import AppButton from '../../../../Common/Form/AppButton';
import AppDateInput from '../../../../Common/Form/AppDateInput';
import AppSelect from '../../../../Common/Form/AppSelect';
import AppTextInput from '../../../../Common/Form/AppTextInput';
import { useMechanics } from '../../../hooks/useMechanics';

export default function FilterForm({ filterList }) {
    const [storeId] = useStore();
    const { users: mechanics } = useMechanics(storeId);
    const { handleSubmit, register, control } = useForm({
        defaultValues: {
            serviceDateFrom: new Date(),
        }
    });

    const onSubmit = (formValues) => {
        const where = Object.entries(formValues)
            .filter(([, value]) => {
                return value !== undefined && value !== '' && value !== null;
            })
            .map((field) => {
                let [key, value] = field;

                if (key === 'serviceDateFrom') {
                    return {
                        field: 'serviceDate',
                        stringValue: format(value, 'yyyy-MM-dd'),
                        operator: '>=',
                    };
                }

                if (key === 'serviceDateTo') {
                    return {
                        field: 'serviceDate',
                        stringValue: format(value, 'yyyy-MM-dd 23:59:59'),
                        operator: '<=',
                    };
                }

                return { field: key, stringValue: value };
            });

        filterList({
            where: where,
        });
    };

    useOnServiceOrderChangeForStoreSubscription({
        variables: { storeId },
        onSubscriptionComplete: () => handleSubmit(onSubmit), // we don't care about the result. Just refetch stats.
    });

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete='off'
                style={{ width: '100%', margin: '20px 10px 20px 0' }}
            >
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item sm={4}>
                        <AppTextInput
                            label='Ordernummer'
                            name='id'
                            size='small'
                            inputRef={register}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <AppSelect
                            control={control}
                            name='serviceByUserId'
                            label='Mekaniker'
                            defaultValue=''
                            size='small'
                        >
                            <MenuItem key='none' value=''>-</MenuItem>
                            {(mechanics || []).map((user) => (
                                <MenuItem
                                    key={user.id}
                                    value={user.id}
                                >
                                    {user.name}
                                </MenuItem>
                            ))}
                        </AppSelect>
                    </Grid>
                    <Grid item sm={4}>
                        <AppSelect
                            control={control}
                            name='status'
                            label='Status'
                            defaultValue=''
                            size='small'
                        >
                            <MenuItem key='none' value=''>-</MenuItem>
                            {Object.entries(orderStatusTexts).map(
                                ([statusKey, statusValue], index) => (
                                    <MenuItem
                                        key={`${statusKey}-${index}`}
                                        value={statusKey}
                                    >
                                        {statusValue}
                                    </MenuItem>
                                ),
                            )}
                        </AppSelect>
                    </Grid>
                    <Grid item sm={4}>
                        <AppDateInput
                            label='Tidigast planerad'
                            name='serviceDateFrom'
                            control={control}
                            size='small'
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <AppDateInput
                            label='Senast planerad'
                            name='serviceDateTo'
                            control={control}
                            size='small'
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <AppSelect
                            control={control}
                            name='origin'
                            label='Källa'
                            defaultValue=''
                            size='small'
                        >
                            <MenuItem key='none' value=''>-</MenuItem>
                            {Object.entries(orderOriginTexts).map(
                                ([originKey, originValue], index) => (
                                    <MenuItem
                                        key={`${originKey}-${index}`}
                                        value={originKey}
                                    >
                                        {originValue}
                                    </MenuItem>
                                ),
                            )}
                        </AppSelect>
                    </Grid>
                    <Grid item sm={4}>
                        <AppButton
                            type='submit'
                            icon={faCheckCircle}
                        >
                            Applicera
                        </AppButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
