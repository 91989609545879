import { useMutation } from '@apollo/client';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Tab, TabPanel } from 'react-tabs';

import { StoreContext } from '../../Common/context/store-context';
import { Dialog } from '../../Common/custom-hooks/useDialog2';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import AppButton from '../../Common/Form/AppButton';
import TextInput from '../../Common/Form/TextInput';
import { datePattern } from '../../Common/helpers/time';
import {
    CREATE_SCHEDULE_DEVIATION,
    DELETE_SCHEDULE_DEVIATION,
    UPDATE_SCHEDULE_DEVIATION,
} from '../graphql/ScheduleDeviationGql';
import { DateInput,MinutesInput } from '../inputs/Inputs';

const ScheduleDeviationDialog = ({ dialogRef, data }) => {
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        errors,
        getValues,
    } = useForm();
    const [storeId] = useContext(StoreContext);
    const isCreate = !data.id;
    const mutation = isCreate
        ? CREATE_SCHEDULE_DEVIATION
        : UPDATE_SCHEDULE_DEVIATION;
    const [
        update,
        { data: updatedData, loading: updating, error },
    ] = useMutation(mutation, { errorPolicy: 'all' });

    const [deleteScheduleDeviation] = useMutation(DELETE_SCHEDULE_DEVIATION, { errorPolicy: 'all', onCompleted: () => {
        dialogRef.close(true);
    } });

    useEffect(() => {
        if (!isCreate) {
            reset({
                ...data,
            });
        }
    }, [isCreate, reset, data]);

    const transformFormValues = (formValues) => {
        clearErrors();

        return {
            storeId: storeId,
            date: formValues.date,
            timeAvailable: parseInt(formValues.timeAvailable) || 0,
            note: formValues.note || '',
        };
    };

    const onSubmit = async (formValues) => {
        const values = transformFormValues(formValues);
        if (values) {
            update({ variables: { id: data.id, formValues: values } });
        }
    };

    const deleteTime = async () => {
        deleteScheduleDeviation({ variables: { id: data.id } })
    }

    if (updatedData) {
        dialogRef.close(true);
    }

    return (
        <Dialog dialogRef={dialogRef}>
            <Tab>Avvikelse</Tab>
            <TabPanel>
                <ErrorMessage error={error} />
                <div style={{ minWidth: 500 }} id='deviatingDatesForm'>
                    <form
                        className='schedule-form-fields'
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ marginTop: 20 }}
                    >
                        <TextInput
                            id='note'
                            label='Beskrivning'
                            name='note'
                            register={register}
                        />
                        <DateInput
                            id='date'
                            label='Datum'
                            name='date'
                            register={register}
                            getValues={getValues}
                            inputProps={{ 'pattern': datePattern }}
                            formatGuide='yyyy-mm-dd'
                        />
                        {errors.date && <p>{errors.date.message}</p>}
                        <MinutesInput
                            id='timeAvailable'
                            label='Minuter tillgängligt'
                            name='timeAvailable'
                            register={register}
                            getValues={getValues}
                            inputProps={{ 'pattern': '^\\d+$' }}
                        />
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <AppButton
                                disabled={updating}
                                type='submit'
                                startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                            >
                                {isCreate ? 'Lägg till' : 'Uppdatera'}
                            </AppButton>
                            {
                                data?.id ? (<AppButton
                                    disabled={updating}
                                    type='button'
                                    onClick={deleteTime}
                                    startIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                                >
                                    Ta bort
                                </AppButton>) : null
                            }
                        </Box>
                    </form>
                </div>
            </TabPanel>
        </Dialog>
    );
};

export default ScheduleDeviationDialog;
