import { NotificationType } from 'src/generated/graphql';

export function notificationTypeText(status: NotificationType): string {
    return {
        [NotificationType.OrderReceived]: 'Order Mottagen',
        [NotificationType.OrderReminder]: 'Order Påminelse',
        [NotificationType.OrderToOrder]: 'Order Att Beställa',
        [NotificationType.OrderOrdered]: 'Order Beställd',
        [NotificationType.OrderToAssemble]: 'Order Att Montera',
        [NotificationType.OrderReadyToDeliver]: 'Order Leveransklar',
        [NotificationType.OrderRetrieved]: 'Order Uthämtad',
        [NotificationType.OrderDenied]: 'Order Nekad',
        [NotificationType.OrderWaitingMessage]: 'Order Väntar Besked',
        [NotificationType.OrderCanceled]: 'Order Makulerad',
        [NotificationType.WorkshopIncome]: 'Verkstad Inkommen',
        [NotificationType.WorkshopBooked]: 'Verkstad Bokad',
        [NotificationType.WorkshopWaitingForParts]: 'Verkstad Inväntar delar',
        [NotificationType.WorkshopReady]: 'Verkstad Klar',
        [NotificationType.WorkshopReminder]: 'Verkstad Påminnelse',
        [NotificationType.WorkshopReminder_2]: 'Verkstad Påminnelse 2',
        [NotificationType.OrderFinished]: 'Order Avslutad',
        [NotificationType.Custom]: 'Meddelande från mekaniker',
        [NotificationType.RequestPhoneVerification]: 'Verifiera mobilnummer för onlinebokning',
        [NotificationType.CustomerResponse]: '',
    }[status];
}
