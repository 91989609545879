import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React from 'react';

const SignOutButton = () => {
    const handleSignOut = () => {
        Auth.signOut().then(() => {
            window.location.href = '/';
        });
    }

    return (
        <ButtonBase onClick={handleSignOut} role='presentation' style={{ marginLeft: '15px' }}>
            <FontAwesomeIcon icon={faSignOutAlt} size='lg' />
        </ButtonBase>
    );
}

export default SignOutButton;
