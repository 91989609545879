import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const IconWrapper = styled.div`
    padding: 7px 0 6px;
`;
const TextWrapper = styled.div`
    text-transform: none;
    margin: 0 8px;
`;

const AppTableButtonLarge = ({ icon, label, ...rest }: any) => {
    return (
        <Button {...rest}>
            <div>
                <IconWrapper>
                    <FontAwesomeIcon icon={icon} style={{ fontSize: 15 }} />
                </IconWrapper>
                <TextWrapper>
                    <Typography variant="body1">{label}</Typography>
                </TextWrapper>
            </div>
        </Button>
    );
};

export default AppTableButtonLarge;
