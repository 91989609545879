import '@aws-amplify/ui/dist/style.css';
import 'react-tiny-fab/dist/styles.css';
import './App.scss';

import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';

import { StoreContextProvider } from '../Common/context/store-context';
import { SubStoreContextProvider } from '../Common/context/sub-store-context';
import { UserContextProvider } from '../Common/context/user-context';
import { useSharedSubject } from '../Common/custom-hooks/sharedSubjects/useSharedSubject';
import { dialogsSubject } from '../Common/custom-hooks/useDialog3';
import AppContent from './AppContent';
import { appTheme } from './AppTheme';
import { Toaster } from './Toaster';

const Dialogs = () => {
    const [data] = useSharedSubject(dialogsSubject);

    if (!data) {
        return null;
    }

    return (
        <div>
            {data.map((dialogRef) => (
                <div key={dialogRef.key}>{dialogRef.content}</div>
            ))}
        </div>
    );
};

const App = () => {
    return (
        <div className="app">
            <ThemeProvider theme={appTheme}>
                <StoreContextProvider>
                    <SubStoreContextProvider>
                        <UserContextProvider>
                            <SnackbarProvider
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <AppContent />
                                <Dialogs />
                                <Toaster />
                            </SnackbarProvider>
                        </UserContextProvider>
                    </SubStoreContextProvider>
                </StoreContextProvider>
            </ThemeProvider>
        </div>
    );
};

export default App;
