import { ApolloError } from '@apollo/client';
import styled from '@emotion/styled';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormGroup, FormLabel, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { FormProvider, useForm, UseFormMethods } from 'react-hook-form';
import AppCheckbox from 'src/modules/Common/Form/AppCheckbox';

import {
    Customer,
} from '../../../generated/graphql';
import LoaderCircular from '../../Common/Common/LoaderCircular';
import { DialogRef } from '../../Common/custom-hooks/useDialog3';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import TextInput from '../../Common/Form/TextInput';
import { OrgNumberInput } from '../../ServiceOrder/components/Inputs/OrgNumberInput';
import AddressesInput from '../components/AddressesInput';
import { SubmitCustomerButton } from '../components/SubmitCustomerButton';
import { useCompanyForm } from '../hooks/useCompanyform';

export type CompanyFormProps = { dialogRef: DialogRef; defaultValues?: Customer; customerId?: number };

export function CompanyForm(props: CompanyFormProps) {
    const { dialogRef } = props;

    const {
        loading,
        error,
        onSubmit,
        methods,
        customerExists,
    } = useCompanyForm(props);

    const [ companyExists, setCompanyExists ] = useState<boolean | undefined>(customerExists || undefined);

    const disabled = Boolean(!companyExists);

    const { handleSubmit, watch } = methods;

    watch();

    return (
        <FormProvider {...methods}>
            <LoaderCircular visible={loading} size={50} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ErrorMessage error={error} />
                <Grid container spacing={3} style={{ maxWidth: 700, margin: '0 auto' }}>
                    <Grid item xs={6}>
                        <OrgNumberInput
                            dialogRef={dialogRef}
                            orgNumber={watch('orgNumber')}
                            onCheckComplete={setCompanyExists}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Företagsnamn"
                            name="companyName"
                            validation={{
                                required: true,
                            }}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput label="E-post" name="email" disabled={disabled} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="Telefonnummer" name="phoneNumber" disabled={disabled} />
                    </Grid>
                    <Section label="Fakturaadress">
                        <Grid container spacing={3} style={{ maxWidth: 700, margin: '0 auto' }}>
                            <Grid item xs={6}>
                                <TextInput
                                    label="Gatuadress"
                                    name="billingAddress[street]"
                                    validation={{
                                        required: true,
                                    }}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextInput
                                    label="Postnummer"
                                    name="billingAddress[postalCode]"
                                    validation={{
                                        required: true,
                                    }}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    label="Ort"
                                    name="billingAddress[city]"
                                    validation={{
                                        required: true,
                                    }}
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>
                    </Section>
                    <AddressesInput disabled={disabled} />
                    <ContactsSection disableAdd={true} disabled={disabled} />
                    {/* <FormControl>
                        <FormLabel component='legend' style={{ margin: 10 }}>
                            Automatiska SMS-utskick
                        </FormLabel>
                        <FormGroup row={ true }>
                            <Grid item xs={12}>
                                <AppCheckbox
                                    control={methods.control}
                                    label="Använd automatiska SMS-utskick"
                                    name="useAutomaticSms"
                                    disabled={disabled}
                                />
                            </Grid>
                        </FormGroup>
                    </FormControl> */}
                    <Grid item xs={12}>
                        <SubmitCustomerButton {...{ loading, disabled }} />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}

interface ContactRowProps {
    required: boolean;
    index: number;
    disabled?: boolean;
}

export function ContactsSection({ disableAdd, disabled }: { disableAdd: boolean, disabled?: boolean }) {
    const [contactIndex, setContactIndex] = useState(0);

    return (
        <Section
            label="Kontaktpersoner"
            action={
                disableAdd ? undefined : (
                    <Box style={{ margin: '0 10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 5 }}
                            disabled={contactIndex === 0}
                            onClick={() => setContactIndex((prevState) => Math.max(prevState - 1, 0))}
                        >
                            <FontAwesomeIcon icon={faMinus} size="1x" />
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 5 }}
                            disabled={contactIndex === 4}
                            onClick={() => setContactIndex((prevState) => Math.min(prevState + 1, 4))}
                        >
                            <FontAwesomeIcon icon={faPlus} size="1x" />
                        </Button>
                    </Box>
                )
            }
        >
            <ContactRow key={0} {...{ index: 0, required: true, disabled }} />
            {Array.from({ length: contactIndex }, (_, index) => (
                <ContactRow key={index} {...{ index, required: false, disabled }} />
            ))}
        </Section>
    );
}

export function ContactRow({ required, index, disabled }: PropsWithChildren<ContactRowProps>) {
    return (
        <Grid container spacing={3} style={{ maxWidth: 700, margin: '0 auto' }}>
            <Grid item xs={6}>
                <TextInput
                    label="Namn"
                    name={`contacts[${index}][name]`}
                    validation={{
                        required,
                    }}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    label="Telefon"
                    name={`contacts[${index}][phoneNumber]`}
                    validation={{
                        required,
                    }}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
}

const Divider = styled.div`
    display: flex;
    align-items: baseline;
    width: 100%;
    padding: 5px 12px;
`;

export function Section({
    children,
    label,
    action,
}: PropsWithChildren<{ label: string; action?: ReactNode | undefined }>) {
    const color = '#bbb';

    return (
        <>
            <Divider>
                <div
                    style={{
                        height: '1px',
                        flexBasis: '20px',
                        backgroundColor: color,
                        marginLeft: '4px',
                    }}
                />
                <Typography component="span" style={{ margin: '0 10px', color: '#888', fontSize: '14px' }}>
                    {label}
                </Typography>
                <div
                    style={{
                        height: '1px',
                        flexGrow: 2,
                        backgroundColor: color,
                        marginRight: '4px',
                    }}
                />
            </Divider>
            {children}
            {action !== undefined ? (
                <Divider style={{ alignItems: 'center', padding: '5px 12px' }}>
                    <div
                        style={{
                            height: '1px',
                            flexGrow: 2,
                            backgroundColor: color,
                            marginLeft: '4px',
                        }}
                    />
                    {action}
                    <div
                        style={{
                            height: '1px',
                            flexGrow: 2,
                            backgroundColor: color,
                            marginRight: '4px',
                        }}
                    />
                </Divider>
            ) : null}
        </>
    );
}
