import './ServiceOrderDialog.scss';

import styled from '@emotion/styled';
import React from 'react';

import { useDeleteServiceOrderMutation } from '../../../../generated/graphql';
import LoaderDialog from '../../../Common/Common/LoaderDialog';
import { Dialog } from '../../../Common/custom-hooks/useDialog3';
import ErrorMessage from '../../../Common/ErrorMessage/ErrorMessage';
import { useQueryServiceOrder } from '../../hooks/useQueryServiceOrder';
import { useServiceOrderModificationWarning } from '../../hooks/useServiceOrderModificationWarning';
import ServiceOrderForm from './ServiceOrderDialog/FormTab/ServiceOrderForm';
import InspectionProtocolTab from './ServiceOrderDialog/InspectionProtocolTab/InspectionProtocolTab';
import ProductsTab from './ServiceOrderDialog/ProductsTab/ProductsTab';
import DebugTab from './ServiceOrderDialog/SmsTab/DebugTab';
import SmsTab from './ServiceOrderDialog/SmsTab/SmsTab';

export const TabPanelContent = styled.div`
    width: 950px;
    min-height: 574px;
    display: flex;
    flex-direction: column;
    > * {
        flex-grow: 1;
    }
`;

const ServiceOrderDialog = ({ serviceOrderId, dialogRef }) => {
    const { data, loading, error } = useQueryServiceOrder(serviceOrderId);
    const [deleteServiceOrder] = useDeleteServiceOrderMutation();

    useServiceOrderModificationWarning(serviceOrderId);

    if (!data || loading) {
        return <LoaderDialog />;
    }

    if (error && !data) {
        return <ErrorMessage error={error} />;
    }

    const handleDelete = () => {
        deleteServiceOrder({ variables: { id: serviceOrderId } })
            .then(dialogRef.close);
        // Waiting for the request to finish before closing
        // So that it gets deleted before refetching the table.
    };

    const serviceOrder = data.serviceOrder;
    
    return (
        <Dialog dialogRef={dialogRef} tabs={[{
            label: 'Orderuppgifter',
            content: (
                <TabPanelContent>
                    <ServiceOrderForm
                        serviceOrder={serviceOrder}
                        onDelete={handleDelete}
                    />
                </TabPanelContent>
            ),
        }, {
            label: 'Produkter/Service',
            testId: 'tab-products-services',
            content: (
                <TabPanelContent>
                    <ProductsTab serviceOrder={serviceOrder} />
                </TabPanelContent>
            ),
        }, {
            label: 'Inspektionsprotokoll',
            content: (
                <TabPanelContent>
                    <InspectionProtocolTab
                        serviceOrder={serviceOrder}
                    />
                </TabPanelContent>
            ),
        }, {
            label: 'Sms',
            content: (
                <TabPanelContent>
                    <SmsTab
                        serviceOrder={serviceOrder}
                    />
                </TabPanelContent>
            ),
        }, {
            label: 'Debug',
            content: (
                <TabPanelContent>
                    <DebugTab
                        serviceOrder={serviceOrder}
                    />
                </TabPanelContent>
            ),
        }]}>
        </Dialog>
    );
};

export default ServiceOrderDialog;
