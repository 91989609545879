import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import React from 'react';
import DataTable from 'react-data-table-component';

import LoaderCircular from '../../../../../Common/Common/LoaderCircular';
import ErrorMessage from '../../../../../Common/ErrorMessage/ErrorMessage';
import AppSearchDebounce from '../../../../../Common/Form/AppSearchDebounce';

export const NoResult = styled.div`
    padding: 20px 0;
`;

export function QuickSearch({
    error,
    id,
    label,
    placeholder,
    onSearch,
    loading,
    columns,
    items,
    onSelect,
    onSelectColumnName = 'id',
    called,
    noResultText,
    debounceDelay = 300,
}) {
    return (
        <>
            <ErrorMessage error={error} />
            <AppSearchDebounce
                autoFocus
                id={id}
                label={label}
                placeholder={placeholder}
                onSearch={onSearch}
                delay={debounceDelay}
            />
            <Card className='loader-container' style={{ marginTop: 30 }}>
                <LoaderCircular visible={loading} size={50} />
                <DataTable
                    noHeader
                    striped
                    highlightOnHover
                    pointerOnHover
                    columns={columns}
                    data={items ?? []}
                    onRowClicked={(row) => onSelect(row[onSelectColumnName])}
                    noDataComponent={
                        called ? (
                            <NoResult>{noResultText}</NoResult>
                        ) : (
                            <NoResult>Ange sökterm</NoResult>
                        )
                    }
                />
            </Card>
        </>
    );
}
