import React from 'react';
import { Tab, TabPanel } from 'react-tabs';

import { Dialog } from '../../Common/custom-hooks/useDialog2';
import BikeForm from './BikeForm';

const CreateBikeDialog = ({ dialogRef, ...rest }) => (
    <Dialog dialogRef={dialogRef}>
        <Tab>Lägg till cykel</Tab>
        <TabPanel>
            <BikeForm onCreate={dialogRef.close} { ...rest } />
        </TabPanel>
    </Dialog>
);

export default CreateBikeDialog;
