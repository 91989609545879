import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React from 'react';
import { ServiceOrder, useStoreMetadataQuery } from 'src/generated/graphql';
import { useStore } from 'src/modules/Common/context/store-context';
import AppButton from 'src/modules/Common/Form/AppButton';
import { Qztray } from 'src/services/Qztray/Qztray';

interface Props {
    serviceOrder: ServiceOrder;
    disabled: boolean;
}

export function PrintButton({ serviceOrder, disabled }: Props) {

    // http://labelary.com/viewer.html

    const [ storeId ] = useStore()!;
    const { data } = useStoreMetadataQuery({ variables: { storeId } });
    const x = data?.storeMetadata?.printerOffsetLeft || 0;

    const received = serviceOrder.history
        .find((entry) => entry.status === 'received');

    if (!received) {
        return null;
    }

    const printLabel = () => {
        const receviedDate = format(new Date(received.createdAt), 'yyyy-MM-dd');
        const plannedDate = format(new Date(serviceOrder.serviceDate), 'yyyy-MM-dd');

        Qztray.printRaw(`^XA
^CI27

^FX -- Set the font size (CFA,20  for mono spaced) --
^CFC,15

^FO${x},30^FD${serviceOrder.name}^FS
^FO${x},50^FD${serviceOrder.description}^FS

^FO${x},80^FDMottagen:^FS
^FO${x+130},80^FD${receviedDate}^FS

^FO${x},100^FDPlanerad:^FS
^FO${x+130},100^FD${plannedDate}^FS

^FX -- Order number --
^CFC,40
^FO${x},130^FD${serviceOrder.orderNumber}^FS

^FX -- Barcode --
^BY3,2,50
^FO${x},170
^BC,80,N,N,N,N
^FD${serviceOrder.orderNumber}^FS

^XZ`);
    };

    return (
        <AppButton
            icon={null}
            disabled={disabled}
            onClick={printLabel}
            startIcon={
                <FontAwesomeIcon icon={faPrint} />
            }
        >
            Etikett
        </AppButton>
    );
}
