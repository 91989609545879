// Reset Material UI Select styling
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';

export const BootstrapInput = withStyles(() => ({
    input: {
        position: 'relative',
        fontSize: 12,
    },
}))(InputBase);

export const MiniSelect = withStyles({
    root: {
        color: 'white',
        border: 'none',
        fontWeight: 'bold',
        fontSize: '12px',
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    icon: {
        color: 'white',
    },
})(Select);

export const MiniSelectLabel = withStyles({
    root: {
        color: 'white',
        display: 'inline-flex',
        fontSize: 'inherit',
    },
})(InputLabel);
