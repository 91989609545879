import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

export function ServiceProtocolNotFoundOnOrder() {
    return (
        <Alert severity="warning">
            <AlertTitle>Detta protokoll saknas.</AlertTitle>
            Äldre ordrar saknar detta serviceprotokoll.
        </Alert>
    );
}
