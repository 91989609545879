import { faPen, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';

export const OpenEntityDialogButton = (props: {
    entityId: null | number | string;
    onDialogOpen: () => void;
}) => {
    return (
        <IconButton
            onClick={props.onDialogOpen}
            color='primary'
        >
            <FontAwesomeIcon
                fontSize={21}
                icon={!props.entityId ? faPlusCircle : faPen}
            />
        </IconButton>
    );
};
