import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        transition:
            'background-color .3s ease-in-out, border-color .35s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.grey[50],
        },
        marginLeft: 0,
        width: '100%',
    },
    primaryActionButton: {
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        width: 30,
        height: 30,
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    cancelButton: {
        marginRight: theme.spacing(1),
        width: 30,
        height: 30,
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    noMarginRight: {
        marginRight: -6,
    },
}));

const AppSearch = ({
    autoSearch,
    label,
    autoFocus,
    primaryActionButton: PrimaryActionButton = SearchIcon,
    onCancel = () => {},
    onChange = () => {},
    onKeyDown = () => {},
    onSearch = () => {},
    placeholder,
    primaryActionLabel = 'search',
    value,
    searchRef,
    ...rest
}) => {
    const [currentValue, setCurrentValue] = useState(value);
    const classes = useStyles();
    const inputRef = useRef()

    const handleOnChange = (e) => {
        setCurrentValue(e.target.value);
        onChange(e);
    };
    const handlePrimaryActionClick = () => {
        setCurrentValue(currentValue);
        onSearch(currentValue);
    };

    const handleSearchOnKeyDown = (e) => {
        const escapeKeyPressed = e.key === 'Escape';

        if (escapeKeyPressed) {
            setCurrentValue('');
        }

        onKeyDown(e);
    };

    const handleOnCancel = () => {
        setCurrentValue('');
        onCancel();
    };

    // Bind ref functions
    if (searchRef && searchRef.current) {
        searchRef.current.reset = () => {
            setCurrentValue('');
            onCancel();
        };
        searchRef.current.focus = () => {
            inputRef.current.focus();
        };
    }

    return (
        <>
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                autoFocus={autoFocus}
                label={label}
                onChange={handleOnChange}
                onKeyDown={handleSearchOnKeyDown}
                className={classes.search}
                placeholder={placeholder}
                value={currentValue}
                inputRef={inputRef}
                id={rest.id}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='start'>
                            {value && (
                                <IconButton
                                    aria-label='cancel'
                                    className={clsx(
                                        classes.cancelButton,
                                        autoSearch
                                            ? classes.noMarginRight
                                            : undefined
                                    )}
                                    component='span'
                                    onClick={handleOnCancel}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                            {!autoSearch && (
                                <IconButton
                                    size='small'
                                    aria-label={primaryActionLabel}
                                    className={classes.primaryActionButton}
                                    component='span'
                                    onClick={handlePrimaryActionClick}
                                >
                                    <PrimaryActionButton />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                    // ...inputProps
                }}
                variant='outlined'
            />
        </>
    );
};

export default AppSearch;
