import styled from '@emotion/styled';
import React from 'react';

const Wrapper = styled.div`
    font-family: monospace;
    white-space: nowrap;
`;

interface Props {
    number: number;
}

export function MonoSpacedNumber({ number }: Props) {
    const string = new Intl.NumberFormat('se-SE').format(number);

    return <Wrapper>{string}</Wrapper>;
}
