import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import {
    CompanyCustomerInput,
    useCreateCompanyUniqueMutation,
    useUpdateCompanyMutation,
} from '../../../generated/graphql';
import { CompanyFormProps } from '../CustomerDialogs/CompanyForm';
import { convertCustomerToCompanyInput } from '../helpers/company.helper';

export const useCompanyForm = ({
    dialogRef,
    defaultValues,
    customerId,
}: CompanyFormProps) => {
    const customerExists = Boolean(defaultValues);

    const { enqueueSnackbar } = useSnackbar();

    const [ createCompanyUnique, { loading, error } ] = useCreateCompanyUniqueMutation({
        onCompleted: () => enqueueSnackbar('Företagskund skapad och tillagd!', { variant: 'success' }),
        onError: () => enqueueSnackbar('Kunde inte skapa företagskund!', { variant: 'error' }),
    });

    const [ updateCompany ] = useUpdateCompanyMutation({
        onCompleted: () => enqueueSnackbar('Företagskund uppdaterad!', { variant: 'success' }),
        onError: () => enqueueSnackbar('Kunde inte uppdatera företagskund!', { variant: 'error' }),
    });

    const updateCompanyCustomer = async (formValues: CompanyCustomerInput) => {
        const updatedCompany = await updateCompany({
            variables: { id: customerId!, companyCustomerInput: formValues },
        });

        dialogRef.close(updatedCompany.data?.updateCompanyCustomer);
    };

    const createCompanyCustomer = async (formValues: CompanyCustomerInput) => {
        const createdOrExistingCompanyCustomer = await createCompanyUnique({
            variables: { companyInput: formValues },
        });

        dialogRef.close(createdOrExistingCompanyCustomer.data?.createCompanyCustomerUnique);
    };

    const onSubmit = customerExists ? updateCompanyCustomer : createCompanyCustomer;

    const methods = useForm<CompanyCustomerInput>({
        mode: 'onChange',
        shouldUnregister: false,
        defaultValues: { ...convertCustomerToCompanyInput(defaultValues!) },
    });

    return {
        loading,
        error,
        onSubmit,
        methods,
        customerExists,
    };
};
