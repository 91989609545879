import React from 'react';
import { Dialog } from 'src/modules/Common/custom-hooks/useDialog3';
import ErrorMessage from 'src/modules/Common/ErrorMessage/ErrorMessage';
import {
    CreateUserForm,
    CreateUserFormValues,
} from 'src/modules/UserAdministration/components/CreateUserDialog/CreateUserForm';
import {
    useCreateUserForStoreMutation,
    useCreateUserMutation,
} from 'src/modules/UserAdministration/hooks/useUserMutations';

interface Props {
    dialogRef: any;
    storeId?: number,
}

export function CreateUserDialog({ dialogRef, storeId }: Props) {

    const [createUser, { loading, error }] = useCreateUserMutation();
    const [createUser2, { loading: loading2, error: error2 }] = useCreateUserForStoreMutation();

    const onSubmit = async (formData: CreateUserFormValues) => {
        if (storeId) {
            await createUser2({
                variables: {
                    storeId: storeId,
                    ...formData
                }
            });
        } else {
            await createUser({
                variables: formData
            });
        }
        dialogRef.close()
    }

    return (
        <Dialog dialogRef={dialogRef} tabs={[{
            label: 'Ny användare',
            content: (
                <div>
                    <ErrorMessage error={error} />
                    <ErrorMessage error={error2} />
                    <CreateUserForm
                        loading={loading || loading2}
                        onSubmit={onSubmit}
                        defaultValues={{}}
                    />
                </div>
            ),
        }]}
        />
    );
}
