import { DocumentNode } from 'graphql';
import { Query } from 'src/generated/graphql';
import cache from 'src/services/GraphQL/Cache';


export function useQueryCache(query: DocumentNode, baseVars: any = {}) {

    const replace = async (data: Partial<Query>, variables: any = {}) => {
        await cache.writeQuery({
            query,
            data,
            variables: {
                ...baseVars,
                ...variables
            }
        })
    }

    return {
        replace
    }
}
