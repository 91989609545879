import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faPen,faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Tab, TabPanel } from 'react-tabs';

import AppTableButton from '../../../../../Common/Common/AppTableButton';
import { Dialog, useDialog2 } from '../../../../../Common/custom-hooks/useDialog2';
import AppButton from '../../../../../Common/Form/AppButton';
import TextInput from '../../../../../Common/Form/TextInput';

const CommentDialog = ({ defaultValue, dialogRef }) => {
    const [value, setValue] = useState(defaultValue);

    return (
        <Dialog dialogRef={dialogRef}>
            <Tab>Lägg till kommentar</Tab>
            <TabPanel>
                <div style={{ width: 500 }}>
                    <TextInput
                        label='Lägg till kommentar'
                        styles={{ margin: '40px' }}
                        defaultValue={defaultValue}
                        onChange={(e) => setValue(e.target.value)}
                    />
                    <div className='button-wrapper-right'>
                        <AppButton
                            startIcon={
                                <FontAwesomeIcon icon={faCheckCircle} />
                            }
                            onClick={() => dialogRef.close({ value })}
                        >
                            Spara
                        </AppButton>
                    </div>
                </div>
            </TabPanel>
        </Dialog>
    )
}

const CommentDialogButton = ({ defaultValue, onClose }) => {
    const { createDialog } = useDialog2();

    const open = () => {
        const ref = createDialog((ref) => (
            <CommentDialog
                defaultValue={defaultValue}
                dialogRef={ref}
            />
        ));
        ref.onClose.push((response) => {
            if (response) {
                onClose(response.value);
            }
        });
    }

    let icon = defaultValue ? faPen : faPlus;

    return (
        <div>
            <AppTableButton icon={icon} onClick={open} />
        </div>
    );
};
export default CommentDialogButton;
