
const isInt = (value: string) => /^\d+$/.test(value)

export const daySpanValidation = (min: number, max: number) => {
    return (rawValue: string) => {
        if (!isInt(rawValue)) {
            return `Värdet måste vara ett nummer mellan ${min} och ${max} dagar`;
        }

        const value = parseInt(rawValue, 10);

        if (value < min || value > max) {
            return `Värdet måste vara mellan ${min} och ${max} dagar`;
        }

        return true;
    }
}
