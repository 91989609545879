import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export const LoaderWrapper = styled.div`
    position: relative;
`;

const LoadingContainer = styled.div`
    position: absolute;
    z-index: 1;
    background-color: #ffffffb3;
    padding: 16px;
    left: 0;
    top: 0;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoaderCircular = ({ visible = true, size = 25, ...rest }) => (
    <div {...rest}>
        {visible && (
            <LoadingContainer>
                <CircularProgress size={size} color='secondary' />
            </LoadingContainer>
        )}
    </div>
);

export default LoaderCircular;
