import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const StyledDivider = withStyles({
    root: {
        backgroundColor: '#999999',
    },
})(Divider);

const MenuDivider = () => {
    return <StyledDivider variant='middle' />;
};

export default MenuDivider;
