import gql from 'graphql-tag';

export const SEARCH_CUSTOMER = gql`
    query searchForCustomers($query: String!) {
        searchForCustomers(query: $query) {
            items {
                id
                name
                phoneNumber
                cellPhoneNumber
                socialSecurityNumber
                email
                company {
                    id
                    companyName
                    orgNumber
                }
                bikes {
                    id
                    manufacturer
                    frameNumber
                }
            }
        }
    }
`;

export const SEARCH_COMPANY_CUSTOMER = gql`
    query searchForCompanyCustomers($query: String!) {
        searchForCompanyCustomers(query: $query) {
            items {
                id
                name
                orgNumber
                phoneNumber
                email
                customerId
            }
        }
    }
`;
