import useEventCallback from '@material-ui/core/utils/useEventCallback';
import React, { useEffect, useState } from 'react';

import useDebounce from '../custom-hooks/useDebounce';
import AppSearch from './AppSearch';

const AppSearchDebounce = ({ onSearch, delay = 300, ...rest }) => {
    const [search, setSearch] = useState('');
    const searchCallback = useEventCallback(onSearch, []);
    const debouncedSearchTerm = useDebounce({ value: search, delay });

    useEffect(() => {
        searchCallback(debouncedSearchTerm);
    }, [debouncedSearchTerm, searchCallback]);

    const resetSearch = () => {
        setSearch('');
        onSearch(''); // skip debounce
    };

    return (
        <AppSearch
            {...rest}
            autoSearch
            onCancel={resetSearch}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
        />
    );
};

export default AppSearchDebounce;
