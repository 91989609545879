import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase, Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import SimpleConfirmDialog from './SimpleConfirmDialog';

export function ExistingCustomerDialog(props) {
    const { customers, isOpen, onConfirm, onCancel, onCustomerSelect, disabled } = props;

    if (disabled) return null;

    const [firstCustomer, ...rest] = customers;

    return (
        <SimpleConfirmDialog
            title="Välj en existerande kund:"
            confirmLabel="Sök i Bisnode"
            open={isOpen}
            onConfirm={() => onConfirm()}
            onCancel={() => onCancel()}
        >
            <Typography align="center">Senast använd</Typography>
            {firstCustomer && (
                <ExistingCustomerCard
                    key={firstCustomer.id}
                    isFirst={true}
                    customer={firstCustomer}
                    onCustomerSelect={onCustomerSelect}
                />
            )}
            {rest.length > 0 && <Typography align="center">tidigare</Typography>}
            {rest.map((customer) => (
                <ExistingCustomerCard
                    key={customer.id}
                    isFirst={false}
                    customer={customer}
                    onCustomerSelect={onCustomerSelect}
                />
            ))}
            <Typography align="center">eller</Typography>
        </SimpleConfirmDialog>
    );
}

function ExistingCustomerCard(props) {
    const { isFirst, customer, onCustomerSelect } = props;

    return (
        <Card style={{ padding: 5, marginBottom: 10, textAlign: 'left' }}>
            <ButtonBase onClick={() => onCustomerSelect(customer)}>
                <CardContent style={{ padding: 5, textAlign: 'left' }}>
                    <Typography>{isFirst && <FontAwesomeIcon
                        fontSize={ 21 }
                        icon={ faStar }
                        color={ '#fecb00' }
                    />} {customer.firstName} {customer.lastName}</Typography>
                    <Typography color='textSecondary' fontSize={12}>ID: {customer.id ?? '-'}</Typography>
                    <Typography color='textSecondary' fontSize={12}>E-post: {customer.email ?? '-'}</Typography>
                    <Typography color='textSecondary' fontSize={12}>Mobil: {customer.cellPhoneNumber ?? '-'}</Typography>
                </CardContent>
            </ButtonBase>
        </Card>
    );
}