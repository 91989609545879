import { useState } from 'react';

const useTableSorting = (defaultProperty, defaultDirection = 'asc') => {
    const [property, _setProperty] = useState(defaultProperty);
    const [direction, _setDirection] = useState(defaultDirection);
    let changeHandler = () => {
    };

    const set = (newProperty, newDirection) => {
        _setProperty(newProperty);
        _setDirection(newDirection);
        changeHandler(newProperty, newDirection === 'desc');
    };

    return {
        property,
        direction,
        descending: direction === 'desc', // boolean alias
        defaultProperty,
        defaultDirection,
        set,
        flip: () => {
            set(property, direction === 'asc' ? 'desc' : 'asc');
        },
        onChange: (handler) => (changeHandler = handler),
    };
};

export default useTableSorting;

// Returns a function that can be used to sort an array of objects
export const inlineSortingFunction = (sortState) => {
    return (a, b) => {
        const dirAsInt = sortState.descending ? -1 : 1;

        if (typeof a[sortState.property] === 'string' && typeof b[sortState.property] === 'string') {
            return a[sortState.property].localeCompare(b[sortState.property]) * dirAsInt;
        }

        if (a[sortState.property] < b[sortState.property]) {
            return dirAsInt;
        }
        if (a[sortState.property] > b[sortState.property]) {
            return -dirAsInt;
        }

        return 0;
    };
};
