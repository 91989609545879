import { css } from 'emotion';
import React from 'react';

import { Customer, useGetCustomerByIdQuery } from '../../../generated/graphql';
import BikesTable from '../../Bike/components/BikesTable';
import LoaderDialog from '../../Common/Common/LoaderDialog';
import { Dialog, DialogRef } from '../../Common/custom-hooks/useDialog3';
import ServiceOrderHistoryTable from '../../ServiceOrder/components/Dialogs/ServiceOrderDialog/FormTab/ServiceOrderHistoryTable';
import { isCompanyCustomer } from '../helpers/company.helper';
import { CompanyForm } from './CompanyForm';
import CustomerForm from './CustomerForm';

const tabContentStyles = css`
    position: relative;
    width: 700px;
    min-height: 524px;
`;

const EditCustomerDialog = ({ customerId, dialogRef }: { customerId: Customer['id']; dialogRef: DialogRef }) => {
    const { data, loading, error } = useGetCustomerByIdQuery({
        fetchPolicy: 'network-only',
        variables: { id: customerId },
        skip: !customerId,
    });

    if (loading) {
        return <LoaderDialog />;
    }

    if (error) {
        // Close the window in case there was an error fetching the customer.
        // useAppQuery will display the error using the snackbar.
        dialogRef.close();

        return null;
    }

    return (
        <Dialog
            dialogRef={dialogRef}
            tabs={[
                {
                    label: 'Kunduppgifter',
                    content: (
                        <div className={tabContentStyles}>
                            {data?.customer && isCompanyCustomer(data?.customer) ? (
                                <CompanyForm
                                    dialogRef={dialogRef}
                                    customerId={customerId}
                                    defaultValues={data?.customer ?? undefined}
                                />
                            ) : (
                                <CustomerForm
                                    dialogRef={dialogRef}
                                    customerId={customerId}
                                    defaultValues={data?.customer ?? undefined}
                                />
                            )}
                        </div>
                    ),
                },
                {
                    label: 'Cyklar',
                    content: (
                        <div className={tabContentStyles}>
                            <BikesTable customerId={customerId} bikes={data?.customer?.bikes} />
                        </div>
                    ),
                },
                {
                    label: 'Historik',
                    content: (
                        <div className={tabContentStyles}>
                            <ServiceOrderHistoryTable customerId={customerId} />
                        </div>
                    ),
                },
            ]}
        />
    );
};

export default EditCustomerDialog;
