export const colors = {
    grayScale: {
        0: '#ededed',
        1: '#d6d6d6',
        2: '#dfdfdf',
    },
    white: '#fff',
    black: '#000000',
    timeline: '#030000',
};
