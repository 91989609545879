import gql from 'graphql-tag';

export const GET_PRODUCTS = gql`
    query getProducts($categoryId: Float!, $page: Int!) {
        products(categoryId: $categoryId, page: $page) {
            total
            items {
                PartNo
                ProductId
                ClientId
                StatusId
                TypeId
                IntegrationPartNo
                ErpName
                DefaultComment
                EanCode
                UnitOfMeasurementId
                UnitOfMeasurementCount
                ImagePath
                IsPublished
                IsBlocked
                IsBuyable
                DiscountClass
                ErpName
                Vat
                Price
                PriceIncVat
                Name
            }
        }
    }
`;

export const FIND_PRODUCTS = gql`
    query searchForProducts($query: String!, $page: Int!, $storeId: Float!) {
        searchForProducts(query: $query, page: $page, storeId: $storeId) {
            items {
                PartNo
                ProductId
                ClientId
                StatusId
                TypeId
                IntegrationPartNo
                ErpName
                DefaultComment
                EanCode
                UnitOfMeasurementId
                UnitOfMeasurementCount
                ImagePath
                IsPublished
                IsBlocked
                IsBuyable
                DiscountClass
                ErpName
                Vat
                Price
                PriceIncVat
                Name
                OnHands {
                    OnHandValue
                }
            }
        }
    }
`;
