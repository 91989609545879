import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

interface Props extends ButtonProps {
    icon?: IconProp | null;
}

export default function AppButton({ icon, children, color = 'primary', ...rest }: Props) {
    return (
        <Button
            type={rest.type ?? 'submit'}
            variant='contained'
            color={color}
            startIcon={icon ? <FontAwesomeIcon icon={icon} /> : ''}
            {...rest}
        >
            {children}
        </Button>
    );
}
