import TextField from '@material-ui/core/TextField';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { cx } from 'emotion';
import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useStyles } from 'src/modules/Common/styles/inputStyles';

interface TextInputProps {
    className?: any;
    inputAdornments?: any;
    validation?: RegisterOptions;
    multiline?: boolean;
    register?: any;
    errors?: any;
    name: string;
    label: string;
    helperText?: string;
}

const TextInput = React.memo(
    ({
        className,
        inputAdornments,
        validation,
        multiline = false,
        register,
        errors,
        helperText = '',
        name,
        label,
        ...rest
    }: TextFieldProps & TextInputProps) => {
        const classes = useStyles();
        const methods = useFormContext();

        register = register || methods?.register || (() => {});
        errors = errors || methods?.errors || {};

        return (
            <>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    name={name}
                    label={label}
                    {...rest}
                    className={cx(classes.inputStyles, className)}
                    InputProps={{
                        ...inputAdornments,
                    }}
                    inputRef={register({
                        ...validation,
                    })}
                    multiline={multiline}
                    error={!!errors[name]}
                    helperText={errors[name] ? errors[name].message : helperText}
                    fullWidth
                />
            </>
        );
    },
);

export default TextInput;
