import 'react-big-calendar/lib/css/react-big-calendar.css';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import sv from 'date-fns/locale/sv';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { dateFnsLocalizer } from 'react-big-calendar';
import { registerLocale } from 'react-datepicker';

registerLocale('sv', sv);

const locales = {
    // 'en-US': require('date-fns/locale/en-US'),
    'sv': sv,
};

export const bigCalendarLocalizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

export const bigCalendarMessage = {
    next: 'Nästa',
    previous: 'Föregående',
    today: 'Idag',
    month: 'Månad',
    week: 'Vecka',
    day: 'Dag',
};
