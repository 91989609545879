import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { useSubStore } from 'src/modules/Common/context/sub-store-context';

import { useOnServiceOrderChangeForStoreSubscription, useOrderStatisticsQuery } from '../../../generated/graphql';
import LoaderCircular from '../../Common/Common/LoaderCircular';
import { useStore } from '../../Common/context/store-context';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import OrderTable from './OrderTable';

export function Dashboard() {
    const [storeId] = useStore();
    const { selectedSubStoreId } = useSubStore();
    const [value, setValue] = React.useState(0);

    const { data, error, loading, refetch } = useOrderStatisticsQuery({
        pollInterval: 60 * 1000,
        variables: { storeId, subStoreId: selectedSubStoreId }
    });

    useOnServiceOrderChangeForStoreSubscription({
        variables: { storeId },
        onSubscriptionData: () => refetch(), // we don't care about the result. Just refetch stats.
    })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (!data || loading || !storeId) {
        return <LoaderCircular />;
    }

    if (error) {
        return <ErrorMessage error={error} />;
    }
    const {
        myOrders,
        bookedOrders,
        newOrders,
        notReceivedPartsAwaiting,
        ordersAwaitingParts,
        completedOrders,
        ordersLeft,
        ordersAwaitingSupplier,
        ordersAwaitingCustomer,
        lateOrders,
        finishedOrders,
    } = data.statistics?.orderStatistics;

    return (
        <div className='dashboard-tabs-wrapper'>
            <Tabs value={value} onChange={handleChange} orientation="vertical" >
                <Tab label={(
                    <div className='tab'><b>{myOrders}</b> Mina ordrar</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{ordersLeft}</b> Kvar idag</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{newOrders}</b> Nya idag</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{bookedOrders}</b> Bokade idag</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{completedOrders}</b> Färdiga idag</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{notReceivedPartsAwaiting}</b> Väntar på delar<br />men ej inkommen</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{ordersAwaitingParts}</b> Väntar<br />på delar</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{ordersAwaitingSupplier}</b> Väntar svar<br />från leverantör</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{ordersAwaitingCustomer}</b> Väntar svar<br />från kund</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{lateOrders}</b> Försenade</div>
                )} />
                <Tab label={(
                    <div className='tab'><b>{finishedOrders}</b> Färdiga för upphämtning</div>
                )} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <OrderTable category='myOrders' />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <OrderTable category='ordersLeft' />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <OrderTable category='newOrders' />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <OrderTable category='bookedOrders' />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <OrderTable category='completedOrders' />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <OrderTable category='notReceivedPartsAwaiting' />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <OrderTable category='ordersAwaitingParts' />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <OrderTable category='ordersAwaitingSupplier' />
            </TabPanel>
            <TabPanel value={value} index={8}>
                <OrderTable category='ordersAwaitingCustomer' />
            </TabPanel>
            <TabPanel value={value} index={9}>
                <OrderTable category='lateOrders' />
            </TabPanel>
            <TabPanel value={value} index={10}>
                <OrderTable category='finishedOrders' showBulk />
            </TabPanel>
        </div>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
