import '../styles/prettyJson.scss';

import React from 'react';

interface Props {
    data: string | null;
}

export function PrettyJson({ data, ...rest }: Props) {

    if (!data) {
        return <pre className="pretty-print-json" {...rest}>{data}</pre>;
    }

    const parsed = safeJsonParse(data);

    if (!parsed) {
        return <pre className="pretty-print-json" {...rest}>{parsed}</pre>;
    }

    const prettyData = JSON.stringify(parsed, undefined, 2);

    const html = syntaxHighlight(prettyData);

    return <pre className="pretty-print-json" {...rest} dangerouslySetInnerHTML={{ __html: html }} />;
}

function syntaxHighlight(json: string) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    /* eslint-disable-next-line no-useless-escape  */
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
        let cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}

function safeJsonParse(str: string) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
}
