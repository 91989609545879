
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import debounce from '@material-ui/core/utils/debounce';
import React, { useEffect, useMemo, useState } from 'react';

export function DiscountInput(props) {
    const {
        onChange,
        value,
        max,
        disabled,
        width = 80,
        unit,
    } = props;

    const [_value, _setValue] = useState(value);

    useEffect(() => {
        if (_value !== value) {
            _setValue(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const _onChange = useMemo(
        () => debounce((nextValue) => {
            onChange(nextValue);
        }, 300),
        [onChange],
    );

    if (disabled) {
        return value ? value + ' ' + unit : '';
    }

    return (
        <FormControl style={{ width: width }} className="input-fontsize-inherit">
            <Input
                fullWidth
                className="number-input-hide-arrows"
                type="number"
                min={0}
                max={max}
                value={_value || ''}
                endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
                onChange={(e) => {
                    _setValue(+e.target.value);
                    _onChange(+e.target.value || 0);
                }}
            />
        </FormControl>
    );
}
