import gql from 'graphql-tag';

export const GET_SCHEDULE = gql`
    query getSchedule($numberOfDays: Float!, $storeId: Float!, $start: String) {
        scheduleAvailableList(numberOfDays: $numberOfDays, storeId: $storeId, start: $start) {
            date
            status
            scheduleTime
            timeUsed
            numberOfJobs
        }
    }
`;
