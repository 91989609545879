import styled from '@emotion/styled';
import React from 'react';

const Divider = styled.div`
    border-left: 2px dotted black;
    margin: 0 24px;
`;

const VerticalDivider = () => (
    <>
        <Divider />
    </>
);

export default VerticalDivider;
