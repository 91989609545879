import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';

import { Settings, useSettingQuery } from '../../generated/graphql';
import { removeTypename } from '../../utils/removeTypename';
import { useStore } from '../Common/context/store-context';
import { AutomatedMessagingSettingsForm } from './AutomatedMessagingSettingsForm';

export const SmsSettingsTab = () => {
    const [ storeId ] = useStore();

    const { data, loading } = useSettingQuery({
        variables: {
            storeId,
            path: 'automation/messaging',
        },
        fetchPolicy: 'network-only',
    });

    const settings = (data?.settings || []) as Settings[];

    if (loading) {
        return <LinearProgress color="secondary" />;
    }

    return (
        <div className="store-metadata-form">
            <AutomatedMessagingSettingsForm storeId={storeId} settings={removeTypename(settings)} />
        </div>
    )
}
