import styled from '@emotion/styled';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form';
import TextInput from 'src/modules/Common/Form/TextInput'

const Divider = styled.div`
    display: flex;
    align-items: baseline;
    width: 100%;
    padding: 5px 12px;
`;

export default function AddressesInput({ disabled }: { disabled?: boolean }) {
    const { control } = useFormContext();
    const { fields: addresses, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "shippingAddress", // unique name for your Field Array
        keyName: "fieldId"
    });

    const addressAmount = addresses.length;

    if (addressAmount == 0) append({ street: '', unit: '', postalCode: '', city: '' });

    return (
        <>
            {addresses.map((val, index) => {
                return ( 
                    <Fragment key={index}>
                        <Divider>
                            <div
                                style={{
                                    height: '1px',
                                    flexBasis: '20px',
                                    backgroundColor: "#bbb",
                                    marginLeft: '4px',
                                }}
                            />
                            <Typography component="span" style={{ margin: '0 10px', color: '#888', fontSize: '14px' }}>
                                Leveransaddress {index + 1}
                            </Typography>
                            <div
                                style={{
                                    height: '1px',
                                    flexGrow: 2,
                                    backgroundColor: "#bbb",
                                    marginRight: '4px',
                                }}
                            />
                            {index == 0 ? <IconButton
                                style={{
                                    flexBasis: '20px',
                                    marginLeft: '4px',
                                }}
                                onClick={() => addressAmount < 15 ? append({ street: '', unit: '', postalCode: '', city: '' }) : undefined}
                                type='button'
                                color='primary'
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </IconButton> : index == addressAmount - 1 ? <IconButton
                                style={{
                                    flexBasis: '20px',
                                    marginLeft: '4px',
                                }}
                                onClick={() => {
                                    remove(addressAmount - 1)
                                }}
                                type='button'
                                color='primary'
                            >
                                <FontAwesomeIcon icon={faMinus} />
                            </IconButton> : null}
                        </Divider> 
                        <Grid container
                            spacing={3}
                            style={{ maxWidth: 700, margin: '0 auto' }}
                            key={val.fieldId}>
                            <Grid item xs={8}>
                                <TextInput
                                    label="Gata"
                                    key={val.id}
                                    defaultValue={val.street}
                                    name={`shippingAddress.${index}.street`}
                                    validation={{ required: true }}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    label="Enhet"
                                    key={val.fieldId}
                                    defaultValue={val.unit}
                                    name={`shippingAddress.${index}.unit`}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    label="Postnummer"
                                    key={val.fieldId}
                                    defaultValue={val.postalCode}
                                    name={`shippingAddress.${index}.postalCode`}
                                    validation={{ required: true }}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextInput
                                    label="Ort"
                                    key={val.fieldId}
                                    defaultValue={val.city}
                                    name={`shippingAddress.${index}.city`}
                                    validation={{ required: true }}
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>
                    </Fragment>
                )
            })}
        </>
    )
}
