import { gql } from '@apollo/client';
import { Query } from 'src/generated/graphql';

import client from '../GraphQL/client';

export default class ConfigService {
    static async getConfig() {
        const configQuery = gql`
            {
                config {
                    appEnv
                    appTimeStart
                    cognitoUserPoolId
                    cognitoRegion
                    cognitoIdentityPoolId
                    cognitoUserPoolClientId
                }
            }
        `;

        const result = await client.query<Query>({
            query: configQuery,
            fetchPolicy: 'no-cache',
        });

        return result.data.config;
    }
}
