import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';

export default function TimeInput(props) {
    const {
        register,
        getValues,
        ...rest
    } = props;

    register(props.name, {
        maxLength: 6,
    });

    const formValue = getValues(props.name);

    const [, setValue] = useState(formValue)

    useEffect(() => {
        setValue(formValue)
    }, [formValue])

    return (
        <div style={{ position: 'relative' }}>
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                variant='outlined'
                fullWidth
                onChange={(e) => setValue(e.target.value)}
                inputRef={register()}
                {...rest}
            />
        </div>
    );
}
