import makeStyles from '@material-ui/core/styles/makeStyles';

export const inputStyles = {
    inputWidth: '303px',
    commentWidth: '348px',
    buttonText: '#fff',
    buttonBackGroundColor: '#000000',
    buttonRightMargin: '94px',
};

export const useStyles = makeStyles((theme) => ({
    buttonStyles: {
        backgroundColor: inputStyles.buttonBackGroundColor,
        color: inputStyles.buttonText,
    },
    boldBorderBottom: {
        borderColor: `1px solid`,
    },
    formContainer: {
        padding: theme.spacing(3),
    },
    inputStyles: {
        minHeight: '30px',
        display: 'block',

        '& .MuiInputLabel-outlined': {
            zIndex: 0,
        },
    },
    datePickerWidth: { width: '100%' },
}));
