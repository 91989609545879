import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import LoaderCircularDiscrete from '../../../Common/Common/LoaderCircularDiscrete';
import { GET_STORES } from '../../graphql/UsersGql';
import { useEditStoreOnUser } from '../../hooks/useUserMutations';

const useStyles = makeStyles(() => ({
    formControl: {
        margin: '24px 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 600,
        minWidth: 380,
    },
}));

const Wrapper = styled.div`
    position: relative;
`;

const UserAssignedStores = ({ userId, stores = [] }) => {
    const classes = useStyles();
    const [assignedStores, setAssignedStores] = useState();

    const { data, loading: fetchingStores } = useQuery(
        GET_STORES,
        {
            fetchPolicy: 'cache-first',
        }
    );

    const [editUserOnStore, { loading: editingStoreForUser }] = useEditStoreOnUser()

    useEffect(() => {
        const assignedStores = stores.map((store) => store.name);
        if (fetchingStores === false && data) {
            const storesForUser = data.stores.items.reduce(
                (accStores, currentStore) => ({
                    ...accStores,
                    [currentStore.name]: {
                        assigned: assignedStores.includes(currentStore.name),
                        id: currentStore.id,
                    },
                }),
                {}
            );
            setAssignedStores(storesForUser);
        }
    }, [fetchingStores, data, stores]);

    const handleChange = (event) => {
        const {
            target: { name, id, checked },
        } = event;
        setAssignedStores({
            ...assignedStores,
            [name]: { ...assignedStores[name], assigned: checked },
        });
        editUserOnStore({ variables: { userId, storeId: parseInt(id) } });
    };
    return (
        <Wrapper>
            <FormControl component='fieldset' className={classes.formControl}>
                <FormLabel component='legend'>Administrera butiker</FormLabel>
                <FormGroup className={classes.formGroup}>
                    {!isEmpty(assignedStores) &&
                        Object.entries(
                            assignedStores
                        ).map(([storeName, store]) => (
                            <FormControlLabel
                                key={`${storeName}Key`}
                                control={
                                    <Checkbox
                                        checked={store.assigned}
                                        color='primary'
                                        id={store.id}
                                        name={storeName}
                                        onChange={handleChange}
                                    />
                                }
                                label={storeName}
                            />
                        ))}
                </FormGroup>
            </FormControl>
            <LoaderCircularDiscrete visible={editingStoreForUser} />
        </Wrapper>
    );
};

export default UserAssignedStores;
