const config = {
    graphql_api: window.location.protocol + '//' + window.location.hostname + '/graphql',
    amplify: {
        aws_cognito_identity_pool_id: 'eu-west-1:e3c3d00e-04cb-414b-ab52-4d076334f9ae',
        aws_cognito_region: 'eu-west-1',
        aws_user_pools_id: 'eu-west-1_VtHzhCtwu',
        aws_user_pools_web_client_id: '2l1q9au9pfjfo6u93jh50hrnmm',
    },
};

export default config;
