import { Auth as AuthAmplify } from 'aws-amplify';

export default class Auth {
    async getToken() {
        try {
            const session = await AuthAmplify.currentSession();

            return session.getIdToken().getJwtToken();
        } catch (err) {
            return null;
        }
    }

    async getTokenOrWait() {
        return new Promise((resolve) => {
            const interval = setInterval(async () => {
                try {
                    const session = await AuthAmplify.currentSession();
                    const token = session.getIdToken().getJwtToken();
                    clearInterval(interval);
                    resolve(token);
                } catch (err) {
                    return null;
                }
            }, 100);
        });

    }
}
