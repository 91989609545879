import './Menu.scss';

import {
    faCalendarAlt,
    faCalendarWeek,
    faCashRegister,
    faColumns,
    faCommentAlt,
    faHouseUser,
    faListUl,
    faSms,
    faStore,
    faUsers,
    faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useGetSystemUserQuery } from 'src/generated/graphql';
import { useUser } from 'src/modules/Common/context/user-context';

import MenuDivider from './MenuDivider';
import { UnreadMessages } from './UnreadMessages';

export default function Menu() {
    const [user, setUser] = useUser();

    const { data: fetchedUser } = useGetSystemUserQuery({
        variables: { id: user?.attributes?.sub },
        skip: user?.attributes?.sub === undefined,
    });

    const hasAccessToGroup = (name) => {
        if (!user) {
            return false;
        }

        const groups = user.signInUserSession.accessToken.payload[
            'cognito:groups'
        ];
        return groups.indexOf(name) !== -1;
    }

    const hasAccessToGroups = (names) => {
        return !!names.find(hasAccessToGroup.bind(this));
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((user) => {
            setUser(user);
        });
    }, [setUser]);

    return (
        <nav className={'menu'}>
            <ul>
                <MenuItem
                    className={'dashboard'}
                    title={'Översikt'}
                    to={'/'}
                    icon={<FontAwesomeIcon icon={faColumns} size="2x" />}
                    exact={true}
                />
                <MenuItem
                    className={'orders'}
                    title={'Alla ordrar'}
                    to={'/orders'}
                    icon={<FontAwesomeIcon icon={faListUl} size="2x" />}
                />
                <MenuItem
                    className={'customers'}
                    title={'Kunder'}
                    to={'/customer'}
                    icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
                />
                <MenuItem
                    className={'schedule'}
                    title={'Schema'}
                    to={'/schedule-overview'}
                    icon={<FontAwesomeIcon icon={faCalendarAlt} size="2x" />}
                />
                <div className='incoming-messages-tab'>
                    <MenuItem
                        className={'conversations'}
                        title={'Kommunikation med kund'}
                        to={'/conversations'}
                        icon={<FontAwesomeIcon icon={faCommentAlt} size="2x" />}
                    />
                    <UnreadMessages />
                </div>
                {hasAccessToGroups(['StoreAdmins', 'Admins']) && (
                    <>
                        <MenuDivider />
                        <MenuItem
                            className={'schedule-administration'}
                            title={'Schemainställningar'}
                            to={'/schedule-administration'}
                            icon={<FontAwesomeIcon icon={faCalendarWeek} size="2x" />}
                        />
                        <MenuItem
                            className={'user-store-administration'}
                            title={'Mekaniker'}
                            to={'/user-store-administration'}
                            icon={<FontAwesomeIcon icon={faHouseUser} size="2x" />}
                        />
                        <MenuItem
                            className={'heads'}
                            title={'Heads-integration'}
                            to={'/heads'}
                            icon={<FontAwesomeIcon icon={faCashRegister} size="2x" />}
                        />
                    </>
                )}
                {hasAccessToGroups(['Admins']) && (
                    <>
                        <MenuDivider />
                        <MenuItem
                            className={'sms-overview'}
                            title={'Skickade SMS'}
                            to={'/sms-overview'}
                            icon={<FontAwesomeIcon icon={faSms} size="2x" />}
                        />
                        <MenuItem
                            className={'user-administration'}
                            title={'Användare'}
                            to={'/user-administration'}
                            icon={<FontAwesomeIcon icon={faUsersCog} size="2x" />}
                        />
                        <MenuItem
                            className={'store-administration'}
                            title={'Butiksinställningar'}
                            to={'/store-administration'}
                            icon={<FontAwesomeIcon icon={faStore} size="2x" />}
                        />
                    </>
                )}
            </ul>
        </nav>
    );
}

function MenuItem(props) {
    const {
        className,
        title,
        icon,
        to,
        exact = false,
    } = props;

    return <li className={className}>
        <Tooltip
            title={title}
            placement="right"
        >
            <NavLink
                exact={exact}
                to={to}
                activeClassName="is-active"
                className="nav-link"
            >
                {icon}
            </NavLink>
        </Tooltip>
    </li>;
}
