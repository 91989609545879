import { Box, Button } from '@material-ui/core';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import useFeatureFlag, { LaunchDarklyFlag } from 'src/modules/Common/custom-hooks/useFeatureFlag';

import { Dialog, DialogRef } from '../../Common/custom-hooks/useDialog3';
import { CompanyForm } from './CompanyForm';
import CustomerForm from './CustomerForm';

const tabContentStyles = css`
    position: relative;
    width: 700px;
    min-height: 524px;
`;

// @todo Refactor, contains code for both creating customer and company
const CreateCustomerDialog = ({ dialogRef }: { dialogRef: DialogRef }) => {
    const [selectedCustomerType, setSelectedCustomerType] = useState<CustomerType>('private');
    const featureFlagBusinessCustomersActive = useFeatureFlag(LaunchDarklyFlag.BUSINESS_CUSTOMERS);
    useEffect(() => {
        if (featureFlagBusinessCustomersActive) {
            setSelectedCustomerType(null);
        }
    }, [featureFlagBusinessCustomersActive])
    return (
        <Dialog dialogRef={dialogRef} tabs={[{
            label: 'Kunduppgifter',
            content: (
                <div className={tabContentStyles} style={{ display: 'flex' }}>
                    {!selectedCustomerType ? (
                        <CustomerSelectionView onSelect={setSelectedCustomerType} />
                    ) : null}
                    {selectedCustomerType === 'private' ? (
                        <CustomerForm dialogRef={dialogRef} />
                    ) : null}
                    {selectedCustomerType === 'company' ? (
                        <CompanyForm dialogRef={dialogRef} />
                    ) : null}
                </div>
            ),
        }]} />
    );
};

export default CreateCustomerDialog;

type CustomerType = 'private' | 'company' | unknown;

function CustomerSelectionView({ onSelect }: { onSelect: (type: CustomerType) => void }) {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1} style={{ gap: '10px' }}>
            <CustomerSelectionButton onClick={() => {
                onSelect('private');
            }} label="Privat" />
            <CustomerSelectionButton onClick={() => {
                onSelect('company');
            }} label="Företag" />
        </Box>
    );
}

function CustomerSelectionButton({ onClick, label }: { onClick: () => void, label: string }) {
    return (
        <Button
            variant="contained"
            color="primary"
            style={{ padding: '10px 40px' }}
            onClick={onClick}
        >
            {label}
        </Button>
    );
}
