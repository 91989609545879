import { css } from 'emotion';

import { colors } from './colors';

export const tableStyles = css`
    border: 2px solid ${colors.grayScale[1]};
    border-radius: 5px;
    min-height: 40px;
    overflow-x: auto;
    min-width: 100%;
`;
