import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useForm } from 'react-hook-form';
import { MutationCreateUserArgs } from 'src/generated/graphql';
import AppButton from 'src/modules/Common/Form/AppButton';
import AppTextInput from 'src/modules/Common/Form/AppTextInput';

export type CreateUserFormValues = MutationCreateUserArgs;

interface Props {
    onSubmit: (values: CreateUserFormValues) => void,
    loading: boolean,
    defaultValues: Partial<CreateUserFormValues>
}

export function CreateUserForm(props: Props) {
    const { register, handleSubmit, errors } = useForm({
        defaultValues: props.defaultValues,
    });

    return (
        <div style={{ width: 550, margin: '30px auto 0' }}>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AppTextInput
                            inputRef={register}
                            label="Namn"
                            name="name"
                            required={true}
                        />
                        {errors.name?.message || null}
                    </Grid>
                    <Grid item xs={12}>
                        <AppTextInput
                            inputRef={register}
                            label="E-post"
                            type='email'
                            name="email"
                            required={true}
                        />
                        {errors.email?.message || null}
                    </Grid>
                    <Grid item xs={12}>
                        <AppTextInput
                            inputRef={register}
                            type='password'
                            label="Tillfälligt lösenord"
                            name="tempPassword"
                            required={true}
                        />
                        {errors.tempPassword?.message || null}
                    </Grid>
                    <Grid item xs={12}>
                        <p>
                            Ett mail skickas till ifylld e-post med det tillfälliga lösenordet.
                            Användaren tvingas att byta lösenord vid första inloggningstillfälle.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='button-wrapper-right'>
                            <AppButton
                                disabled={props.loading}
                                type='submit'
                                variant='contained'
                                startIcon={
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                }
                            >
                                Skapa
                            </AppButton>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
