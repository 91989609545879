export function vatFormat(vat: number | null | undefined): string {
    return vat !== null ? vat + '%' : '-';
}

export function priceFormat(price: number | null | undefined, minDigits = 0): string {
    if (typeof price === 'number') {
        return new Intl.NumberFormat('sv-SE', {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: minDigits,
        }).format(price);
    }

    return '-';
}
