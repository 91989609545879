// Move out
import { useEffect, useState } from "react";

export const useOnChange = (watch: (field: string) => any, field: string, callback: () => void) => {
    const [persistedValue, setPersistedValue] = useState(undefined);
    const watchedValue = watch(field);

    useEffect(() => {
        if (persistedValue === undefined) {
            // first time
            setPersistedValue(watchedValue);
            return;
        }
        if (persistedValue !== watchedValue) {
            // Value has changed
            setPersistedValue(watchedValue);
            callback();
        }
    }, [watchedValue, callback, persistedValue])
}
