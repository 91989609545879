import styled from '@emotion/styled';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';

const LoadingContainer = styled.div`
    height: 0;
`;

export default function LoaderLinearDiscrete({ visible = true }) {
    return (
        <LoadingContainer>
            {visible && (
                <LinearProgress color="secondary" />
            )}
        </LoadingContainer>
    );
}
