import './ScheduleOverviewPage.scss';

import React from 'react';

import { StoreSchedule } from './StoreSchedule';

const ScheduleOverviewPage = () => (
    <>
        <h1>Schema</h1>
        <div className='box'>
            <div className='schedule-overview-page'>
                <StoreSchedule />
            </div>
        </div>
    </>
);

export default ScheduleOverviewPage;
