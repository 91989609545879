import './StoreSelector.scss';

import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

import { BootstrapInput, MiniSelect, MiniSelectLabel } from '../../Common/Common/MiniSelect';
import { useStore } from '../../Common/context/store-context';

function StoreSelector({ stores = [], ...rest }) {
    const [currentStore, setCurrentStore] = useStore();

    const SelectElement = (stores) => {
        if (currentStore === undefined || !stores.length) {
            return (
                <div {...rest}>
                    <div className={'store-select-loader'}>
                        <CircularProgress
                            color='secondary'
                            classes={{ root: 'root' }}
                        />
                    </div>
                </div>
            );
        }

        return (
            <MiniSelect
                value={currentStore}
                onChange={onChangeHandler}
                input={<BootstrapInput />}
            >
                {stores.map((store, index) => (
                    <MenuItem key={index} value={store.id}>
                        {store.name}
                    </MenuItem>
                ))}
            </MiniSelect>
        );
    };

    const onChangeHandler = (event) => {
        setCurrentStore(Number(event.target.value));
        localStorage.setItem('selectedStore', event.target.value);
    };

    return (
        <div {...rest}>
            <MiniSelectLabel>Aktiv butik:</MiniSelectLabel>{' '}
            {SelectElement(stores)}
        </div>
    );
}

export default StoreSelector;
