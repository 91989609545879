import './styles.scss';

import { Translations } from '@aws-amplify/ui-components';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';

import App from '../App/App';
import { Footer } from './Footer';
import { Header } from './Header';
import { SignInFooter } from './SignInFooter';
import { SignInHeader } from './SignInHeader';

I18n.putVocabulariesForLanguage('sv-SE', {
    [Translations.SIGN_IN_HEADER_TEXT]: 'Sportson Servicesystem',
    [Translations.SIGN_IN_ACTION]: 'Logga in',
    'Create account': 'Skapa konto',
    [Translations.SIGN_OUT]: 'Logga ut',
    'No account?': 'Inget konto? ',
    'Forgot your password?': 'Glömt lösenord? ',
    'Reset password': 'Nollställ lösenord',
    'Enter your password': 'Ange lösenord',
    'Enter your username': 'Ange e-postadress',
    Username: 'E-postadress',
    Password: 'Lösenord',
    'Confirm Password': 'Bekräfta Lösenord',
    'New password': 'Nytt lösenord',
    'Submit': 'Skicka',
    'Resend Code': 'Skicka kod igen',
    'Email Address *': 'E-postadress *',
    'Enter your email address': 'Ange din e-postadress',
    'Username *': 'E-postadress *',
    'Password *': 'Lösenord *',
    'Username cannot be empty': 'E-postadress får ej vara tom',
    'Enter your phone number': 'Ange ditt telefonnummer',
    'Create Account': 'Skapa konto',
    'Have an account?': 'Har du redan ett konto? ',
    'Sign in': 'Logga in',
    'The following fields need to be filled out:':
        'Följande fält måste anges: ',
    'Reset your password': 'Nollställ lösenord',
    'Send code': 'Skicka kod',
    'Code': 'Kod',
    'Signing in': 'Loggar in...',
    'Back to Sign In': 'Tillbaka',
    'Confirm Sign Up': 'Bekräfta konto',
});


// https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#recommended-usage

const Login = withAuthenticator(App, {
    components: {
        Header,
        SignIn: {
            Header: SignInHeader,
            Footer: SignInFooter,
        },
        Footer,
    },

});

export default Login;
