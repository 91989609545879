import './FormStatus.scss';

import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from 'react';

const isValid = (errors) => {
    return !errors || Object.keys(errors).length === 0;
}

export function FormStatus ({ errors, loading = false }) {

    const [loaded, setLoaded] = useState();

    useEffect(() => {
        if (loading) {
            setLoaded(true);
        }

        if (!loading && loaded) {
            const timer = setTimeout(() => setLoaded(null), 750);

            return () => clearTimeout(timer);
        }

    }, [loading, loaded]);

    return (
        <div className='form-status'>
            { !loading && !isValid(errors) && <FontAwesomeIcon icon={faExclamationTriangle} className='form-status-error' /> }
            { !loading && isValid(errors) && loaded && <FontAwesomeIcon icon={faCheck} className='form-status-success' /> }

            {loading && <CircularProgress size={20} thickness={8} className='form-status-loader' />}
        </div>
    );
}
