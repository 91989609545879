import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';

export const DocumentButton = ({ link }) => {
    const onClick = () => {
        let win = window.open(link.link, '_blank');
        win.focus();
    };

    return (
        <IconButton color='primary' size='medium' disabled={!link} onClick={onClick}>
            <FontAwesomeIcon icon={faPrint} style={{ fontSize: 15 }} />
        </IconButton>
    );
};
