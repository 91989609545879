import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useStore } from '../Common/context/store-context';
import CustomerPage from '../Customer/CustomerPage';
import DashboardPage from '../Dashboard/DashboardPage';
import HeadsIntegrationPage from '../HeadsIntegration/HeadsIntegrationPage';
import { ConversationList } from '../Conversation/ConversationList';
import ScheduleAdministrationPage from '../ScheduleAdministration/ScheduleAdministrationPage';
import ScheduleOverviewPage from '../ScheduleOverview/ScheduleOverviewPage';
import AllOrders from '../ServiceOrder/components/OrdersPage/OrdersPage';
import { SmsOverviewPage } from '../SmsOvererview/SmsOverviewPage';
import StoreAdministrationPage from '../StoreAdministration/StoreAdministrationPage';
import SubStorePage from '../SubStore/SubStorePage';
import UserAdministrationPage from '../UserAdministration/UserAdministrationPage';
import UserStoreAdministrationPage from '../UserAdministration/UserStoreAdministrationPage';

function Routes() {
    const [storeId] = useStore();

    if (!storeId) {
        return <div>Ingen butik vald</div>;
    }

    return (
        <Switch>
            <Route exact path="/" component={DashboardPage} />
            <Route exact path="/customer" component={CustomerPage} />
            <Route exact path="/orders" component={AllOrders} />
            <Route exact path="/schedule-overview" component={ScheduleOverviewPage} />
            <Route exact path="/user-store-administration" component={UserStoreAdministrationPage} />
            <Route exact path="/conversations" component={ConversationList} />
            <Route exact path="/user-administration" component={UserAdministrationPage} />
            <Route exact path="/store-administration" component={StoreAdministrationPage} />
            <Route exact path="/sub-store" component={SubStorePage} />
            <Route path="/sub-store/:id" component={SubStorePage} />
            <Route exact path="/schedule-administration" component={ScheduleAdministrationPage} />
            <Route exact path="/heads" component={HeadsIntegrationPage} />
            <Route exact path="/sms-overview" component={SmsOverviewPage} />
            <Redirect from="*" to="/" />
        </Switch>
    );
}

export default Routes;
