import React from 'react';
import { SystemUser } from 'src/generated/graphql';
import { Dialog } from 'src/modules/Common/custom-hooks/useDialog3';
import ErrorMessage from 'src/modules/Common/ErrorMessage/ErrorMessage';
import {
    EditPasswordForm,
    EditPasswordFormValues,
} from 'src/modules/UserAdministration/components/EditPasswordDialog/EditPasswordForm';
import {
    useSetPasswordMutation,
} from 'src/modules/UserAdministration/hooks/useUserMutations';

interface Props {
    dialogRef: any;
    user: SystemUser
}

export function EditPasswordDialog({ dialogRef, user }: Props) {

    const [editPassword, { loading, error }] = useSetPasswordMutation();

    const onSubmit = async (formData: EditPasswordFormValues) => {
        await editPassword({
            variables: {
                userId: user.id,
                ...formData,
            },
        });
        dialogRef.close();
    };

    return (
        <Dialog dialogRef={dialogRef} tabs={[{
            label: 'Redigera lösenord',
            content: (
                <div>
                    <ErrorMessage error={error} />
                    <EditPasswordForm
                        loading={loading}
                        onSubmit={onSubmit}
                        defaultValues={{
                            tempPassword: '',
                        }}
                    />
                </div>
            ),
        }]}
        />
    );
}
