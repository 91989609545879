export function findOrPush(
    list: any[],
    where: (model: any) => boolean,
    newData: any,
    newDataOnCreate: any = null
) {
    let hit = false;

    const newList = list.map((model) => {
        if (where(model)) {
            hit = true;
            return { ...model, ...newData };
        }

        return model;
    });

    if (!hit) {
        newList.push({
            ...newData,
            ...newDataOnCreate,
        });
    }

    return newList;
}
