import React, { createContext, useContext, useState } from 'react';

type StoreId = number;

type SetStoreFn = (id: number) => void;

type StoreList = {
    id: number,
    name: string
}[];

type SetStoresFn = (stores: {
    id: number,
    name: string
}) => void;

type CurrentStore = {
    id: number,
    name: string
};

type StateType = [
    StoreId,
    SetStoreFn,
    StoreList,
    SetStoresFn,
    CurrentStore,
];

export const StoreContext = createContext<StateType>([0, () => { }, [{ id: 0, name: '' }], () => { }, { id: 0, name: '' }]);

export const StoreContextProvider = ({ children } : { children: any[]}) => {
    const [store, setStore] = useState<number>();
    const [stores, setStores] = useState<{ id: number, name: string }[]>();

    const currentStore = stores?.find(s => s.id === store);

    return (
        <StoreContext.Provider value={[store, setStore, stores, setStores, currentStore] as any }>
            {children}
        </StoreContext.Provider>
    );
};

export function useStore(): StateType {
    return useContext(StoreContext);
}
