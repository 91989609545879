import Box from '@material-ui/core/Box';
import omitDeep from 'omit-deep-lodash';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import {
    ComponentReportInput,
    InspectionReport,
    UpdateServiceOrderInput,
} from '../../../../../../generated/graphql';
import VerticalDivider from '../../../../../Common/Common/VerticalDivider';
import { useDebouncedCallback } from "../../../../../Common/custom-hooks/useDebounceCallback";
import AppTextInput from '../../../../../Common/Form/AppTextInput';
import ComponentComments from './ComponentComments';
import { ServiceProtocolNotFoundOnOrder } from './components/ServiceProtocolNotFoundOnOrder';
import ComponentTables from "./ComponentTables";
import InspectionProtocolHeader from './utils/InspectionProtocolHeader';

export type InspectionProtocolViewProps = {
    inspectionReport: InspectionReport | undefined,
    selectedProtocolId: number | undefined,
    update: ( serviceOrderInput: UpdateServiceOrderInput ) => void,
    finished: boolean,
    config: {
        id: number,
        key: 'default' | 'cycleurope' | string,
        label: string,
        legend?: string | undefined,
        columns: {
            label: string,
            key: string,
            prefix?: string | undefined,
        }[],
        options: {
            isBikeFrameNumberRequired: boolean,
        },
    },
};

const InspectionProtocolView = (props: InspectionProtocolViewProps) => {
    const { inspectionReport, update, config, finished } = props;

    const selectedId = useSelectedProtocolId(props.selectedProtocolId);
    const { id, comment, grades } = useInspectionProtocol(props.selectedProtocolId, inspectionReport);

    const onUpdate = () => {
        update({
            inspectionReports: [
                {
                    id: inspectionReport!.id,
                    inspectionProtocol: { id: "" + selectedId },
                    componentReport: omitDeep(grades.current, '__typename') as ComponentReportInput[],
                    comment: comment.current,
                }
            ],
        });
    };

    const debouncedUpdate = useDebouncedCallback(onUpdate, 300);

    if (id.current !== selectedId) {
        return null;
    }

    if (!inspectionReport?.inspectionProtocol?.section) {
        return (
            <Box py={2}>
                <ServiceProtocolNotFoundOnOrder />
            </Box>
        );
    }

    return (
        <Fragment key={selectedId}>
            <MemoizedComponentTables
                section={inspectionReport?.inspectionProtocol?.section}
                grades={grades.current}
                onChange={(newGrades: any[]) => {
                    grades.current = newGrades;
                    onUpdate();
                }}
                disabled={finished}
                config={config}
            />
            <VerticalDivider />
            <div style={{ flexGrow: 1 }}>
                <ComponentComments
                    section={inspectionReport?.inspectionProtocol?.section || []}
                    grades={grades.current}
                />
                <div>
                    <InspectionProtocolHeader>Generellt omdöme</InspectionProtocolHeader>
                    <AppTextInput
                        id='customerCommentText'
                        label='Kommentar från mekaniker'
                        defaultValue={comment.current}
                        multiline
                        rows={4}
                        onChange={(event) => {
                            comment.current = event.target.value;
                            debouncedUpdate();
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default InspectionProtocolView;

const MemoizedComponentTables = React.memo(ComponentTables, (prevProps, nextProps) => {
    switch (true) {
        case prevProps.grades !== nextProps.grades:
        case prevProps.disabled !== nextProps.disabled:
            return false;
        default:
            return true;
    }
});

function useInspectionProtocol(id: number | undefined, inspectionReport: InspectionReport | undefined) {
    const idRef = useRef(id);
    const comment = useRef<InspectionReport['comment']>(inspectionReport?.comment ?? '');
    const grades = useRef<InspectionReport['componentReport']>(inspectionReport?.componentReport ?? []);

    useEffect(() => {
        if (idRef.current !== id) {
            idRef.current = id;
            comment.current = inspectionReport?.comment ?? '';
            grades.current = inspectionReport?.componentReport ?? [];
        }

        return () => {}
    });

    return { id: idRef, comment, grades };
}

// This triggers update of the component based of selectedProtocolId
// React triggers re-renders based on state change.
// Change of prop value will not re-render
function useSelectedProtocolId(id: number | undefined) {
    const [ selectedId, setSelectedId ] = useState<number | undefined>();
    useEffect(() => {
        setSelectedId(id);
    }, [ id ]);

    return selectedId;
}
