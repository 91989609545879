import React from 'react';
import { Dialog } from 'src/modules/Common/custom-hooks/useDialog3';
import ErrorMessage from 'src/modules/Common/ErrorMessage/ErrorMessage';
import {
    AddUserToStoreForm,
    AddUserToStoreFormValues,
} from 'src/modules/UserAdministration/components/AddUserToStoreDialog/AddUserToStoreForm';
import {
    useAddUserToStoreMutation,
} from 'src/modules/UserAdministration/hooks/useUserMutations';

interface Props {
    dialogRef: any;
    storeId: number;
}

export function AddUserToStoreDialog({ dialogRef, storeId }: Props) {

    const [call, { loading, error }] = useAddUserToStoreMutation();

    const onSubmit = async (formData: AddUserToStoreFormValues) => {
        await call({
            variables: {
                storeId,
                ...formData,
            }
        });
        dialogRef.close()
    }

    return (
        <Dialog dialogRef={dialogRef} tabs={[{
            label: 'Befintlig användare',
            content: (
                <div>
                    <ErrorMessage error={error} />
                    <AddUserToStoreForm
                        loading={loading}
                        onSubmit={onSubmit}
                        defaultValues={{}}
                    />
                </div>
            ),
        }]}
        />
    );
}
