import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react'
import { Control, Controller, useFormContext } from 'react-hook-form';

type AppCheckboxProps = {
    name: string,
    label: string,
    control?: Control<any>,
    onChangedCallback?: () => void,
    disabled?: boolean,
    controllerProps?: Record<string, unknown>;
    checkboxProps?: Record<string, unknown>;
};

export default function AppCheckbox({
    name,
    control,
    onChangedCallback,
    disabled,
    label,
    controllerProps,
    checkboxProps,
}: AppCheckboxProps) {
    const formControl = useFormContext();
    return (
        <Controller
            name={name}
            control={control || formControl.control}
            render={(props) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            {...checkboxProps}
                            inputRef={props.ref}
                            name={props.name}
                            checked={props.value}
                            onChange={(e) => { 
                                props.onChange(e.target.checked);
                                onChangedCallback && onChangedCallback(); 
                            }}
                            disabled={disabled ?? false}
                        />
                    } 
                    label={label} />
            )}
            {...controllerProps}
        />
    )
}
