import React from 'react';
import { Tab, TabPanel } from 'react-tabs';

import { Dialog } from '../../../Common/custom-hooks/useDialog2';
import UserAssignedStores from './UserAssignedStores';

export const EditUserStoresDialog = ({ userId, stores = [], dialogRef }) => {
    return (
        <>
            <Dialog dialogRef={dialogRef}>
                <Tab>Butiker</Tab>
                <TabPanel>
                    <UserAssignedStores stores={stores} userId={userId} />
                </TabPanel>
            </Dialog>
        </>
    );
};
