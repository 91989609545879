import { useEffect, useState } from "react";
import { FeatureToggleService } from "src/services/Launchdarkly/services/FeatureToggleService";

import { useStore } from "../context/store-context";
import { useUser } from "../context/user-context";

export const enum LaunchDarklyFlag {
    BUSINESS_CUSTOMERS = 'BUSINESS_CUSTOMERS',
}

export default function useFeatureFlag(flag: LaunchDarklyFlag) {
    const [active, setFlagActive] = useState(false);
    const [user] = useUser();
    const [storeId] = useStore();

    useEffect(() => {
        (async () => {
            if(!user) {
                return;
            }

            const ldClient = await FeatureToggleService.getClient({
                key: user.username,
                custom: { storeIds: storeId }
            });
            const flagIsActive: boolean = ldClient.variation(flag);
            
            setFlagActive(() => flagIsActive);
        })();
    }, [flag, storeId, user]);
    
    return active;
}
