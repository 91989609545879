import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoaderDialog = ({ visible = true, size = 50 }) => {
    if (!visible) {
        return null;
    }

    return (
        <LoadingContainer className={'dialog2'}>
            <CircularProgress size={size} color="secondary" />
        </LoadingContainer>
    );
};

export default LoaderDialog;
