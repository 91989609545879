import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import isFunction from 'lodash/isFunction';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Control, Controller, FieldErrors, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

// @see https://stackoverflow.com/questions/56472438/select-outlineinput-label-with-shrink-property-not-same-as-textfield-when-empty

type AppSelectProps = {
    label: string;
    defaultValue?: string | number | null;
    children: React.ReactNode;
    control?: Control<Record<string, any>>;
    name: string;
    handleChange?: ChangeEvent<HTMLSelectElement>;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    size?: 'small' | 'medium';
    disabled?: boolean;
    id?: string;
    formErrors?: FieldErrors<FieldValues>;
};

function AppSelect({
    label,
    defaultValue,
    children,
    control,
    name,
    handleChange,
    rules,
    size,
    id,
    disabled = false,
    formErrors,
}: AppSelectProps) {
    const methods = useFormContext();
    const inputLabel = useRef<HTMLLabelElement>(null);

    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current?.offsetWidth || 0);
    }, [inputLabel]);

    const invalidField = Boolean(formErrors?.[name]);

    control = control || methods?.control || (() => {});

    return (
        <FormControl
            variant="outlined"
            className={invalidField ? 'customFieldError' : ''}
            fullWidth
            size={size}
        >
            <InputLabel error={methods?.errors[name] ? true : false} style={{ zIndex: 0 }} shrink ref={inputLabel} htmlFor={name}>
                {label}
            </InputLabel>
            <Controller
                render={(props) => (
                    <>
                        <Select
                            input={
                                <OutlinedInput
                                    notched
                                    labelWidth={labelWidth}
                                    name="value"
                                    id={name}
                                    disabled={disabled}
                                />
                            }
                            onChange={(event) => {
                                props.onChange(event.target.value);
                                if (isFunction(handleChange)) {
                                    handleChange(event.target.value);
                                }
                            }}
                            id={id}
                            value={props.value || ''}
                            error={methods?.errors[name] ? true : false}
                        >
                            {children}
                        </Select>
                        <FormHelperText error={true} className='errors'>{methods?.errors[name]?.message}</FormHelperText>
                    </>
                )}
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
            />
        </FormControl>
    );
}

export default AppSelect;
