import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import React from "react";

interface Props {
    title: string,
    cancelLabel: string,
    confirmLabel: string,
    children: React.ReactNode,
    open: boolean,

    onConfirm: () => void,
    onCancel?: () => void,
    onClose?: () => void,
}

export default function SimpleConfirmDialog(props: Props) {
    const handleClose = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleConfirm = () => {
        props.onConfirm();
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="simple-confirm-dialog-title"
            aria-describedby="simple-confirm-description"
        >
            <DialogTitle id="simple-confirm-title">
                {props.title}
            </DialogTitle>
            <DialogContent id="simple-confirm-description">
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCancel}>{props.cancelLabel}</Button>
                <Button variant='contained' color="secondary" onClick={handleConfirm} autoFocus>{props.confirmLabel}</Button>
            </DialogActions>
        </Dialog>
    );
}
