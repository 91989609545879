import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React from 'react';

type SimpleConfirmDialogProps = {
    open: boolean;
    title: string;
    cancelLabel?: string;
    confirmLabel?: string;
    onCancel?: () => void;
    onConfirm?: () => void;
    children: React.ReactNode;
};

export default function SimpleConfirmDialog({ title, open, children, cancelLabel, confirmLabel, onCancel, onConfirm }: SimpleConfirmDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={() => onCancel && onCancel()}
            aria-labelledby="simple-confirm-dialog-title"
            aria-describedby="simple-confirm-description"
        >
            <DialogTitle id="simple-confirm-title">
                <Typography align="center">{title}</Typography>
            </DialogTitle>
            <DialogContent id="simple-confirm-description">{children}</DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                {cancelLabel &&
                    <Button variant='outlined' onClick={() => onCancel && onCancel()}>{cancelLabel}</Button>
                }
                {confirmLabel &&
                    <Button variant='contained' color="secondary" onClick={() => onConfirm && onConfirm()} autoFocus>{confirmLabel}</Button>
                }
            </DialogActions>
        </Dialog>
    );
}
