import React from 'react';

interface Props {
    env: string
}

export function EnvironmentBanner (props: Props) {
    if (!props.env || props.env === 'production') {
        return null
    }

    return (
        <div className="environment-banner">{props.env}</div>
    )
}
