import './ProductSelector.scss';

import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState } from 'react';

import LoaderCircular from '../../../../../../Common/Common/LoaderCircular';
import { GET_PRODUCTS } from '../../../../../graphql/ProductsGql';
import { priceFormat, vatFormat } from '../../../../../utils/productHelper';

let prevData = null;

const status = (statusId) => {
    switch (statusId) {
        case 1:
            return 'Aktiv';
        case 2:
            return 'Kommande';
        case 3:
            return 'Utgående';
        case 4:
            return 'Dold';
        case 5:
            return 'Inaktiv';
        case 6:
            return 'Ej aktiverad';
        default:
            return '';
    }
}

const CellOverflow = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 520px;
`;

const ProductSelector = ({ categoryId, onSelect }) => {
    let [page, setPage] = useState(0);
    let [selected, setSelected] = useState(null);

    let { data, loading } = useQuery(GET_PRODUCTS, {
        variables: {
            page: page + 1,
            categoryId: categoryId,
        },
    });

    // Keep data between paginations
    prevData = data = data || prevData;

    const onClick = (row, i) => {
        onSelect(row);
        setSelected(i);
        setTimeout(() => setSelected(null), 300)
    }

    if (!data) {
        return (
            <div className='card'>
                <LoaderCircular />
            </div>
        );
    }

    function priceTooltip(row) {
        return priceFormat(row.Price, 2) + ' + ' + vatFormat(row.Vat);
    }

    return (
        <div className='card product-selector' data-testid='product-selector'>
            <LoaderCircular visible={loading} />
            <TableContainer>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>ArtNr</TableCell>
                            <TableCell>Beskrivning</TableCell>
                            <TableCell align='right'>Pris</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.products.items.map((row, i) => (
                            <TableRow
                                key={i}
                                onClick={() => onClick(row, i)}
                                selected={selected === i}
                            >
                                <TableCell>
                                    <Tooltip title={status(row.StatusId)} placement='left'>
                                        <CellOverflow>{row.PartNo}</CellOverflow>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <CellOverflow>{row.Name}</CellOverflow>
                                </TableCell>
                                <TableCell align='right'>
                                    <Tooltip title={priceTooltip(row)} placement='left'>
                                        <span>{priceFormat(row.PriceIncVat)}</span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component='div'
                count={data.products.total}
                page={page}
                onChangePage={(e, p) => setPage(p)}
                rowsPerPage={data.products.items.length}
                rowsPerPageOptions={[]}
            />
        </div>
    );
};

export default ProductSelector;
