// General handler for graph ql errors
import 'src/modules/Common/ErrorMessage/ErrorMessage.scss';

import { ApolloError } from '@apollo/client';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import { GraphQLError } from 'graphql';
import React from 'react';
import { translateError } from 'src/modules/Common/ErrorMessage/errorMessageTranslations';

interface Props {
    error: ApolloError | undefined,
}

const SingleGraphQlError = ({ error }: { error: GraphQLError }) => {
    if (error?.extensions?.exception?.code === 'ER_DUP_ENTRY') {
        return <span>Värdet finns redan i databasen</span>;
    }

    return <pre>{translateError(error.message)}</pre>;
};

const ErrorMessage = ({ error, ...rest }: Props & AlertProps) => {
    if (!error) {
        return null;
    }

    return (
        <Alert severity="error" className='error-message' { ...rest }>
            {error.graphQLErrors.length === 0 ? translateError(error.message) : null}
            {error.graphQLErrors.map((e, i) => (
                <SingleGraphQlError key={i} error={e} />
            ))}
        </Alert>
    );
};

export default ErrorMessage;
