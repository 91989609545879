
import { toast } from '../../modules/App/Toaster';
import { printers } from '../../modules/Common/custom-hooks/sharedSubjects/usePrinters';

const qz = require('qz-tray');

export class Qztray {

    static async connect() {
        if (qz.websocket.isActive()) {
            return true;
        }
        await qz.websocket.connect().catch((err) => {
            console.error(err);
        });

        if (qz.websocket.isActive()) {
            return true;
        }

        toast.error('Kunde inte ansluta till QZ Tray', 'error');

        return false;
    }

    static async refreshPrinterList() {
        const success = await Qztray.connect();

        if (!success) {
            return;
        }

        const newPrinters = await qz.printers.find();

        printers.next(newPrinters);
    }

    static disconnect() {
        return qz.websocket.disconnect();
    }

    static async print(data = []) {
        const success = await Qztray.connect();

        if (!success) {
            return;
        }

        const config = qz.configs.create(Qztray.getPrinter(), {
            altPrinting: true, // Required when the device is connected to a mac.
        });

        return qz.print(config, data);
    }

    static setPrinter(printer) {
        localStorage.setItem('selectedPrinter', printer);
    }

    static getPrinter() {
        return localStorage.getItem('selectedPrinter');
    }

    static async testPrint() {
        await Qztray.connect();
        let config = qz.configs.create('Zebra Technologies ZTC GK420d');

        const printData = [
            '^XA\n',
            '^FO50,50^ADN,36,20^FDPRINTED WITH QZ \n',
            '^FS\n',
            '^XZ\n',
        ];

        return qz.print(config, printData);
    }

    static printTemplate(s = '') {
        Qztray.print(s.split('\n').map((row) => row.trimLeft() + '\n'));
    }

    static printRaw(s = '') {
        var data = {
            format: 'base64',
            type: 'raw',
            data: btoa(s),
            options: { language: 'ZPL' },
        };

        Qztray.print([data]);
    }
}
