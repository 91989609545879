import * as LaunchDarkly from 'launchdarkly-js-client-sdk';

import config from '../config/launchdarkly';

export class FeatureToggleService {
    // @todo The clientside the storeIds should be storeId but that is not yet provided by launchdarkly config.
    static async getClient(user: { key: string, custom: { storeIds: number } }): Promise<LaunchDarkly.LDClient> {
        if (!config.apiKey) {
            throw new Error('Missing api key for launchdarkly');
        }

        const ldClient = LaunchDarkly.initialize(config.apiKey, user);

        try {
            await ldClient.waitForInitialization();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(
                'Feature Toggle service failed to initialize.' +
                    ' All feature flags will resolve to their fallback values',
            );
            // eslint-disable-next-line no-console
            console.error(e);
        }
        
        return ldClient;
    }
}
